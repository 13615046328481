import React from 'react';
import { Text, TextType } from '@hum/ui-library';

export type ConnectorListHeaderProps = {
  title: string;
  subtitle: string;
};

export const ConnectorListHeader = ({
  title,
  subtitle,
}: ConnectorListHeaderProps) => {
  return (
    <div>
      <Text
        testId="connector-list-header"
        type={TextType.PAGE_HEADING}
        className="text-gray-100 mb-4"
      >
        {title}
      </Text>
      <Text
        testId="connector-list-subheader"
        type={TextType.BODY_M}
        subdued
        className="mb-8"
      >
        {subtitle}
      </Text>
    </div>
  );
};
