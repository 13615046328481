import React from "react";
import _pubDe25E084, {Modal as _pubDe25E084_Modal} from "../Modal/index.pc";
import _pub2F51C399 from "../../../../../design-system/src/breakpoints.pc";
import _pubA97D53F3 from "../../../../../design-system/src/atoms.pc";
import _pub2Dbb4493 from "../../../../../design-system/src/Button.pc";
import _pub9A961Ec6, {AccordionContent as _pub9A961Ec6_AccordionContent} from "../../../../../design-system/src/Accordion.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../design-system/src/utils.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var ImportFileAccordionLabel = React.memo(React.forwardRef(function ImportFileAccordionLabel(props, ref) {
  return React.createElement("div", {
    "className": "_a041107 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3" + (props["hasDescription"] ? " " + "_68a3c8fb_has-description _pub-68a3c8fb_has-description _pub-2f51c399_has-description _pub-a97d53f3_has-description has-description" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_15dcf41b _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
    }, 
      React.createElement("span", {
        "className": "_23cba069 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
      }, 
        props["title"]
      )
    ),
    React.createElement("button", {
      "className": "_fbd29537 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
    }, null)
  )
}));
export { ImportFileAccordionLabel };

var UploadItems = React.memo(React.forwardRef(function UploadItems(props, ref) {
  return React.createElement("ul", {
    "className": "_edbc3c00 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { UploadItems };

var UploadChecklistItem = React.memo(React.forwardRef(function UploadChecklistItem(props, ref) {
  return React.createElement("li", {
    "className": "_4be8d983 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_a88b92ac _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
    }, 
      props["children"]
    )
  )
}));
export { UploadChecklistItem };

var UploadInfoModal = React.memo(React.forwardRef(function UploadInfoModal(props, ref) {
  return React.createElement(_pubDe25E084_Modal, {
    "class": "_a5e6b8af _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "onBackgroundClick": props["onBackgroundClick"],
    "fixed": props["fixed"],
    "isVisible": props["isVisible"],
    "title": React.createElement(React.Fragment, {
    }, 
      props["title"],
      " "
      ,
      React.createElement("strong", {
        "className": "_81c9586d _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
      }, 
        "should..."
        
      )
    ),
    "footerControls": React.createElement("div", {
      "className": "_bccc0fd2 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
    }, 
      React.createElement(_pub2Dbb4493, {
        "class": "_649b7e7c _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
        "v3": true,
        "transparent": true,
        "onClick": props["onCancelClick"],
      }, 
        "Cancel"
        
      ),
      React.createElement(_pub2Dbb4493, {
        "class": "_139c4eea _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
        "v3": true,
        "primary": true,
      }, 
        "\n      Continue to upload\n      "
        ,
        React.createElement("input", {
          "className": "_9cd17dfd _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
          "type": "file",
          "onChange": props["onFileUploadChange"],
          "accept": props["accept"],
        }, null)
      )
    ),
  }, 
    React.createElement("ul", {
      "className": "_ab0f46c2 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
    }, 
      props["children"]
    )
  )
}));
export { UploadInfoModal };

var UploadedItem = React.memo(React.forwardRef(function UploadedItem(props, ref) {
  return React.createElement("li", {
    "className": "_4c851d9a _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
    "ref": ref,
  }, 
    props["icon"],
    React.createElement("p", {
      "className": "_418c5b5c _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
    }, 
      props["label"]
    ),
    React.createElement("p", {
      "className": "_368b6bca _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
    }, 
      props["uploadedDate"]
    ),
    props["controls"]
  )
}));
export { UploadedItem };

var Sections = React.memo(React.forwardRef(function Sections(props, ref) {
  return React.createElement("div", {
    "className": "_a28b7cb6 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3" + " " + "_68a3c8fb_space-between-vertical _pub-68a3c8fb_space-between-vertical _pub-2f51c399_space-between-vertical _pub-a97d53f3_space-between-vertical space-between-vertical _68a3c8fb_space-between-medium _pub-68a3c8fb_space-between-medium _pub-2f51c399_space-between-medium _pub-a97d53f3_space-between-medium space-between-medium",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Sections };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(_pubBab5A68F_Preview, {
    "class": "_32346127 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
    "ref": ref,
    "humDark": true,
  }, 
    props["showModal"]&&React.createElement(UploadInfoModal, {
      "class": "_561bf4fa",
    }, 
      React.createElement(UploadChecklistItem, {
        "class": "_13a91195",
      }, 
        "do something"
        
      ),
      React.createElement(UploadChecklistItem, {
        "class": "_64ae2103",
      }, 
        "do something"
        
      )
    ),
    React.createElement(_pub9A961Ec6, {
      "class": "_d05d99b5 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
      "label": React.createElement(ImportFileAccordionLabel, {
        "class": "_4f00c5bb",
        "title": "Something",
      }, null),
      "expanded": true,
    }, 
      React.createElement(_pub9A961Ec6_AccordionContent, {
        "class": "_574bd9fc _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
      }, 
        React.createElement(UploadItems, {
          "class": "_2ceb36",
        }, 
          React.createElement(UploadedItem, {
            "class": "_f9eed215",
            "icon": React.createElement("img", {
              "className": "_99ffd518 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
              "src": getDefault(require("@hum/figma/spot-icons/symbols/files-pdf@1.svg")),
            }, null),
            "label": "PDF",
          }, null),
          React.createElement(UploadedItem, {
            "class": "_8ee9e283",
            "icon": React.createElement("img", {
              "className": "_80e4e459 _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
              "src": getDefault(require("@hum/figma/spot-icons/symbols/files-jpg@1.svg")),
            }, null),
            "label": "Upload item",
          }, null),
          React.createElement(UploadedItem, {
            "class": "_17e0b339",
            "icon": React.createElement("img", {
              "className": "_abc9b79a _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
              "src": getDefault(require("@hum/figma/spot-icons/symbols/files-csv@1.svg")),
            }, null),
            "label": "CSV",
          }, null),
          React.createElement(UploadedItem, {
            "class": "_60e783af",
            "icon": React.createElement("img", {
              "className": "_b2d286db _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
              "src": getDefault(require("@hum/figma/spot-icons/symbols/files-xls@1.svg")),
            }, null),
            "label": "XLS file",
          }, null),
          React.createElement(UploadedItem, {
            "class": "_fe83160c",
            "icon": React.createElement("img", {
              "className": "_fd93101c _68a3c8fb _pub-68a3c8fb _pub-2f51c399 _pub-a97d53f3",
              "src": getDefault(require("@hum/figma/spot-icons/symbols/files-file@1.svg")),
            }, null),
            "label": "Other doc",
          }, null)
        )
      )
    )
  )
}));


