import React from "react";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../design-system/src/utils.pc";
import _pub2Dbb4493 from "../../../../../design-system/src/Button.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var ListHeader = React.memo(React.forwardRef(function ListHeader(props, ref) {
  return React.createElement("div", {
    "className": "_272e3d17 _d535042e _pub-d535042e" + " " + "_d535042e_list-header _pub-d535042e_list-header list-header",
    "ref": ref,
  }, 
    React.createElement("h2", {
      "className": "_1ac19715 _d535042e _pub-d535042e" + " " + "_d535042e_title _pub-d535042e_title title",
    }, 
      props["title"]
    ),
    React.createElement("div", {
      "className": "_6dc6a783 _d535042e _pub-d535042e" + " " + "_d535042e_actions _pub-d535042e_actions actions",
    }, 
      props["children"]
    )
  )
}));
export { ListHeader };


