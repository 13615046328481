import { useState, useMemo, useEffect } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { datadogLogs } from '@datadog/browser-logs';
import { useHistory } from 'react-router-dom';

import { toast } from '@hum/common/src/modules/toast';
import { AvailableServiceInfo } from '@hum/icm-app/src/pages/connectors/Integrations/Services/base';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import {
  linkedAccountDeleteButtonClicked,
  externalServiceConnected,
} from '@hum/icm-app/src/actions';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { Company, CompanyFileDocumentType } from '@hum/types';
import { Connector, Routes } from '@hum/icm-app/src/state';
import { FileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { useConnectors } from '@hum/icm-app/src/hooks/useConnectors';
import { ConnectorStatus } from '@hum/icm-app/src/backend/api/models/connectorModels';

import { useForm } from '@hum/common/src/modules/form';
import {
  generateInitialValues,
  generateValidationSchema,
} from '@hum/icm-app/src/pages/connectors/Integrations/ConnectAccountPopup/SelectedServiceTab';

import {
  serviceIsCodat,
  SERVICES_SHOWN_IN_ONBOARDING,
  hasCompletedOnboardingFileUpload,
} from './connectServiceUtils';

const handleCodatConnect = async ({
  company,
  service,
}: {
  company: Company;
  service: AvailableServiceInfo;
}) => {
  // This function may be triggered from the flow, abort the connection if service is not defined
  if (!service) return null;

  // Ideally we wouldn't be sending this twice we shouldn't change the "connect" function on all connectors at the moment
  const response = await service.connect(
    {}, // codat services do not take any arguments
    {
      companyId: company.id,
    }
  );

  if (!response) {
    toast.error('Failed to create codat connection link');
    datadogLogs.logger.error('Failed to create codat connection link');
  }

  return response;
};

export const useNewDirectConnect = ({
  service,
  setIsConnecting,
  onSuccessCallback,
}: {
  service: AvailableServiceInfo | null;
  setIsConnecting;
  onSuccessCallback?: () => void;
}) => {
  const showDirectConnect = Boolean(service?.fields?.length);
  const [additionalParams, setAdditionalParams] = useState({});
  const company = useCurrentCompany();
  const dispatch = useDispatch();
  const flags = useFlags();

  const initialValues = useMemo(() => generateInitialValues(service), [
    service,
  ]);
  const validationSchema = generateValidationSchema(service);

  const saveParams = async () => {
    setAdditionalParams({});
  };

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      if (!company.data?.id || !service) return;

      const connectPayload = {
        ...form.values,
        ...additionalParams,
      };

      // connect function may return what it was given, happens on netsuite
      // might want to rename it to something like "prepare" but we already have a "prepare"
      setIsConnecting(true);

      const connectFinalParams = await service.connect(connectPayload, {
        company: company.data,
      });

      dispatch(
        externalServiceConnected({
          displayName: service.label,
          companyId: company?.data?.id,
          connectorType: service.type,
          airbyteEnabled: flags.enabled('show-airbyte'),
          params: connectFinalParams,
        })
      );

      // reset the state of the loading form
      setIsConnecting(false);

      // call a close modal function if it was passed
      onSuccessCallback && onSuccessCallback();
    },
  });

  useEffect(() => {
    saveParams();
  }, [service, form.values]);

  return {
    showDirectConnect,
    form,
  };
};

const handleConnectService = async ({
  service,
  company,
  directConnect,
  dispatch,
  airbyteEnabled,
}: {
  service: AvailableServiceInfo;
  company: Company;
  directConnect: any;
  dispatch: Dispatch;
  airbyteEnabled: boolean;
}) => {
  const showDirectConnect = Boolean(service?.fields?.length);

  if (showDirectConnect) {
    // Do not submit if there are fields and form isn't valid
    if (
      !directConnect.newDirectConnectForm.isValid ||
      !directConnect.isFormValid
    ) {
      return;
    }

    // only submit form if is direct connect (inside this if block)
    // commenting this because it was previously outside
    directConnect.newDirectConnectForm.submitForm();
  }

  const params = await service.connect(
    {
      ...directConnect.newDirectConnectForm.values,
      ...directConnect.additionalParams,
    },
    { company }
  );

  dispatch(
    externalServiceConnected({
      displayName: service.label,
      companyId: company.id,
      connectorType: service.type,
      airbyteEnabled,
      params,
    })
  );
};

export const useService = (
  fileManager: FileManager,
  { onSuccessCallback }: { onSuccessCallback?: () => void } = {}
) => {
  const dispatch = useDispatch();
  const company = useCurrentCompany();
  const services = useConnectors();
  const history = useHistory();
  const [showOtherOption, setShowOtherOption] = useState(false);
  const [showImportFiles, setShowImportFiles] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [showConnectedAnimation, setShowConnectedAnimation] = useState(false);
  const [disconnectService, setDisconnectService] = useState<Connector | null>(
    null
  );
  const [disconnectFiles, setDisconnectFiles] = useState(false);

  const flags = useFlags();
  const [
    selectedService,
    setSelectedService,
  ] = useState<AvailableServiceInfo | null>(null);

  // Updated connect flows
  const { showDirectConnect, form: newDirectConnectForm } = useNewDirectConnect(
    {
      service: selectedService,
      setIsConnecting,
      onSuccessCallback,
    }
  );

  const selectedServiceFields = selectedService?.fields || [];

  // Generate a boolean that decides if the form is considered valid
  // Updates any time a form value is changed
  const isFormValid = useMemo(() => {
    if (!selectedService) return false;
    // interrupt if not a direct connect
    if (!showDirectConnect) return false;

    return (
      !selectedService.validate ||
      selectedService.validate(newDirectConnectForm.values)
    );
  }, [newDirectConnectForm.values]);

  // Reset isConnecting whenever a service is deselected
  useEffect(() => {
    if (!selectedService) {
      setIsConnecting(false);
    }
  }, [selectedService]);

  const directConnect = {
    newDirectConnectForm,
    isFormValid,
    selectedServiceFields,
  };

  // The company said there is no connectors available for them, enables file upload
  const hasRequestedConnectors = Boolean(
    company.data?.application.requestedConnectors?.length
  );

  const availableServices = SERVICES_SHOWN_IN_ONBOARDING;

  // Let's us know if the company has connected one service, if yes, it will be used
  // to let the company go to the next part of the flow
  const hasOneServiceConnected =
    availableServices.findIndex((service) => {
      const connectedServices =
        services.data?.filter(({ connectorType, status }) => {
          // make sure we only consider `active`, `pending` connectors as connected
          return (
            connectorType === service.type &&
            [ConnectorStatus.Active, ConnectorStatus.Pending].includes(status)
          );
        }) || [];
      return connectedServices.length > 0;
    }) >= 0;

  const uploadedFilesComplete = hasCompletedOnboardingFileUpload(fileManager);

  // this will set service in this' component state to the selected service
  // and then it will decide if the service has form fields
  // if it does not have fields, it will call connectService
  // if it has them, the fields will render because of the state update
  // but connectService will be called from the form submit event instead
  const onServiceClick = async (service: AvailableServiceInfo) => {
    const isCodat = serviceIsCodat({ service });
    const isDirectConnect = Boolean(service?.fields?.length);
    const isRegularConnect = !isCodat && !isDirectConnect;

    // Set current service
    setSelectedService(service);

    if (isCodat) {
      setIsConnecting(true);
      await handleCodatConnect({
        service,
        company: company.data!,
      });
      setIsConnecting(false);
      setSelectedService(null);
    }
    if (isRegularConnect) {
      setIsConnecting(true);
      handleConnectService({
        service,
        company: company.data!,
        directConnect,
        airbyteEnabled: flags.enabled('show-airbyte'),
        dispatch,
      });
    }
  };

  const onTryAgainClick = (service: AvailableServiceInfo) => {
    onCancel();
    onServiceClick(service);
  };

  const onConnectPopupClose = () => {
    setSelectedService(null);
    setShowConnectedAnimation(true);
    setIsConnecting(false);
  };

  const onOtherClick = () => {
    if (hasRequestedConnectors) {
      setShowImportFiles(true);
    } else {
      setShowOtherOption(true);
    }
    setSelectedService(null);
    setDisconnecting(false);
    setShowConnectedAnimation(false);
  };

  const onOtherSubmit = () => {
    setShowOtherOption(false);
    setShowImportFiles(true);
  };

  // "Finish" button when uploading files
  const onSubmitFilesConfirm = () => {
    setShowImportFiles(false);
  };

  const onCancelFileImport = () => {
    setShowImportFiles(false);
    setDisconnectService(null);
    setSelectedService(null);

    // if HL M2.5 v3 return to connect page when cancelling file import
    if (flags.enabled('show-hl-m25-v3')) {
      history.push(Routes.ONBOARDING_V5_CONNECT);
    }
  };

  const onDisconnectClick = (service: Connector) => {
    setDisconnectService(service);
  };

  const onDisconnectFilesClick = () => {
    setDisconnectFiles(true);
  };

  const onReplaceFilesClick = () => {
    setShowImportFiles(true);
  };

  const onCancelDisconnectFilesClick = () => {
    setDisconnectFiles(false);
  };

  const onCancel = () => {
    setDisconnectService(null);
    setShowOtherOption(false);
    setSelectedService(null);
    setDisconnecting(false);
    setShowConnectedAnimation(false);
  };

  const onConfirmDisconnectClick = async (service: Connector) => {
    setDisconnecting(true);
    await dispatch(
      linkedAccountDeleteButtonClicked({
        companyId: service.companyId,
        connectorId: service.id,
      })
    );
    // reset everything after it finishes
    onCancel();
  };

  // Remove files that were uploaded in onboarding if the user clicks cancel
  // Keep in mind we only allow 1 file for balance sheet and 1 file for income statement
  // so doing files.find works in this scenario
  const removeFiles = async () => {
    const balanceSheet = fileManager.files.find(
      (file) => file.documentType == CompanyFileDocumentType.BalanceSheet
    );
    const incomeStatement = fileManager.files.find(
      (file) => file.documentType == CompanyFileDocumentType.IncomeStatement
    );
    if (balanceSheet) {
      await fileManager.removeFile(balanceSheet.id);
    }
    if (incomeStatement) {
      await fileManager.removeFile(incomeStatement.id);
    }
  };

  const onConfirmDisconnectFilesClick = async () => {
    await removeFiles();
    setDisconnectFiles(false);
  };

  return {
    services,
    hasOneServiceConnected,
    enabledServices: availableServices, // TODO: Fully rename enabledServices to availableServices later
    uploadedFilesComplete,
    showOtherOption,
    showImportFiles,
    selectedService,
    onOtherClick,
    onServiceClick,
    onConnectPopupClose,
    onOtherSubmit,
    onCancel,
    disconnectService,
    disconnectFiles,
    onDisconnectClick,
    onDisconnectFilesClick,
    onReplaceFilesClick,
    onConfirmDisconnectClick,
    onConfirmDisconnectFilesClick,
    onSubmitFilesConfirm,
    onCancelFileImport,
    onCancelDisconnectFilesClick,
    disconnecting,
    showConnectedAnimation,
    directConnect,
    isConnecting,
    setIsConnecting,
    onTryAgainClick,
    showDirectConnect,
    newDirectConnectForm,
  };
};
