import { AcceptTosButtonClicked, OnboardingV4ActionType } from '../actions';
import { call, takeEvery } from 'redux-saga/effects';
import { acceptFeeTos, getHistory } from '@hum/icm-app/src/sagas/utils';

import { routeCreators, Routes } from '@hum/icm-app/src/state';

import { catchError } from '@hum/icm-app/src/modules/sagas';
import { noop } from 'lodash';
import { History } from 'history';

export function* handleAcceptFeeTosButtonClicked() {
  const history: History = yield getHistory();
  yield takeEvery(
    OnboardingV4ActionType.ACCEPT_TOS_BUTTON_CLICKED,
    catchError(function* ({ payload: { companyId } }: AcceptTosButtonClicked) {
      yield call(acceptFeeTos, companyId);
      history.push(routeCreators[Routes.ONBOARDING_V4_COMPLETED]());
    }, noop)
  );
}
