import { OnboardingV4ActionType, verifyEmailResent } from '../actions';
import { put, takeEvery, call } from 'redux-saga/effects';
import { toast } from '@hum/common/src/modules/toast';

export function* handleVerifyEmail() {
  yield takeEvery(
    OnboardingV4ActionType.EMAIL_NOT_FOUND_BUTTON_CLICKED,
    handleEmailNotFound
  );
}

function* handleEmailNotFound() {
  yield put(verifyEmailResent());
  yield call(() => toast.info(`We've resent you a verification link.`));
}
