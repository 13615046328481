import React from "react";
import _pubA97D53F3 from "../../../../../design-system/src/atoms.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Modal = React.memo(React.forwardRef(function Modal(props, ref) {
  return React.createElement("div", {
    "className": "_ecba5e7f _de25e084 _pub-de25e084 _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["fixed"] ? " " + "_de25e084_fixed _pub-de25e084_fixed _pub-a97d53f3_fixed fixed" : "") + (props["isVisible"] ? " " + "_de25e084_visible _pub-de25e084_visible _pub-a97d53f3_visible visible" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_8d50ece0 _de25e084 _pub-de25e084 _pub-a97d53f3",
      "onClick": props["onBackgroundClick"],
    }, null),
    React.createElement("div", {
      "className": "_fa57dc76 _de25e084 _pub-de25e084 _pub-a97d53f3" + " " + "_de25e084_modal _pub-de25e084_modal _pub-a97d53f3_modal modal",
    }, 
      React.createElement("div", {
        "className": "_5f24a348 _de25e084 _pub-de25e084 _pub-a97d53f3",
      }, 
        React.createElement("div", {
          "className": "_de5b71eb _de25e084 _pub-de25e084 _pub-a97d53f3",
        }, 
          props["title"]
        ),
        props["children"]
      ),
      React.createElement("div", {
        "className": "_b647067d _de25e084 _pub-de25e084 _pub-a97d53f3",
      }, 
        React.createElement("div", {
          "className": "_43df5ce3 _de25e084 _pub-de25e084 _pub-a97d53f3",
        }, 
          props["footerControls"]
        )
      )
    )
  )
}));
export { Modal };


