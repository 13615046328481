import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyChart, CompanyFileDocumentType } from '@hum/types';
import { ChartList } from '@hum/icm-app/src/components/ChartList';
import { Icon, Spinner, Splash, Text, TextType } from '@hum/ui-library';
import { PageContent } from '@hum/legacy-ui';
import { AppState } from '@hum/icm-app/src/state';
import { useSyndicationInsightsCharts } from '@hum/icm-app/src/hooks/useSyndicationInsightsCharts';
import { INSIGHTS_TABS } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail';
import { InsightsCard } from './InsightsCard';
import { downloadCompanyFinancialButtonClicked } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/actions';

export const CompanyFinancialAnalysisView = ({
  companyId,
  withCard,
}: {
  companyId: number;
  withCard?: boolean;
}) => {
  const { chartTabName } = useParams<{ chartTabName?: INSIGHTS_TABS }>();
  const dispatch = useDispatch();
  const onClickDownloadButton = () => {
    dispatch(
      downloadCompanyFinancialButtonClicked({
        companyId: companyId,
        docType: CompanyFileDocumentType.ChartbookInvestorAnalytics,
      })
    );
  };
  const isDownloadingCompanyFinancialsFile = useSelector(
    (state: AppState) => state.isDownloadingCompanyFinancialsFile
  );
  const chartsData = useSyndicationInsightsCharts(companyId);
  const chartsContent = chartsData.data![chartTabName!];

  if (!chartsData) {
    return <Spinner fullScreen />;
  }

  return (
    <div style={{ height: '100%' }}>
      {
        // @ts-ignore
        chartsContent?.length ? (
          <PageContent fixedWidth padded center>
            {withCard && (
              <InsightsCard
                onDownload={onClickDownloadButton}
                isDownloadingFile={isDownloadingCompanyFinancialsFile.status}
              />
            )}
            <ChartList charts={chartsContent as CompanyChart[]} />
          </PageContent>
        ) : (
          <div className="flex h-[calc(100vh-48px)]">
            <div className="flex flex-col items-center justify-around w-full">
              <Splash
                graphic={
                  <Icon.InvestorSignupStepThree className="max-w-[200px] mx-auto" />
                }
                title="Connect data to see insights"
                subtitle="Connect your accounting software in the Data Sharing tab or upload Income Statement and Balance sheet data in your data room. Some additional insights are also available when you connect to your bank and/or payment processor data as well."
              >
                <Text
                  type={TextType.BODY_S}
                  subdued
                  className="max-w-md mx-auto"
                >
                  Note: Insights can take some time to generate once your data
                  is connected, thank you for your patience
                </Text>
              </Splash>
            </div>
          </div>
        )
      }
    </div>
  );
};
