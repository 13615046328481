import React from "react";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../../design-system/src/utils.pc";
import _pub8Ec1B7A from "../../styles/common.pc";
import _pub972E797, {OnboardingLayout as _pub972E797_OnboardingLayout,OnboardingContent as _pub972E797_OnboardingContent} from "../../styles/layout.pc";
import _pub5079941C from "../../styles/splash.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var VerifyEmail = React.memo(React.forwardRef(function VerifyEmail(props, ref) {
  return React.createElement(_pub5079941C, {
    "class": "_a97d0444 _51df9762 _pub-51df9762 _pub-a97d53f3",
    "ref": ref,
    "graphic": React.createElement("img", {
      "className": "_1cca7616 _51df9762 _pub-51df9762 _pub-a97d53f3",
      "src": getDefault(require("@hum/assets/mailbox.png")),
    }, null),
    "title": "Verify your email",
    "subtitle": "To make sure you’re you, we’ve sent a verification email to " + props["email"] + ". Click the link inside to continue.",
    "footer": props["returnToLogin"],
  }, null)
}));
export { VerifyEmail };

var SignUpVerifyEmail = React.memo(React.forwardRef(function SignUpVerifyEmail(props, ref) {
  return React.createElement(_pub5079941C, {
    "class": "_4ec52943 _51df9762 _pub-51df9762 _pub-a97d53f3",
    "ref": ref,
    "graphic": React.createElement("img", {
      "className": "_82495b98 _51df9762 _pub-51df9762 _pub-a97d53f3",
      "src": getDefault(require("@hum/assets/mailbox.png")),
    }, null),
    "title": "Verify your email",
    "subtitle": "Your account has been created. We’ve sent a verification email to " + props["email"] + ". Click the link inside to continue.",
    "footer": props["returnToLogin"],
  }, null)
}));
export { SignUpVerifyEmail };

var VerifyEmailV5 = React.memo(React.forwardRef(function VerifyEmailV5(props, ref) {
  return React.createElement(_pub5079941C, {
    "class": "_ca82b16b _51df9762 _pub-51df9762 _pub-a97d53f3",
    "ref": ref,
    "graphic": React.createElement("img", {
      "className": "_b778b4fa _51df9762 _pub-51df9762 _pub-a97d53f3",
      "src": getDefault(require("@hum/assets/mailbox.png")),
    }, null),
    "title": "Verify your email to continue your funding journey",
    "subtitle": "To make sure you’re you, we’ve sent a verification email to " + props["email"] + ". Click the link inside to continue.",
    "footer": props["returnToLogin"],
  }, null)
}));
export { VerifyEmailV5 };


