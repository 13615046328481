import React, { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '@hum/icm-app/src/state';
import { submitDetailedConnectorRequestForm, useApiMutation } from '@hum/api';
import { Button, ButtonType, Modal, Select, TextInput } from '@hum/ui-library';
import { CONNECTOR_OPTIONS, REASON_OPTIONS } from '@hum/types';

export type CannotConnectModalProps = {
  isOpen: boolean;
  onBack: () => void;
  withTransitions?: boolean;
};

export const CannotConnectModal = ({
  isOpen,
  onBack,
  withTransitions,
}: CannotConnectModalProps) => {
  const history = useHistory();
  const [reason, setReason] = useState('');
  const [connector, setConnector] = useState('');
  const { mutate: submitDetailedForm } = useApiMutation(
    submitDetailedConnectorRequestForm
  );
  const disabled = useMemo(
    () =>
      ['I don’t see my connector', 'Other'].includes(reason)
        ? !connector
        : !reason,
    [reason, connector]
  );

  const pushOnboardingCompleted = useCallback(() => {
    history.push(Routes.ONBOARDING_V5_UPLOAD_FILES);
  }, []);

  return (
    <Modal
      testId="cannot-connect-modal"
      isOpen={isOpen}
      title="Not able to connect?"
      description="Connecting your accounting software will unlock exclusive funding opportunities. If you're not able to do so, please share some feedback with us."
      withTransitions={withTransitions}
      onClose={onBack}
    >
      <Select
        className="px-6 pb-7"
        id="select-reason"
        testId="select-reason"
        onSelect={(selection) => {
          setConnector('');
          setReason(selection.value);
        }}
        options={REASON_OPTIONS}
        placeholder="Select a reason"
      />
      {reason === 'I don’t see my connector' && (
        <Select
          className="px-6 pb-7"
          id="select-connector"
          testId="select-connector"
          onSelect={(selection) => setConnector(selection.value)}
          options={CONNECTOR_OPTIONS}
          placeholder="What connector are you looking for?"
        />
      )}
      {reason === 'Other' && (
        <div className="m-6">
          <TextInput
            placeholder="Let us know why"
            id="other-connector"
            name="other-connector"
            testId="other-connector"
            value={connector}
            onChange={(value) => setConnector(value)}
            multiline
          />
        </div>
      )}
      <div className="flex bg-surface-dark p-4 gap-4">
        <div className="grow">
          <Button type={ButtonType.QUIET} onClick={onBack}>
            Back to connect
          </Button>
        </div>
        <Button type={ButtonType.QUIET} onClick={pushOnboardingCompleted}>
          Skip
        </Button>
        <Button
          disabled={disabled}
          onClick={() => {
            submitDetailedForm({
              environment: process.env.NODE_ENV,
              reason,
              connector,
            });
            pushOnboardingCompleted();
          }}
        >
          Send
        </Button>
      </div>
    </Modal>
  );
};
