import { fork } from 'redux-saga/effects';
import { handleVerifyEmail } from './verify-email';
import { handleConnectorActivation } from './connector-activation';
import { trackOnboardingV4Actions } from './analytics';
import { handleAcceptFeeTosButtonClicked } from './fee';
import { handleAcceptMNDAButtonClicked } from './mnda';
import { handleFundraisingForm } from './fundraising';
import { handleBasicInfoForm } from './svb';

export function* handleV4Onboarding() {
  yield fork(handleConnectorActivation);
  yield fork(handleVerifyEmail);
  yield fork(handleAcceptFeeTosButtonClicked);
  yield fork(trackOnboardingV4Actions);
  yield fork(handleAcceptMNDAButtonClicked);
  yield fork(handleFundraisingForm);
  yield fork(handleBasicInfoForm);
}
