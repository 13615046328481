import React from 'react';

import { FooterWrapper, FooterLink } from './styles.pc';

export const FooterOnboarding = () => (
  <FooterWrapper>
    <FooterLink href="https://humcapital.com/terms-of-service/" target="_blank">
      Terms
    </FooterLink>
    <FooterLink href="https://humcapital.com/privacy-policy/" target="_blank">
      Privacy Policy
    </FooterLink>
  </FooterWrapper>
);
