import React from "react";
import _pub5Ccf593C, {__DEPRECATED__ToggleField as _pub5Ccf593C___DEPRECATED__ToggleField} from "../../../../../design-system/src/Toggle.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../design-system/src/utils.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Input = React.memo(React.forwardRef(function Input(props, ref) {
  return React.createElement(_pub5Ccf593C, {
    "class": "_2b2ce5cc _3c48e9be _pub-3c48e9be",
    "ref": ref,
    "on": props["on"],
    "testId": props["testId"],
  }, null)
}));
export { Input };

var Container = React.memo(React.forwardRef(function Container(props, ref) {
  return React.createElement(_pub5Ccf593C___DEPRECATED__ToggleField, {
    "class": "_c24f40f9 _3c48e9be _pub-3c48e9be" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "label": props["label"],
    "onClick": props["onClick"],
    "testId": props["testId"],
  }, 
    props["children"]
  )
}));
export { Container };


