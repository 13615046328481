import React from "react";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import _pubBab5A68F from "../../../../../../design-system/src/utils.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var FooterWrapper = React.memo(React.forwardRef(function FooterWrapper(props, ref) {
  return React.createElement("div", {
    "className": "_fbf1f542 _61d7f078 _pub-61d7f078 _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { FooterWrapper };

var FooterLink = React.memo(React.forwardRef(function FooterLink(props, ref) {
  return React.createElement("a", {
    "className": "_12925077 _61d7f078 _pub-61d7f078 _pub-a97d53f3",
    "ref": ref,
    "href": props["href"],
    "target": props["target"],
  }, 
    props["children"]
  )
}));
export { FooterLink };


