import { UserFillFundraisingForm, OnboardingV4ActionType } from '../actions';
import { call, takeEvery } from 'redux-saga/effects';
import { getHistory, sendFundraisingForm } from '@hum/icm-app/src/sagas/utils';

import { routeCreators, Routes } from '@hum/icm-app/src/state';
import { catchError } from '@hum/icm-app/src/modules/sagas';
import { noop } from 'lodash';
import { History } from 'history';
export function* handleFundraisingForm() {
  const history: History = yield getHistory();
  yield takeEvery(
    OnboardingV4ActionType.USER_FILL_FUNDRAISING_FORM,
    catchError(function* ({
      payload: { companyId, payload },
    }: UserFillFundraisingForm) {
      yield call(sendFundraisingForm, companyId, payload);
      history.push(routeCreators[Routes.ONBOARDING_V4_CONNECTORS]());
    },
    noop)
  );
}
