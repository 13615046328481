import { useEffect, useState } from 'react';
import { AccountCreated } from '../components/Overview/ducks/actions';
import { Routes, NoticeName } from '@hum/icm-app/src/state';
import { useCompanyNotice } from '@hum/icm-app/src/hooks/useCompanyNotice';
import { useService } from '@hum/icm-app/src/pages/onboarding-v5/components/OnboardingConnectStep_DeprecateAfterM2_5/useService';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { useFileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { MndaOptions } from '@hum/icm-app/src/components/types';
import { overviewCopy } from '../components/Overview/utils';
import { overviewSteps, StepIndex } from '@hum/types';
import { useAnalyticsEvent } from '@hum/api';

export const useOverview = () => {
  const [currentStep, setCurrentStep] = useState(
    StepIndex.CREATE_ACCOUNT_INDEX
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const company = useCurrentCompany();
  const fileManager = useFileManager({ companyId: Number(company.data?.id) });
  const { hasOneServiceConnected, uploadedFilesComplete } = useService(
    fileManager
  );
  const acceptedNDA = !!company.data?.acceptedNDA;
  const dataRoomDone = hasOneServiceConnected || uploadedFilesComplete;
  const isMndaPending =
    company.data?.pendingMndaSignOffline == MndaOptions.Pending;
  const [
    accountCreatedCompleted,
    accountCreatedCompletedLoaded,
    accountCreatedComplete,
  ] = useCompanyNotice(
    NoticeName.ChooseYourAdventureAccountCreated,
    company.data?.id
  );
  const { sideDescription } = overviewCopy;
  const trackEvent = useAnalyticsEvent();
  const title = 'Welcome to Hum';
  const subtitle = sideDescription;

  const handleMndaClick = () => {
    history.push(Routes.ONBOARDING_V5_MNDA + history.location.search);
  };

  const handleDataroomClick = () => {
    history.push(Routes.ONBOARDING_V5_CONNECT + history.location.search);
  };

  useEffect(() => {
    trackEvent('company-visit-welcome-to-hum');
  }, []);

  useEffect(() => {
    if (acceptedNDA) {
      setCurrentStep(StepIndex.BUILD_DATAROOM_INDEX);
      return;
    }

    setCurrentStep(
      Object.values(overviewSteps).findIndex((step) => step.completed) + 1
    );
  }, [company?.data]);

  useEffect(() => {
    if (accountCreatedCompletedLoaded) {
      if (!accountCreatedCompleted) {
        dispatch(AccountCreated());
        accountCreatedComplete();
      }
    }
  }, [accountCreatedCompletedLoaded]);

  return {
    company,
    title,
    subtitle,
    currentStep,
    dataRoomDone,
    handleMndaClick,
    handleDataroomClick,
    isMndaPending,
    acceptedNDA,
  };
};
