import React, { Suspense } from 'react';
import { Spinner } from '@hum/ui-library';
import { AdminPage } from '@hum/icm-app/src/pages/admin/outer';

export const LabelingUIPage = () => {
  return (
    <AdminPage>
      <LabelingUI />
    </AdminPage>
  );
};

export const LabelingUI = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <LazyLabelingUI />
    </Suspense>
  );
};

const LazyLabelingUI = React.lazy(() => import('@hum/labeling-ui/src'));
