import { call, takeEvery } from 'redux-saga/effects';
import { History } from 'history';

import { UserFillInfoForm, OnboardingV4ActionType } from '../actions';

import { getHistory, sendUserInfoForm } from '@hum/icm-app/src/sagas/utils';
import { routeCreators, Routes } from '@hum/icm-app/src/state';

export function* handleBasicInfoForm() {
  const history: History = yield getHistory();
  yield takeEvery(
    OnboardingV4ActionType.USER_FILLED_INFO_FORM,
    function* ({ payload: { companyId, payload } }: UserFillInfoForm) {
      yield call(sendUserInfoForm, companyId, payload);
      history.push(routeCreators[Routes.ONBOARDING_V4_COMPANY_INFO]());
    }
  );
}
