import * as sidebar from '@hum/icm-app/src/pages/syndication-v2/sidebar.pc';
import * as styles from '@hum/icm-app/src/pages/portfolio-monitoring/styles.pc';

import { ChartList } from '@hum/icm-app/src/components/ChartList';
import React from 'react';
import { Spinner } from '@hum/ui-library';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { money } from '@hum/icm-app/src/utils/format';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useCompany } from '@hum/icm-app/src/hooks/useCompany';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { CompanyChart } from '@hum/types';
import { useSyndicationInsightsCharts } from '@hum/icm-app/src/hooks/useSyndicationInsightsCharts';

// TODO right now when one reloads this page it does not render because the currentCompanyData is
// empty, this is due the fact rely on PortfolioMonitoringDashboard to load first and then fill
// in portfolioMonitoringDashboard, we need to make it so this page can be reloaded
export const PortfolioMonitoringCompany = () => {
  const { state } = useAppStore();
  const { portfolioMonitoringDashboard } = state;
  let { companyId } = useParams<{ companyId: string }>();
  const { data: dashboardData } = portfolioMonitoringDashboard;
  const currentCompanyData = dashboardData && dashboardData[companyId];
  const history = useHistory();
  const { data: company } = useCompany(Number(companyId));

  let companyCharts: CompanyChart[] = [];

  const chartsContent = useSyndicationInsightsCharts(Number(companyId));
  if (chartsContent.data) {
    for (let chartCategory in chartsContent.data) {
      chartsContent.data[chartCategory].forEach((chart: CompanyChart) =>
        companyCharts.push(chart)
      );
    }
  }

  return (
    <>
      <Topbar />
      {currentCompanyData ? (
        <styles.CompanyDetail
          title={company?.name}
          ltmRevenue={
            currentCompanyData.ltmRevenue
              ? money(currentCompanyData.ltmRevenue)
              : 'N/A'
          }
          cashOnHand={
            currentCompanyData.cashOnHand
              ? money(currentCompanyData.cashOnHand)
              : 'N/A'
          }
          lastMonthEBITDA={
            currentCompanyData.lastMonthEBITDA
              ? money(currentCompanyData.lastMonthEBITDA)
              : 'N/A'
          }
          clickGoBack={() => {
            history.goBack();
          }}
        >
          {chartsContent?.data ? (
            <ChartList charts={companyCharts} />
          ) : (
            <sidebar.EmptyInsights />
          )}
        </styles.CompanyDetail>
      ) : (
        <Spinner fullScreen />
      )}
    </>
  );
};
