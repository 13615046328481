import {
  DisconnectButtonClicked,
  ManualImportContinueButtonClicked,
  OnboardingV4ActionType,
} from '../actions';
import { call, takeEvery, select } from 'redux-saga/effects';
import {
  deleteConnector,
  requestForConnectors,
  locationMatches,
  getHistory,
} from '@hum/icm-app/src/sagas/utils';

import {
  AppState,
  getUserCompanyId,
  routeCreators,
  Routes,
} from '@hum/icm-app/src/state';
import {
  ActionType,
  APIConnectorSavedSuccessfully,
} from '@hum/icm-app/src/actions';
import { History } from 'history';

export function* handleConnectorActivation() {
  // UI events
  yield takeEvery(
    OnboardingV4ActionType.MANUAL_IMPORT_CONTINUE_BUTTON_CLICKED,
    handleManualImportContinueButtonClicked
  );

  yield takeEvery(
    OnboardingV4ActionType.DISCONNECT_BUTTON_CLICKED,
    handleConnectorDisconnectButtonClicked
  );
  yield takeEvery(
    ActionType.API_CONNECTOR_SAVED_SUCCESSFULLY,
    handleServiceConnectedRedirects
  );

  // API events
  yield takeEvery(
    ActionType.CONNECTORS_REQUEST_STATE_CHANGED,
    onConnectorsLoaded
  );
}

function* handleConnectorDisconnectButtonClicked({
  payload: { connector, airbyteEnabled },
}: DisconnectButtonClicked) {
  yield call(deleteConnector, connector, airbyteEnabled);
}

function* handleServiceConnectedRedirects(
  action: APIConnectorSavedSuccessfully
) {
  const history: History = yield getHistory();
  // must be on connectors service page
  if (!(yield locationMatches(Routes.ONBOARDING_V4_CONNECTORS))) {
    return;
  }

  const linkedAccountsPath =
    routeCreators[Routes.ONBOARDING_V4_CONNECTORS_CONNECTED](
      action.payload.connectorType
    ) + history.location.search;

  // I think there's a race condition going on, but sometimes the location
  // doesn't change, so we're waiting for a sec before redirecting
  setTimeout(() => {
    history.push(linkedAccountsPath);
  });
}

function* onConnectorsLoaded() {
  const state: AppState = yield select();
  const history: History = yield getHistory();
  if (!state.connectors.loaded) {
    return;
  }

  // must be on connectors service page
  if (!(yield locationMatches(Routes.ONBOARDING_V4_CONNECTORS_CONNECTED))) {
    return;
  }

  const { name } = state.history?.params || {};

  const hasConnected = state.connectors.data?.some(
    (connector) => connector.connectorType === name
  );

  if (!hasConnected) {
    history.push(routeCreators[Routes.ONBOARDING_V4_CONNECTORS]());
  }
}

function* handleManualImportContinueButtonClicked({
  payload: { missingServices },
}: ManualImportContinueButtonClicked) {
  const history: History = yield getHistory();
  const state: AppState = yield select();
  try {
    yield call(requestForConnectors, getUserCompanyId(state), missingServices);
  } catch (e) {
    return;
  }

  history.push(routeCreators[Routes.ONBOARDING_V4_COMPLETED]());
}
