import React from 'react';
import { useRouter } from '@hum/icm-app/src/hooks/useRouter';
import { Welcome } from '@hum/icm-app/src/components/Welcome';
import { CompanyContactStatus } from '@hum/types';
import { useInvite } from '@hum/icm-app/src/hooks/useInvite';
import { Button, Spinner } from '@hum/ui-library';
import { useHistory } from 'react-router';

export const InvitePage = () => {
  const router = useRouter<{ token: string }>();
  const { loading, error, invite } = useInvite(router.query.token);

  if (error) {
    throw error;
  }
  const history = useHistory();

  if (loading) {
    return <Spinner fullScreen />;
  }

  if (!invite) {
    history.push({ pathname: '/' });
    return null;
  }

  const claimed = invite.user.status === CompanyContactStatus.Active;

  const title = claimed
    ? `To access ${invite.company.name}, please create an account.`
    : `${invite.company.name} awaits.`;

  const subtitle = claimed
    ? `Contact ${invite.user.email} for an account invitation.`
    : `A new access link will be sent to ${invite.user.email}.`;

  const renderButton = () => (
    <Button onClick={() => history.push('/recover')}>Get link</Button>
  );

  return (
    <>
      <Welcome title={title} subtitle={subtitle}>
        {claimed ? null : renderButton()}
      </Welcome>
    </>
  );
};
