import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';

export enum OverviewActionType {
  ACCOUNT_CREATED = 'onboarding-v5/ACCOUNT_CREATED',
}

export type AccountCreated = BaseAction<OverviewActionType.ACCOUNT_CREATED>;
export const AccountCreated = actionCreator<AccountCreated>(
  OverviewActionType.ACCOUNT_CREATED
);

export type OverviewActions = AccountCreated;
