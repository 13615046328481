import * as Yup from 'yup';

import { Modal } from '@hum/design-system';
import { Field, TextInput } from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import {
  apiCompanyUserAdded,
  apiCompanyUserUpdated,
} from '@hum/icm-app/src/actions';
import { useField, useForm } from '@hum/common/src/modules/form';
import { toast } from '@hum/common/src/modules/toast';
import { ChannelType, CompanyContact } from '@hum/types';
import React from 'react';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { createCompanyUser, updateCompanyUser, useApiMutation } from '@hum/api';

export type AddUserModalProps = {
  currentContact?: CompanyContact;
  companyId: number;
  isOpen: boolean;
  onClose: () => void;
  companyType: string;
};

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  channel: '',
  position: '',
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  phone: Yup.string().nullable(),
  position: Yup.string().required('Required'),
});

export const AddUserModal = ({
  currentContact,
  companyId,
  companyType,
  isOpen,
  onClose,
}: AddUserModalProps) => {
  const flags = useFlags();
  const { dispatch } = useAppStore();
  const isUpdate = !!currentContact?.id;
  const api = useAPI();
  const { mutate: createUser } = useApiMutation(createCompanyUser, {
    onSuccess: (data) => {
      toast.success(`An invitation has been sent to ${data.email}`);
      handleClose();
    },
    onError: () => {
      toast.error('Failed to invite user');
    },
  });
  const { mutate: updateUser } = useApiMutation(updateCompanyUser, {
    onSuccess: (data) => {
      toast.success(`${data.email} has been successfully edited`);
      handleClose();
    },
    onError: () => {
      toast.error('Failed to update user');
    },
  });

  const form = useForm({
    initialValues: currentContact || initialValues,
    validationSchema,
    onSubmit: async (fields) => {
      const { position, ...rest } = fields;
      if (currentContact) {
        if (flags.enabled('use-react-query')) {
          updateUser({
            companyId,
            email: rest.email,
            firstName: rest.firstName,
            lastName: rest.lastName,
            phone: rest.phone,
            companyPosition: position,
            userId: currentContact.id,
          });
        } else {
          const response = await api.legacy.updateCompanyUser(
            companyId,
            currentContact.id,
            fields
          );

          // api replies with minimal user
          if (response.email) {
            dispatch(apiCompanyUserUpdated());
            toast.success(`${response.email} has been successfully edited`);
            handleClose();
          }
        }
      } else {
        fields.channel =
          companyType == 'company'
            ? ChannelType.icm_company_invitation
            : ChannelType.icm_investor_invitation;

        if (flags.enabled('use-react-query')) {
          createUser({ companyId, companyPosition: position, ...rest });
        } else {
          const response = await api.legacy.addCompanyUser(companyId, fields);

          // api replies with company object
          if (response.company?.id) {
            dispatch(apiCompanyUserAdded());
            toast.success(`An invitation has been sent to ${response.email}`);
            handleClose();
          }
        }
      }
    },
  });

  const handleClose = () => {
    form.resetForm();
    onClose();
  };
  const itemsField = [
    { testId: 'first-name', label: 'First Name', property: 'firstName' },
    { testId: 'last-name', label: 'Last Name', property: 'lastName' },
    { testId: 'email', label: 'Email', property: 'email' },
    { testId: 'phone', label: 'Phone', property: 'phone' },
    { testId: 'position', label: 'Position', property: 'position' },
  ];

  return (
    <Modal
      visible={isOpen}
      scrollable
      header={isUpdate ? 'Edit' : 'Invite user'}
      onClose={handleClose}
      onSubmit={form.handleSubmit}
      side
      wide
      footer={
        <>
          <Button type={ButtonType.SECONDARY} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            testId="send-invite-button"
            submit
            loading={form.isSubmitting}
            disabled={form.isSubmitting}
          >
            {currentContact ? 'Save' : 'Send invite'}
          </Button>
        </>
      }
    >
      {itemsField.map((item) => (
        <Field
          key={item.testId}
          label={item.label}
          v3
          {...useField(`${item.property}`, 'text', form).input}
        >
          {(props) => (
            <TextInput
              {...props}
              data-testid={`${item.testId}-input`}
              {...(item.testId === 'first-name'
                ? { autoFocus: true }
                : { autoFocus: false })}
            />
          )}
        </Field>
      ))}
    </Modal>
  );
};
