import React from 'react';
import { Modal } from '@hum/design-system';
import { TextInput, Field } from '@hum/legacy-ui';
import { Button, ButtonType, Scrollable } from '@hum/ui-library';
import { useField, useForm } from '@hum/common/src/modules/form';
import * as Yup from 'yup';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { PortfolioMonitoringInvite } from '@hum/icm-app/src/state';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import {
  createPortfolioInvite,
  updatePortfolioInvite,
  useApiMutation,
} from '@hum/api';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

export type FormData = {
  title: string;
  button: string;
};

export type CompanyInvitationModalProps = {
  currentCompany?: PortfolioMonitoringInvite;
  companyId: number;
  isOpen: boolean;
  onClose: () => void;
  formData: FormData;
};

const initialValues = {
  companyName: '',
  companyUrl: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPosition: '',
  investedCapital: 0,
  dateOfFirstInvestment: null,
};

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  companyUrl: Yup.string().nullable(),
  contactFirstName: Yup.string().required('Required'),
  contactLastName: Yup.string().required('Required'),
  contactEmail: Yup.string().email().required('Required'),
  contactPosition: Yup.string().nullable(),
});

export const CompanyInvitationModal = ({
  currentCompany,
  companyId,
  isOpen,
  onClose,
  formData,
}: CompanyInvitationModalProps) => {
  const flags = useFlags();
  const { mutate: updateInvite } = useApiMutation(updatePortfolioInvite, {
    onSuccess: () => {
      handleClose();
    },
  });
  const { mutate: createInvite } = useApiMutation(createPortfolioInvite, {
    onSuccess: () => {
      handleClose();
    },
  });
  const { dispatch } = useAppStore();
  const api = useAPI();

  const form = useForm({
    initialValues: currentCompany || initialValues,
    validationSchema,
    onSubmit: async (fields) => {
      if (currentCompany) {
        if (flags.enabled('use-react-query')) {
          updateInvite({
            companyId,
            inviteId: currentCompany.id,
            ...fields,
          });
        } else {
          await api.portfolioMonitoring
            .updatePortfolioInvite(dispatch)(companyId, currentCompany.id, {
              status: currentCompany.status,
              companyName: fields.companyName,
              companyUrl: fields.companyUrl,
              contactFirstName: fields.contactFirstName,
              contactLastName: fields.contactLastName,
              contactEmail: fields.contactEmail,
              contactPosition: fields.contactPosition,
            })
            .then(() => handleClose());
        }
      } else {
        if (flags.enabled('use-react-query')) {
          createInvite({ companyId, ...fields });
        } else {
          const response = await api.portfolioMonitoring.createPortfolioInvite(
            dispatch
          )(companyId, fields);
          if (response.data.id) {
            await api.portfolioMonitoring
              .bulkCompanyStatusUpdate(dispatch)(companyId, [response.data.id])
              .then(() => handleClose());
          }
        }
      }
    },
  });
  const handleClose = () => {
    form.resetForm();
    onClose();
  };
  const itemsField = [
    {
      id: 1,
      label: 'Company Name',
      property: 'companyName',
      testid: 'company-name',
    },
    { id: 2, label: 'Website', property: 'companyUrl', testid: 'website' },
    {
      id: 3,
      label: 'First Name',
      property: 'contactFirstName',
      testid: 'first-name',
    },
    {
      id: 4,
      label: 'Last Name',
      property: 'contactLastName',
      testid: 'last-name',
    },
    { id: 5, label: 'Email', property: 'contactEmail', testid: 'email' },
    {
      id: 6,
      label: 'Position',
      property: 'contactPosition',
      testid: 'position',
    },
  ];
  return (
    <Modal
      visible={isOpen}
      header={formData.title}
      onClose={handleClose}
      onSubmit={form.handleSubmit}
      side
      wide
      footer={
        <>
          <Button type={ButtonType.SECONDARY} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            submit
            loading={form.isSubmitting}
            disabled={form.isSubmitting}
          >
            {formData.button}
          </Button>
        </>
      }
    >
      <Scrollable>
        {itemsField.map((item) => (
          <Field
            key={item.id}
            label={item.label}
            v3
            {...useField(`${item.property}`, 'text', form).input}
          >
            {(props) => (
              <TextInput
                {...props}
                data-testid={`${item.testid}-input`}
                {...(item.id === 1
                  ? { autoFocus: true }
                  : { autoFocus: false })}
              />
            )}
          </Field>
        ))}
      </Scrollable>
    </Modal>
  );
};
