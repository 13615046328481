import React, { memo, useState } from 'react';

import { StatusCodeDot } from '@hum/design-system';
import { Button, ButtonType, Spinner } from '@hum/ui-library';
import { CompanyContact, CompanyContactStatus } from '@hum/types';

import { AddUserModal } from './AddUserModal';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { useCompanyUsers } from '@hum/icm-app/src/hooks/useCompanyUsers';

import { PageTitle, Pill, Table, TableCellConfig } from '@hum/legacy-ui';
import * as styles from '@hum/icm-app/src/pages/settings/styles.pc';

import editIcon from '@hum/icm-app/src/pages/portfolio-monitoring/assets/edit-icon.svg';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { getCompanyUsers, useApiQuery } from '@hum/api';

const TABLE_CELLS: Record<string, TableCellConfig> = {
  name: {
    label: 'NAME',
    renderer: ({ item }: { item: CompanyContact }) => {
      return (
        <>
          <styles.WrapperData>
            {`${item.firstName} ${item.lastName} `}
          </styles.WrapperData>
          {item.isPrimaryContact && <Pill>Primary</Pill>}
        </>
      );
    },
  },
  email: {
    label: 'EMAIL',
    renderer: ({ item }: { item: CompanyContact }) => {
      return <styles.WrapperData>{item.email}</styles.WrapperData>;
    },
  },
  status: {
    label: 'STATUS',
    renderer: ({ item: item, onButtonEditClick, isPrimaryContact }: any) => (
      <>
        <StatusCodeDot
          active={item.status === CompanyContactStatus.Active}
          disabled={item.status === CompanyContactStatus.Disabled}
          warning={item.status === CompanyContactStatus.Invited}
        />
        {item.status.toUpperCase()}
        {isPrimaryContact && !item.isPrimaryContact && (
          <Button
            type={ButtonType.SECONDARY}
            className="button_cell"
            testId="table-lastcell-button"
            onClick={() => onButtonEditClick(item)}
          >
            <img src={editIcon} />
          </Button>
        )}
      </>
    ),
  },
};

export const TeamTab = memo(() => {
  const flags = useFlags();
  const company = useCurrentCompany();
  const companyId = company.data?.id!;
  const { data: companyUsers, isLoading: isCompanyUsersLoading } = useApiQuery(
    getCompanyUsers,
    {
      params: { companyId },
      enabled: flags.enabled('use-react-query'),
    }
  );
  const { data: sagaUsers, loaded: loadedSagasUsers } = useCompanyUsers(
    companyId
  );
  const users = flags.enabled('use-react-query') ? companyUsers : sagaUsers;
  const loaded = flags.enabled('use-react-query')
    ? !isCompanyUsersLoading
    : loadedSagasUsers;
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [currentContact, setCurrentContact] = useState<
    CompanyContact | undefined
  >();

  const onUserModalClose = () => {
    setCurrentContact(undefined);
    setInviteUserModalOpen(false);
  };

  const onInviteButtonClick = () => {
    setInviteUserModalOpen(true);
  };

  const {
    state: { session },
  } = useAppStore();

  const primaryContact = users?.find((user) => user.isPrimaryContact);
  const isPrimaryContact = primaryContact?.id === session.data?.id;

  const onButtonEditClick = (item: CompanyContact) => {
    setCurrentContact(item);
    setInviteUserModalOpen(true);
  };

  if (!loaded && users !== undefined && users.length === 0) {
    return <Spinner fullScreen />;
  }

  return (
    <>
      <PageTitle
        title="Manage users"
        noCenter
        action={
          isPrimaryContact && (
            <Button
              onClick={onInviteButtonClick}
              testId="settings-page:manage-users-btn"
            >
              Invite users
            </Button>
          )
        }
      />
      <Table
        cellConfig={TABLE_CELLS}
        cellProps={{ onButtonEditClick, isPrimaryContact }}
        itemKey="id"
        data={users || []}
        secondary
        fixedCellWidth={true}
        nowrap
      />
      <AddUserModal
        companyId={company.data?.id!}
        companyType={company.data?.type[0]!}
        isOpen={inviteUserModalOpen}
        currentContact={currentContact}
        onClose={onUserModalClose}
      />
    </>
  );
});
TeamTab.displayName = 'Manage Users';
