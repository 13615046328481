import {
  AcceptMndaButtonClicked,
  MNDAActionType,
  acceptMndaSuccess,
  acceptMndaFail,
} from './actions';
import { call, takeLeading, put } from 'redux-saga/effects';
import { acceptMNDA } from '@hum/icm-app/src/sagas/utils';
import { toast } from '@hum/common/src/modules/toast';

export function* handleMNDA() {
  yield takeLeading(
    [MNDAActionType.ACCEPT_MNDA_BUTTON_CLICKED],
    function* ({ payload: { companyId, payload } }: AcceptMndaButtonClicked) {
      try {
        yield call(acceptMNDA, companyId, payload);
        yield call(() =>
          toast.info('We’ve emailed you a signed copy of the agreement')
        );
        yield put(acceptMndaSuccess());
      } catch (error) {
        yield call(() => toast.error('Failed to process request'));
        yield put(acceptMndaFail());
      }
    }
  );
}
