import { useCallback, useMemo, useState } from 'react';

import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { useApiQuery, getConnectorConfigs, getConnectors } from '@hum/api';
import { ConnectorStatus, ConnectorOption, PlatformType } from '@hum/types';

export type IUseConnectorList = {
  filteredConnectors: ConnectorOption[];
  hasActiveConnections: boolean;
  success: {
    companyConnectors: boolean;
    connectorConfigs: boolean;
  };
  loading: {
    companyConnectors: boolean;
    connectorConfigs: boolean;
  };
  onFilterConnectors: (filter: string) => void;
  totalConnectors: number;
  selectedConnector: ConnectorOption | null;
  selectConnector: (connector: ConnectorOption | null) => void;
};

export const useConnectorList = (
  platformType: PlatformType = PlatformType.AccountingSystem,
  isOnboarding = true
): IUseConnectorList => {
  const flags = useFlags();

  const [connectorFilter, setConnectorFilter] = useState('');
  const [selectedConnector, selectConnector] = useState<ConnectorOption | null>(
    null
  );

  const {
    data: connections = [],
    isLoading: isCompanyConnectorsLoading,
    isSuccess: isCompanyConnectorsSuccess,
  } = useApiQuery(getConnectors);

  const {
    data: connectorConfigs = [],
    isLoading: isConnectorConfigsLoading,
    isSuccess: isConnectorConfigsSuccess,
  } = useApiQuery(getConnectorConfigs, {
    params: {
      platformType,
      includeBeta: true,
      flags,
    },
    enabled: flags.ready,
  });

  const activeConnections: ConnectorOption[] = useMemo(() => {
    return connections.filter(
      (connector) => connector.status === ConnectorStatus.Active
    );
  }, [connections]);
  const hasActiveConnections = activeConnections.length > 0;

  const connectors: ConnectorOption[] = useMemo(
    () =>
      activeConnections.length && isOnboarding
        ? activeConnections
        : connectorConfigs,
    [activeConnections, connectorConfigs]
  );

  const filteredConnectors = useMemo(() => {
    return connectors.filter((connector) =>
      connector?.config?.label
        .toLocaleLowerCase()
        .includes(connectorFilter.toLocaleLowerCase())
    );
  }, [connectorFilter, connectors]);

  const onFilterConnectors = useCallback(
    (filter: string) => {
      setConnectorFilter(filter);
    },
    [connectorFilter]
  );

  const totalConnectors = useMemo(() => connectors.length, [connectors]);

  return {
    filteredConnectors,
    success: {
      companyConnectors: isCompanyConnectorsSuccess,
      connectorConfigs: isConnectorConfigsSuccess,
    },
    loading: {
      companyConnectors: isCompanyConnectorsLoading,
      connectorConfigs: isConnectorConfigsLoading,
    },
    onFilterConnectors,
    totalConnectors,
    selectedConnector,
    selectConnector,
    hasActiveConnections,
  };
};
