import {
  Connector,
  MNDAPayloadType,
  FinancialDetailsPayload,
  UserInfoPayload,
} from '@hum/icm-app/src/state';
import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';

export enum OnboardingV4ActionType {
  ACCEPT_TOS_BUTTON_CLICKED = 'onboarding-v4/ACCEPT_TOS_BUTTON_CLICKED',
  DISCONNECT_BUTTON_CLICKED = 'onboarding-v4/DISCONNECT_BUTTON_CLICKED',
  MANUAL_IMPORT_CONTINUE_BUTTON_CLICKED = 'onboarding-v4/MANUAL_IMPORT_CONTINUE_BUTTON_CLICKED',
  EMAIL_NOT_FOUND_BUTTON_CLICKED = 'onboarding-v4/EMAIL_NOT_FOUND_BUTTON_CLICKED',
  VERIFY_EMAIL_RESENT = 'onboarding-v4/VERIFY_EMAIL_RESENT',
  SHOW_MANUAL_IMPORT_PAGE_BUTTON_CLICKED = 'onboarding-v4/SHOW_MANUAL_IMPORT_PAGE_BUTTON_CLICKED',
  USER_ACCEPT_MNDA = 'onboarding-v4/USER_ACCEPT_MNDA',
  ACCEPT_MNDA_BUTTON_CLICKED = 'onboarding-v4/ACCEPT_MNDA_BUTTON_CLICKED',
  SUCCESSFULLY_USER_ACCEPT_MNDA = 'onboarding-v4/SUCCESSFULLY_USER_ACCEPT_MNDA',
  FAILED_USER_ACCEPT_MNDA = 'onboarding-v4/FAILED_USER_ACCEPT_MNDA',
  USER_FILL_FUNDRAISING_FORM = 'onboarding-v4/USER_FILL_FUNDRAISING_FORM',
  SUCCESSFULLY_SENT_FUNDRAISING_FORM = 'onboarding-v4/SUCCESSFULLY_SENT_FUNDRAISING_FORM',
  FAILED_SENT_FUNDRAISING_FORM = 'onboarding-v4/FAILED_SENT_FUNDRAISING_FORM',
  USER_FILLED_INFO_FORM = 'onboarding-v4/USER_FILLED_INFO_FORM',
  SUCCESSFULLY_SENT_USER_INFO_FORM = 'onboarding-v4/SUCCESSFULLY_SENT_USER_INFO_FORM',
  FAILED_SENT_USER_INFO_FORM = 'onboarding-v4/FAILED_SENT_USER_INFO_FORM',
}

export type DisconnectButtonClicked = BaseAction<
  OnboardingV4ActionType.DISCONNECT_BUTTON_CLICKED,
  {
    connector: Connector;
    airbyteEnabled: boolean;
  }
>;

export type AcceptTosButtonClicked = BaseAction<
  OnboardingV4ActionType.ACCEPT_TOS_BUTTON_CLICKED,
  { companyId: number }
>;

export type ManualImportContinueButtonClicked = BaseAction<
  OnboardingV4ActionType.MANUAL_IMPORT_CONTINUE_BUTTON_CLICKED,
  {
    missingServices: string[];
  }
>;

export type UserAcceptMNDA = BaseAction<
  OnboardingV4ActionType.USER_ACCEPT_MNDA,
  { companyId: number; payload: MNDAPayloadType }
>;

export type SuccessfullyUserAcceptMNDA = BaseAction<OnboardingV4ActionType.SUCCESSFULLY_USER_ACCEPT_MNDA>;
export type FailedUserAcceptMNDA = BaseAction<OnboardingV4ActionType.FAILED_USER_ACCEPT_MNDA>;

export type UserFillFundraisingForm = BaseAction<
  OnboardingV4ActionType.USER_FILL_FUNDRAISING_FORM,
  { companyId: number; payload: FinancialDetailsPayload }
>;

export type UserFillInfoForm = BaseAction<
  OnboardingV4ActionType.USER_FILLED_INFO_FORM,
  { companyId: number; payload: UserInfoPayload }
>;

export type SuccessfullySentFundraisingForm = BaseAction<OnboardingV4ActionType.SUCCESSFULLY_SENT_FUNDRAISING_FORM>;
export type FailedSentFundraisingForm = BaseAction<OnboardingV4ActionType.FAILED_SENT_FUNDRAISING_FORM>;

export type SuccessfullySentUserInfoForm = BaseAction<OnboardingV4ActionType.SUCCESSFULLY_SENT_USER_INFO_FORM>;
export type FailedSentUserInfoForm = BaseAction<OnboardingV4ActionType.FAILED_SENT_USER_INFO_FORM>;

export const disconnectButtonClicked = actionCreator<DisconnectButtonClicked>(
  OnboardingV4ActionType.DISCONNECT_BUTTON_CLICKED
);

export const acceptTosButtonClicked = actionCreator<AcceptTosButtonClicked>(
  OnboardingV4ActionType.ACCEPT_TOS_BUTTON_CLICKED
);

export const manualImportContinueButtonClicked = actionCreator<ManualImportContinueButtonClicked>(
  OnboardingV4ActionType.MANUAL_IMPORT_CONTINUE_BUTTON_CLICKED
);

export const showManualImportPageButtonClicked = actionCreator<
  BaseAction<OnboardingV4ActionType.SHOW_MANUAL_IMPORT_PAGE_BUTTON_CLICKED>
>(OnboardingV4ActionType.SHOW_MANUAL_IMPORT_PAGE_BUTTON_CLICKED);

export const emailNotFoundButtonClicked = actionCreator<
  BaseAction<OnboardingV4ActionType.EMAIL_NOT_FOUND_BUTTON_CLICKED>
>(OnboardingV4ActionType.EMAIL_NOT_FOUND_BUTTON_CLICKED);

export const verifyEmailResent = actionCreator<
  BaseAction<OnboardingV4ActionType.VERIFY_EMAIL_RESENT>
>(OnboardingV4ActionType.VERIFY_EMAIL_RESENT);

export const userAcceptMNDA = actionCreator<UserAcceptMNDA>(
  OnboardingV4ActionType.USER_ACCEPT_MNDA
);
export const acceptMndaButtonClicked = actionCreator<
  BaseAction<OnboardingV4ActionType.ACCEPT_MNDA_BUTTON_CLICKED>
>(OnboardingV4ActionType.ACCEPT_MNDA_BUTTON_CLICKED);

export const apiSuccessfullyWhenUserAcceptMNDA = actionCreator<SuccessfullyUserAcceptMNDA>(
  OnboardingV4ActionType.SUCCESSFULLY_USER_ACCEPT_MNDA
);
export const apiFailedWhenUserAcceptMNDA = actionCreator<FailedUserAcceptMNDA>(
  OnboardingV4ActionType.FAILED_USER_ACCEPT_MNDA
);

export const userFillFundraisingForm = actionCreator<UserFillFundraisingForm>(
  OnboardingV4ActionType.USER_FILL_FUNDRAISING_FORM
);
export const apiSuccessfullyWhenUserSentFundraisingForm = actionCreator<SuccessfullySentFundraisingForm>(
  OnboardingV4ActionType.SUCCESSFULLY_SENT_FUNDRAISING_FORM
);
export const apiFailedWhenUserSentFundraisingForm = actionCreator<FailedSentFundraisingForm>(
  OnboardingV4ActionType.FAILED_SENT_FUNDRAISING_FORM
);
export const userFillBasicInfoForm = actionCreator<UserFillInfoForm>(
  OnboardingV4ActionType.USER_FILLED_INFO_FORM
);
export const apiSuccessfullyWhenUserSentUserInfoForm = actionCreator<SuccessfullySentUserInfoForm>(
  OnboardingV4ActionType.SUCCESSFULLY_SENT_USER_INFO_FORM
);
export const apiFailedWhenUserSentUserInfoForm = actionCreator<FailedSentUserInfoForm>(
  OnboardingV4ActionType.FAILED_SENT_USER_INFO_FORM
);
export type OnboardingV4Action =
  | DisconnectButtonClicked
  | ManualImportContinueButtonClicked
  | AcceptTosButtonClicked
  | UserAcceptMNDA
  | SuccessfullyUserAcceptMNDA
  | FailedUserAcceptMNDA
  | UserFillInfoForm
  | SuccessfullySentUserInfoForm
  | FailedSentUserInfoForm
  | UserFillFundraisingForm
  | SuccessfullySentFundraisingForm
  | FailedSentFundraisingForm
  | ReturnType<typeof emailNotFoundButtonClicked>
  | ReturnType<typeof verifyEmailResent>
  | ReturnType<typeof showManualImportPageButtonClicked>
  | ReturnType<typeof acceptMndaButtonClicked>;
