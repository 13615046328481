import React from 'react';
import { useHistory } from 'react-router';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { Routes } from '@hum/icm-app/src/state';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import * as styles from '@hum/icm-app/src/pages/portfolio-monitoring/styles.pc';

export const PortfolioMonitoringPreviewInvitation = () => {
  const { state } = useAppStore();
  const currentUser = state.session.data;
  const investorName = currentUser?.firstName;
  const investorFund = currentUser?.company?.name;
  const history = useHistory();
  return (
    <>
      <Topbar />
      <styles.PreviewInvite
        title="Preview Invitation"
        investorName={investorName}
        investorFund={investorFund}
        testId="preview-invite-back-button"
        clickGoBack={() => {
          history.push(Routes.PORTFOLIO_MONITORING_MANAGE_COMPANIES);
        }}
      ></styles.PreviewInvite>
    </>
  );
};
