import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';
import { OtherSoftwareOptions } from '@hum/types';

export enum OtherSoftwareActionType {
  OTHER_SOFTWARE_OPTION_SAVED = 'OTHER_SOFTWARE_OPTION_SAVED',
}

export type OtherSoftwareOptionSaved = BaseAction<
  OtherSoftwareActionType.OTHER_SOFTWARE_OPTION_SAVED,
  { companyId: number; software: OtherSoftwareOptions }
>;
export const otherSoftwareOptionSaved = actionCreator<OtherSoftwareOptionSaved>(
  OtherSoftwareActionType.OTHER_SOFTWARE_OPTION_SAVED
);

export type OtherSoftwareActions = OtherSoftwareOptionSaved;
