import React, { useCallback, useMemo } from 'react';
import { Icon } from '@hum/ui-library';
import { ConnectorModalProps, ModalType } from '../modals/ConnectorModal';
import { AuthenticationFormProps } from '../modals';

import { Connector, ConnectorOption, ConnectionType } from '@hum/types';

import {
  useAuthState,
  useCodatConnector,
  useOAuthConnector,
  useDirectConnector,
} from '.';

export type IUseAuthFlow<A = AuthenticationFormProps> = {
  isConnecting: boolean;
  isConnectionError: boolean;
  setIsConnecting: (isConnecting: boolean) => void;
  onConnect: () => Promise<void>;
  connection:
    | (Connector & {
        connectorConfigId?: number;
      })
    | null;
  authenticationProps: A;
  oauthConfigId?: number;
  connectionId?: number;
  modalProps: ConnectorModalProps;
};

export const useAuthFlow = (
  connector: ConnectorOption | null,
  selectConnector: (connector: ConnectorOption | null) => void
): IUseAuthFlow => {
  const {
    parameters,
    setParameters,
    oauthConfigId,
    connectionId,
    isConnecting,
    setIsConnecting,
    setOauthConfigId,
    clearOauthState,
  } = useAuthState();

  const formFilled = useMemo(
    () =>
      connector !== null &&
      Object.values(parameters).length > 0 &&
      Object.values(parameters).filter(Boolean).length ===
        connector.config.parameters.length,
    [connector, parameters]
  );

  const codat = useCodatConnector({ connector });
  const oauth = useOAuthConnector({ connector });
  const direct = useDirectConnector({ connector });

  const connectionResult: Record<
    ConnectionType | '',
    { data: Connector | null; isError: boolean }
  > = {
    '': {
      isError: false,
      data: null,
    },
    [ConnectionType.Codat]: {
      data: codat.connection,
      isError: codat.isConnectionError,
    },
    [ConnectionType.OAuth]: {
      data: oauth.connection,
      isError: oauth.isConnectionError,
    },
    [ConnectionType.Direct]: {
      data: direct.connection,
      isError: direct.isConnectionError,
    },
  };
  const isConnectionError =
    connectionResult[connector?.config.connectionType ?? ''].isError;
  const connection =
    connectionResult[connector?.config.connectionType ?? ''].data;

  const onConnect = useCallback(async () => {
    if (!connector?.config) {
      return;
    }

    setIsConnecting(true);
    setOauthConfigId(connector.config.id);

    if (connector?.config.connectionType === ConnectionType.Codat) {
      return codat.onConnect();
    } else if (connector?.config.connectionType === ConnectionType.OAuth) {
      return oauth.onConnect(formFilled, parameters);
    } else if (connector?.config.connectionType === ConnectionType.Direct) {
      return direct.onConnect(formFilled, parameters);
    }
  }, [connector, formFilled]);

  const onClose = useCallback(() => {
    selectConnector(null);
    setIsConnecting(false);
    setParameters({});
    clearOauthState();
  }, [connector, isConnecting, parameters]);

  const modalProps: ConnectorModalProps = useMemo(() => {
    const props = isConnectionError
      ? {
          type: ModalType.ERROR,
          isOpen: true,
          title: `There was a problem connecting your ${
            connector?.config.label ?? ''
          } account`,
          onTryAgain: onConnect,
          onClose,
          icon: <Icon.HumConnectError />,
          testId: 'onboarding-connect:connector-error-modal',
        }
      : {
          type: ModalType.CONNECTING,
          isOpen: false,
          title: '',
          onTryAgain: onConnect,
          onClose,
        };

    if (connector && isConnecting && !isConnectionError) {
      if (connector.config.connectionType === ConnectionType.Codat) {
        Object.assign(props, codat.modalProps);
      } else if (connector.config.connectionType === ConnectionType.OAuth) {
        Object.assign(props, oauth.modalProps);
      } else if (connector.config.connectionType === ConnectionType.Direct) {
        Object.assign(props, direct.modalProps);
      }
    }

    return props;
  }, [connector, isConnecting, isConnectionError]);

  return {
    isConnecting,
    isConnectionError,
    oauthConfigId,
    connectionId,
    setIsConnecting,
    onConnect,
    connection,
    authenticationProps: {
      connector,
      parameters,
      setParameters,
      formFilled,
      onConnect,
    },
    modalProps,
  };
};
