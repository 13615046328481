import React from "react";
import _pub778Aed3, {Wrapper as _pub778Aed3_Wrapper,CobrandingLayout as _pub778Aed3_CobrandingLayout,NavSidebar as _pub778Aed3_NavSidebar,CobrandingRight as _pub778Aed3_CobrandingRight} from "../../../../components/Split/styles.pc";
import _pub8Ac8C6B9, {Preview as _pub8Ac8C6B9_Preview} from "../../../../components/Topbar/index.pc";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import _pub2Dbb4493 from "../../../../../../design-system/src/Button.pc";
import _pubF3E3Feb0 from "../../../../../../design-system/src/Spinner.pc";
import "./editor.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var CobrandingInstruction = React.memo(React.forwardRef(function CobrandingInstruction(props, ref) {
  return React.createElement("li", {
    "className": "_55e376e7 _b94e358a _pub-b94e358a _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_9874aceb _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_title _pub-b94e358a_title _pub-a97d53f3_title title",
    }, 
      React.createElement("span", {
        "className": "_25c0252c _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_icon _pub-b94e358a_icon _pub-a97d53f3_icon icon " + (props["icon"] ? " " + props["icon"] : ""),
      }, null),
      props["label"]
    ),
    React.createElement("p", {
      "className": "_17dfd51 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_subtext _pub-b94e358a_subtext _pub-a97d53f3_subtext subtext",
    }, 
      props["subtext"]
    )
  )
}));
export { CobrandingInstruction };

var Instructions = React.memo(React.forwardRef(function Instructions(props, ref) {
  return React.createElement("div", {
    "className": "_1b22f0c5 _b94e358a _pub-b94e358a _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement("h4", {
      "className": "_3b1d821f _b94e358a _pub-b94e358a _pub-a97d53f3",
    }, 
      "Prepare your logo files"
      
    ),
    React.createElement("ul", {
      "className": "_a214d3a5 _b94e358a _pub-b94e358a _pub-a97d53f3",
    }, 
      React.createElement(CobrandingInstruction, {
        "class": "_8b80116a",
        "icon": "maximize-2",
        "label": "The bigger the better",
        "subtext": "To assure quality we recommend uploading an image larger than 320px width * 40px height",
      }, null),
      React.createElement(CobrandingInstruction, {
        "class": "_fc8721fc",
        "icon": "image",
        "label": "Image must be .png or .svg",
      }, null),
      React.createElement(CobrandingInstruction, {
        "class": "_658e7046",
        "icon": "maximize",
        "label": "Remove borders",
      }, null),
      React.createElement(CobrandingInstruction, {
        "class": "_128940d0",
        "icon": "slash",
        "label": "Transparent background image",
      }, null),
      React.createElement(CobrandingInstruction, {
        "class": "_8cedd573",
        "icon": "upload",
        "label": "Keep file size under 500kb",
      }, null)
    ),
    props["children"]
  )
}));
export { Instructions };

var LogoPresetButton = React.memo(React.forwardRef(function LogoPresetButton(props, ref) {
  return React.createElement("button", {
    "className": "_f52c91e9 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_picker _pub-b94e358a_picker _pub-a97d53f3_picker picker" + (props["rainbow"] ? " " + "_b94e358a_rainbow _pub-b94e358a_rainbow _pub-a97d53f3_rainbow rainbow" : "") + (props["white"] ? " " + "_b94e358a_white _pub-b94e358a_white _pub-a97d53f3_white white" : "") + (props["black"] ? " " + "_b94e358a_black _pub-b94e358a_black _pub-a97d53f3_black black" : "") + (props["selected"] ? " " + "_b94e358a_selected _pub-b94e358a_selected _pub-a97d53f3_selected selected" : ""),
    "ref": ref,
    "onClick": props["onClick"],
    "data-testid": "logo-preset-button",
  }, null)
}));
export { LogoPresetButton };

var PrimaryColorButton = React.memo(React.forwardRef(function PrimaryColorButton(props, ref) {
  return React.createElement("button", {
    "className": "_1c4f34dc _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_picker _pub-b94e358a_picker _pub-a97d53f3_picker picker" + (props["selected"] ? " " + "_b94e358a_selected _pub-b94e358a_selected _pub-a97d53f3_selected selected" : ""),
    "ref": ref,
    "onClick": props["onClick"],
    "data-testid": "primary-color-button",
    "style": castStyle(props["style"]),
  }, null)
}));
export { PrimaryColorButton };

var LoadingSplash = React.memo(React.forwardRef(function LoadingSplash(props, ref) {
  return React.createElement("div", {
    "className": "_f24155f0 _b94e358a _pub-b94e358a _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement(_pubF3E3Feb0, {
      "class": "_3f90fead _b94e358a _pub-b94e358a _pub-a97d53f3",
    }, null)
  )
}));

var Controls = React.memo(React.forwardRef(function Controls(props, ref) {
  return React.createElement("div", {
    "className": "_15f978f7 _b94e358a _pub-b94e358a _pub-a97d53f3" + (props["showInstructions"] ? " " + "_b94e358a_show-instructions _pub-b94e358a_show-instructions _pub-a97d53f3_show-instructions show-instructions" : "") + (props["uploadingImage"] ? " " + "_b94e358a_uploading-image _pub-b94e358a_uploading-image _pub-a97d53f3_uploading-image uploading-image" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_350ed3a7 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_preview _pub-b94e358a_preview _pub-a97d53f3_preview preview" + (props["hasPreview"] ? " " + "_b94e358a_preview--has-preview _pub-b94e358a_preview--has-preview _pub-a97d53f3_preview--has-preview preview--has-preview" : ""),
    }, 
      React.createElement("div", {
        "className": "_b9b6e4f2 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_header _pub-b94e358a_header _pub-a97d53f3_header header",
      }, 
        "\n      Set default logo\n      "
        ,
        React.createElement("button", {
          "className": "_b1c054c2 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_info _pub-b94e358a_info _pub-a97d53f3_info info",
          "onClick": props["onInfoClick"],
        }, null)
      ),
      React.createElement("div", {
        "className": "_ceb1d464 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_container _pub-b94e358a_container _pub-a97d53f3_container container",
        "data-testid": "logo-preview-container",
      }, 
        React.createElement(LoadingSplash, {
          "class": "_c7050e63" + " " + "_b94e358a_loading-splash _pub-b94e358a_loading-splash _pub-a97d53f3_loading-splash loading-splash",
        }, null),
        React.createElement(Instructions, {
          "class": "_b0023ef5" + " " + "_b94e358a_instructions _pub-b94e358a_instructions _pub-a97d53f3_instructions instructions",
        }, null),
        props["preview"],
        React.createElement("input", {
          "className": "_5e0c5fd9 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_upload-input _pub-b94e358a_upload-input _pub-a97d53f3_upload-input upload-input",
          "type": "file",
          "data-testid": "file-upload-input",
          "onChange": props["onLogoFileChange"],
        }, null)
      ),
      React.createElement(_pub2Dbb4493, {
        "class": "_57b885de _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_remove-button _pub-b94e358a_remove-button _pub-a97d53f3_remove-button remove-button",
        "primary": true,
        "v3": true,
        "resetCase": true,
        "xsmall": true,
        "onClick": props["onRemoveButtonClick"],
      }, 
        "\n      Remove\n    "
        
      )
    ),
    React.createElement("div", {
      "className": "_ac07821d _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_sections _pub-b94e358a_sections _pub-a97d53f3_sections sections _b94e358a_buttons _pub-b94e358a_buttons _pub-a97d53f3_buttons buttons",
    }, 
      React.createElement("div", {
        "className": "_bbf05aab _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_presets _pub-b94e358a_presets _pub-a97d53f3_presets presets _b94e358a_section _pub-b94e358a_section _pub-a97d53f3_section section",
      }, 
        React.createElement("label", {
          "className": "_81671e84 _b94e358a _pub-b94e358a _pub-a97d53f3",
        }, 
          "Logo Presets"
          
        ),
        React.createElement("div", {
          "className": "_f6602e12 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_inputs _pub-b94e358a_inputs _pub-a97d53f3_inputs inputs",
        }, 
          props["logoPresets"]
        )
      ),
      React.createElement("div", {
        "className": "_ccf76a3d _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_theme _pub-b94e358a_theme _pub-a97d53f3_theme theme _b94e358a_section _pub-b94e358a_section _pub-a97d53f3_section section",
      }, 
        React.createElement("label", {
          "className": "_80a574b3 _b94e358a _pub-b94e358a _pub-a97d53f3",
        }, 
          "Theme"
          
        ),
        React.createElement("div", {
          "className": "_f7a24425 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_inputs _pub-b94e358a_inputs _pub-a97d53f3_inputs inputs",
        }, 
          React.createElement("button", {
            "className": "_de68da70 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_light _pub-b94e358a_light _pub-a97d53f3_light light",
          }, 
            React.createElement("i", {
              "className": "_e13e4e8 _b94e358a _pub-b94e358a _pub-a97d53f3",
            }, null)
          ),
          React.createElement("button", {
            "className": "_a96feae6 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_dark _pub-b94e358a_dark _pub-a97d53f3_dark dark _b94e358a_selected _pub-b94e358a_selected _pub-a97d53f3_selected selected",
          }, 
            React.createElement("i", {
              "className": "_fd18edf _b94e358a _pub-b94e358a _pub-a97d53f3",
            }, null)
          )
        )
      ),
      React.createElement("div", {
        "className": "_55fe3b87 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_primary-color _pub-b94e358a_primary-color _pub-a97d53f3_primary-color primary-color _b94e358a_section _pub-b94e358a_section _pub-a97d53f3_section section",
      }, 
        React.createElement("label", {
          "className": "_82e3caea _b94e358a _pub-b94e358a _pub-a97d53f3",
        }, 
          "Primary Color"
          
        ),
        React.createElement("div", {
          "className": "_f5e4fa7c _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_inputs _pub-b94e358a_inputs _pub-a97d53f3_inputs inputs",
        }, 
          props["primaryColors"]
        )
      )
    )
  )
}));
export { Controls };

var ThemeHeaderLinkPreview = React.memo(React.forwardRef(function ThemeHeaderLinkPreview(props, ref) {
  return React.createElement("div", {
    "className": "_300fa306 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_theme_header_link_preview _pub-b94e358a_theme_header_link_preview _pub-a97d53f3_theme_header_link_preview theme_header_link_preview" + (props["selected"] ? " " + "_b94e358a_selected _pub-b94e358a_selected _pub-a97d53f3_selected selected" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_29a82df1 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_bar _pub-b94e358a_bar _pub-a97d53f3_bar bar",
    }, null)
  )
}));
export { ThemeHeaderLinkPreview };

var ThemePreview = React.memo(React.forwardRef(function ThemePreview(props, ref) {
  return React.createElement("div", {
    "className": "_de01c22a _b94e358a _pub-b94e358a _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "style": castStyle(props["style"]),
  }, 
    React.createElement("div", {
      "className": "_2a2cf99f _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_inner _pub-b94e358a_inner _pub-a97d53f3_inner inner",
    }, 
      React.createElement("div", {
        "className": "_295be6b _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_header _pub-b94e358a_header _pub-a97d53f3_header header",
      }, 
        React.createElement("div", {
          "className": "_3f437e2 _b94e358a _pub-b94e358a _pub-a97d53f3",
        }, 
          props["logo"],
          React.createElement("div", {
            "className": "_ad61e93 _b94e358a _pub-b94e358a _pub-a97d53f3",
          }, null),
          React.createElement("div", {
            "className": "_94b28b30 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_name _pub-b94e358a_name _pub-a97d53f3_name name",
          }, 
            props["companyName"]
          )
        ),
        React.createElement("div", {
          "className": "_74f30774 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_links _pub-b94e358a_links _pub-a97d53f3_links links",
        }, 
          React.createElement(ThemeHeaderLinkPreview, {
            "class": "_e51a1588",
            "selected": true,
          }, null),
          React.createElement(ThemeHeaderLinkPreview, {
            "class": "_7c134432",
          }, null)
        )
      )
    )
  )
}));
export { ThemePreview };

var UploadCTA = React.memo(React.forwardRef(function UploadCTA(props, ref) {
  return React.createElement("div", {
    "className": "_3762671f _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_upload-cta _pub-b94e358a_upload-cta _pub-a97d53f3_upload-cta upload-cta",
    "ref": ref,
  }, 
    "\n  Drag your photo here or "
    ,
    React.createElement("span", {
      "className": "_b7a8d497 _b94e358a _pub-b94e358a _pub-a97d53f3" + " " + "_b94e358a_highlight _pub-b94e358a_highlight _pub-a97d53f3_highlight highlight",
    }, 
      "Browse"
      
    )
  )
}));
export { UploadCTA };

var Preview = React.memo(React.forwardRef(function Preview(props, ref) {
  return React.createElement(_pub778Aed3_Wrapper, {
    "class": "_d96c0633 _b94e358a _pub-b94e358a _pub-a97d53f3" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["humDark"] ? " " + "_b94e358a_hum _pub-b94e358a_hum _pub-a97d53f3_hum hum" + " " + " " + " " + "_b94e358a_dark _pub-b94e358a_dark _pub-a97d53f3_dark dark" : ""),
    "ref": ref,
  }, 
    React.createElement(_pub8Ac8C6B9_Preview, {
      "class": "_5a2261d5 _b94e358a _pub-b94e358a _pub-a97d53f3",
      "loggedIn": true,
    }, null),
    React.createElement(_pub778Aed3_CobrandingLayout, {
      "class": "_2d255143 _b94e358a _pub-b94e358a _pub-a97d53f3",
    }, 
      React.createElement(_pub778Aed3_NavSidebar, {
        "class": "_8012283d _b94e358a _pub-b94e358a _pub-a97d53f3",
        "title": "Branding",
      }, 
        React.createElement(Controls, {
          "class": "_187718ea",
          "hasPreview": true,
          "showInstructions": props["showInstructions"],
          "uploadingImage": props["uploadingImage"],
          "preview": React.createElement(UploadCTA, {
            "class": "_2d15f2e5",
          }, null),
          "logoPresets": React.createElement(React.Fragment, {
          }, 
            React.createElement(LogoPresetButton, {
              "class": "_32dfd7b5",
              "rainbow": true,
            }, null),
            React.createElement(LogoPresetButton, {
              "class": "_45d8e723",
              "white": true,
            }, null),
            React.createElement(LogoPresetButton, {
              "class": "_dcd1b699",
              "black": true,
            }, null)
          ),
          "primaryColors": React.createElement(React.Fragment, {
          }, 
            React.createElement(PrimaryColorButton, {
              "class": "_331dbd82",
              "style": castStyle("background: #B7972F"),
            }, null),
            React.createElement(PrimaryColorButton, {
              "class": "_441a8d14",
              "style": castStyle("background: #2FB653"),
            }, null),
            React.createElement(PrimaryColorButton, {
              "class": "_dd13dcae",
              "selected": true,
              "style": castStyle("background: #3093B7"),
            }, null),
            React.createElement(PrimaryColorButton, {
              "class": "_aa14ec38",
              "style": castStyle("background: #2E4FB8"),
            }, null),
            React.createElement(PrimaryColorButton, {
              "class": "_3470799b",
              "style": castStyle("background: #B7532F"),
            }, null)
          ),
        }, null)
      ),
      React.createElement(_pub778Aed3_CobrandingRight, {
        "class": "_f71518ab _b94e358a _pub-b94e358a _pub-a97d53f3",
        "darker": true,
      }, 
        React.createElement(ThemePreview, {
          "class": "_19b572dd" + " " + "_b94e358a_theme-preview _pub-b94e358a_theme-preview _pub-a97d53f3_theme-preview theme-preview",
          "logo": props["humDark"]&&React.createElement("img", {
            "className": "_ca0c43a _b94e358a _pub-b94e358a _pub-a97d53f3",
            "src": getDefault(require("@hum/assets/hum-logo-circle.svg")),
          }, null)||React.createElement("img", {
            "className": "_ca0c43a _b94e358a _pub-b94e358a _pub-a97d53f3",
            "src": getDefault(require("@hum/assets/captec.svg")),
          }, null),
        }, null)
      )
    )
  )
}));
export { Preview };


