import { produce } from 'immer';
import { AppState } from '@hum/icm-app/src/state';
import { OtherSoftwareActions, OtherSoftwareActionType } from './actions';

export const otherSoftwareReducers = (
  state: AppState,
  action: OtherSoftwareActions
) => {
  switch (action.type) {
    case OtherSoftwareActionType.OTHER_SOFTWARE_OPTION_SAVED: {
      return produce(state, (newState) => {
        newState.currentCompany.data!.application.requestedConnectors = [
          { name: action.payload.software },
        ];
      });
    }
    default:
      return state;
  }
};
