import * as XLSX from 'xlsx';

export const isValidDate = (str: string): boolean => {
  /* Naive date validation
   * The purpose of this function is to return whether or not a given string can
   * be parsed as a Date object. We are *not* concerned with whether the correct
   * date is parsed or not!
   *
   * The idea is that if we can find 12+ consecutive columns that are date-like,
   * we can be reasonably certain that we have monthly data.
   */
  try {
    const date = Date.parse(str);
    if (Number.isNaN(date)) {
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
};

export const validateSpreadsheet = async (file: File): Promise<boolean> => {
  /* Validate a spreadsheet by making sure it meets our minimum requirements
   * Namely:
   * - monthly, not annual data
   * - at least 12 months worth of data
   */
  const arrayBuffer = await file.arrayBuffer();
  const workbook = XLSX.read(arrayBuffer);
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  const rawData: Record<
    string,
    string[]
  >[] = XLSX.utils.sheet_to_json(worksheet, { header: 'A' });

  let numberOfDateColumns = 0;
  // We use a label so that we can break out of both the inner and outer loop
  // at the same time.
  outer: for (let row of rawData) {
    // reset the count for this row
    numberOfDateColumns = 0;
    for (let value of Object.values(row)) {
      if (isValidDate(value.toString())) {
        numberOfDateColumns++;

        if (numberOfDateColumns >= 12) {
          break outer;
        }
      }
    }
  }
  // we have at least 12 months of data
  return numberOfDateColumns >= 12;
};

export function base64ToBytes(base64: string): Uint8Array {
  const binString: string = atob(base64);
  // @ts-ignore
  return Uint8Array.from(binString, (m) => m.codePointAt(0));
}
