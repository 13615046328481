import React from "react";
import _pubFa41B678 from "../../../../../../design-system/src/Icon.pc";
import _pub2F51C399 from "../../../../../../design-system/src/breakpoints.pc";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Content = React.memo(React.forwardRef(function Content(props, ref) {
  return React.createElement("section", {
    "className": "_d17c033 _5969b16b _pub-5969b16b _pub-2f51c399 _pub-a97d53f3" + (props["sideLayout"] ? " " + "_5969b16b_side-layout _pub-5969b16b_side-layout _pub-2f51c399_side-layout _pub-a97d53f3_side-layout side-layout" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Content };

var HeaderWrapper = React.memo(React.forwardRef(function HeaderWrapper(props, ref) {
  return React.createElement("header", {
    "className": "_e319a11f _5969b16b _pub-5969b16b _pub-2f51c399 _pub-a97d53f3" + (props["sideLayout"] ? " " + "_5969b16b_side-layout _pub-5969b16b_side-layout _pub-2f51c399_side-layout _pub-a97d53f3_side-layout side-layout" : ""),
    "ref": ref,
    "data-pc-label": "header",
  }, 
    props["children"]
  )
}));
export { HeaderWrapper };

var HeaderLeft = React.memo(React.forwardRef(function HeaderLeft(props, ref) {
  return React.createElement("div", {
    "className": "_4a18c18 _5969b16b _pub-5969b16b _pub-2f51c399 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("img", {
      "className": "_56381bd9 _5969b16b _pub-5969b16b _pub-2f51c399 _pub-a97d53f3",
      "src": getDefault(require("@hum/figma/components/logo/capital@1.svg")),
    }, null),
    React.createElement("span", {
      "className": "_cf314a63 _5969b16b _pub-5969b16b _pub-2f51c399 _pub-a97d53f3",
    }, 
      props["children"]
    )
  )
}));
export { HeaderLeft };

var HeaderRight = React.memo(React.forwardRef(function HeaderRight(props, ref) {
  return React.createElement("div", {
    "className": "_36715db2 _5969b16b _pub-5969b16b _pub-2f51c399 _pub-a97d53f3",
    "ref": ref,
  }, 
    props["showAuthSection"]&&React.createElement("div", {
      "className": "_c19d7344 _5969b16b _pub-5969b16b _pub-2f51c399 _pub-a97d53f3",
    }, 
      React.createElement("i", {
        "className": "_6d342330 _5969b16b _pub-5969b16b _pub-2f51c399 _pub-a97d53f3",
      }, null),
      React.createElement("div", {
        "className": "_f43d728a _5969b16b _pub-5969b16b _pub-2f51c399 _pub-a97d53f3",
      }, 
        "256-Bit Bank-Level Encryption"
        
      ),
      React.createElement("div", {
        "className": "_833a421c _5969b16b _pub-5969b16b _pub-2f51c399 _pub-a97d53f3",
      }, 
        props["children"]
      )
    )
  )
}));
export { HeaderRight };

var Footer = React.memo(React.forwardRef(function Footer(props, ref) {
  return React.createElement("footer", {
    "className": "_d87f3c9e _5969b16b _pub-5969b16b _pub-2f51c399 _pub-a97d53f3" + (props["sideLayout"] ? " " + "_5969b16b_side-layout _pub-5969b16b_side-layout _pub-2f51c399_side-layout _pub-a97d53f3_side-layout side-layout" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { Footer };


