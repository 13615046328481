import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { NoticeName } from '@hum/icm-app/src/state';
import { Routes } from '@hum/icm-app/src/state/routes';
import { useCompanyNotice } from '@hum/icm-app/src/hooks/useCompanyNotice';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { useAnalyticsEvent } from '@hum/api';

export const useFinalPage = () => {
  const trackEvent = useAnalyticsEvent();
  const history = useHistory();
  const company = useCurrentCompany();
  const [
    chooseYourAdventureCompleted,
    ,
    completeChooseYourAdventure,
  ] = useCompanyNotice(
    NoticeName.ChooseYourAdventureOnboardingCompleted,
    company.data?.id
  );

  useEffect(() => {
    trackEvent('company-visit-final-page');
  }, []);

  const goToDashboard = () => {
    if (!chooseYourAdventureCompleted) {
      completeChooseYourAdventure();
    }
    history.push(Routes.COMPANY_ACTIVATION_GET_STARTED);
  };

  return { goToDashboard };
};
