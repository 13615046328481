import React from 'react';

import { Modal } from '@hum/design-system';
import { Button, ButtonType } from '@hum/ui-library';

const HELP_CENTER_NEW_ISSUE_URL =
  'https://info.humcapital.com/hum-help-center/kb-tickets/new';

export const ErrorModal = ({
  visible = false,
  onCancel,
  serviceName,
  serviceLogo,
  onTryAgain,
}: {
  visible: boolean;
  onCancel: () => void;
  serviceName: string;
  serviceLogo?: React.ReactNode;
  onTryAgain: () => void;
}) => {
  if (!serviceName) {
    return null;
  }

  return (
    <Modal
      v5
      isError
      visible={visible}
      header={`There was a problem connecting your ${serviceName} account`}
      onClose={onCancel}
      serviceLogo={serviceLogo}
      footer={
        <>
          <Button type={ButtonType.QUIET} onClick={onCancel}>
            Cancel
          </Button>
          <Button type={ButtonType.QUIET}>
            <a href={HELP_CENTER_NEW_ISSUE_URL} className="ui-link">
              Report a problem
            </a>
          </Button>
          <Button type={ButtonType.DESTRUCTIVE} onClick={onTryAgain}>
            Try again
          </Button>
        </>
      }
    />
  );
};
