import React, { useEffect } from 'react';
import { Redirect } from 'react-router';

import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { useEntityType } from '@hum/icm-app/src/hooks/useEntityType';
import {
  Detail,
  PageTitle,
  MetaTitle,
  Pill,
  Card,
  SelectCard,
  Field,
  SelectInput,
} from '@hum/legacy-ui';
import { Button } from '@hum/ui-library';
import { DocusignMNDAStatus, Routes } from '@hum/icm-app/src/state';
import { useIsWhitelabeled } from '@hum/icm-app/src/hooks/useIsWhitelabeled';
import { useIsSvbSubDomain } from '@hum/icm-app/src/hooks/useIsSvbSubdomain';
import { useEntitySettings } from '@hum/icm-app/src/hooks/useEntitySettings';
import { useMNDA } from '@hum/icm-app/src/hooks/useMNDA';
import * as styles from './styles.pc';
import { useAPI } from '../../hooks/useAPI';

/*
 * Documents
 *
 * Company should be able to see: NDA, require investor checkbox, investors that don't want to meet
 * Company within cobranding should only see NDA
 * Portfolio Investor should only see: NDA
 * Syndication Investor should only see: NDA
 * BSP should only see: NDA
 * Admin should not see anything here
 *
 */

export const Documents: React.FC = () => {
  const company = useCurrentCompany();
  const isSbvSubdomain = useIsSvbSubDomain();

  if (!company.data?.id) {
    return null;
  }
  if (isSbvSubdomain) {
    return <Redirect to={Routes.SETTINGS} />;
  }

  return (
    <DocumentsContent
      company={company}
      isSbvSubdomain={isSbvSubdomain}
      defaultInitialValues={{
        requireMutualNda: company.data?.application.requireMutualNda,
        unwantedInvestors: company.data?.application.unwantedInvestors || [],
      }}
    />
  );
};

const DocumentsContent = ({
  company,
  isSbvSubdomain,
  defaultInitialValues,
}) => {
  const api = useAPI();
  const { isCompany } = useEntityType();
  const { envelopeStatus, downloadMNDA } = useMNDA();
  const isBranded = useIsWhitelabeled();
  const { form, fields } = useEntitySettings({
    setShowFinancialDetails: () => {},
    setShowInsightRequests: () => {},
    showFinancialDetails: false,
    showInsightRequests: false,
    isSbvSubdomain,
    defaultInitialValues,
  });

  const hasAcceptedNda =
    company.data?.acceptedNDA ||
    envelopeStatus !== DocusignMNDAStatus.WaitingForSignature;

  useEffect(() => {
    // TODO: fix me, this needs to go in sagas
    if (company.data?.id) {
      const unwantedInvestors = form.values.unwantedInvestors || [];
      api.onboarding.saveUnwantedInvestors(company.data?.id, {
        unwantedInvestors,
      });
    }
  }, [form.values.unwantedInvestors]);

  useEffect(() => {
    // TODO: fix me, this needs to go in sagas
    if (company.data?.id) {
      api.onboarding.saveRequireNDA(company.data?.id, {
        requireMutualNda: form.values.requireMutualNda,
      });
    }
  }, [form.values.requireMutualNda]);

  return (
    <>
      <PageTitle title="Privacy Preference" noCenter />
      <MetaTitle>Privacy Preference</MetaTitle>
      <div style={{ maxWidth: 490 }}>
        <Card spaced>
          <styles.CardWithNda>
            <div>
              <h2>Non-Disclosure Agreement with Hum Capital</h2>
              {hasAcceptedNda && (
                <>
                  <Pill>Signed</Pill>
                  <br />
                  <Button onClick={downloadMNDA} className="mt-8">
                    Download
                  </Button>
                </>
              )}
            </div>
            <styles.Nda />
          </styles.CardWithNda>
        </Card>
        {!isBranded && isCompany && (
          <>
            <SelectCard
              form={form}
              checkbox={fields.requireMutualNda}
              checkboxId="requireMutualNda"
              checkboxIsToggle
              title={<h2>Investor Mutual Non-Disclosure Agreement</h2>}
              description={
                <p>
                  Require a mutual non-disclosure agreement with investors
                  before permitting investors to view your information on the
                  Intelligent Capital Market
                </p>
              }
            />
            <Detail header title="Investor preferences" />
            <p>Are there any debt investors that you do NOT want to meet?</p>
            <styles.FieldWrapper>
              <Field
                v3
                label="Please list the investors you don't want to see"
                {...fields.unwantedInvestors}
              >
                {(props) => (
                  <SelectInput
                    {...props}
                    aria-label="Unwanted investors"
                    placeholder="Unwanted investors"
                    options={[]}
                    isMulti
                    isCreatable
                    noOptionsMessage={() => 'Enter the name of the investors'}
                    formatCreateLabel={(value) => `Add investor: ${value}`}
                  />
                )}
              </Field>
            </styles.FieldWrapper>
          </>
        )}
      </div>
    </>
  );
};
