import React, { useState, useEffect } from 'react';
import * as styles from './editor.pc';
import * as containerStyles from '@hum/icm-app/src/components/Split/styles.pc';
import { Preview, getFilteredImageBlob } from './preview';
import { brandingEditorChanged } from '@hum/icm-app/src/actions';
import { Spinner } from '@hum/ui-library';
import { CompanyFileDocumentType } from '@hum/types';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { Branding, LogoPreset } from '@hum/icm-app/src/state';
import { useFileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';

const PRIMARY_COLORS = ['#B7972F', '#2FB653', '#3093B7', '#2E4FB8', '#B7532F'];

export const Editor = () => {
  const {
    state: { currentCompany: company, branding },
    dispatch,
  } = useAppStore();

  const { createFromLocalFile, removeFile } = useFileManager({
    companyId: company?.data?.id as number,
  });

  const brandingData: Branding = branding.data || {};
  const img = useImage(brandingData.logoFileUrl);

  const [showInstructions, setShowInstructions] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);

  if (!branding.data || !company.loaded || !company.data) {
    return <Spinner fullScreen />;
  }

  const {
    preset = LogoPreset.COLOR,
    primaryColor = PRIMARY_COLORS[0],
  } = brandingData;
  const { name: companyName } = company.data!;

  const onBrandingChanged = async (changes: Partial<Branding>) => {
    dispatch(
      brandingEditorChanged({
        branding: {
          ...brandingData,
          ...changes,
        },
      })
    );
  };

  const onPrimaryColorClick = (color: string) => {
    onBrandingChanged({ primaryColor: color });
  };

  const onLogoPresetClick = async (preset: LogoPreset) => {
    onBrandingChanged(
      await maybeUploadEditedPhoto(removeFile, createFromLocalFile)(img, {
        ...brandingData,
        preset,
      })
    );
  };

  const onFileInputChange = async (event: React.ChangeEvent<any>) => {
    let file = event.target.files![0];
    setUploadingImage(true);

    const result = await createFromLocalFile({
      input: {
        name: file.name,
        type: file.type,
        documentType: CompanyFileDocumentType.LogoCompany,
      },
      file,
    });

    let changes: Branding = {
      ...brandingData,
      logoFileId: result.id,
    };

    changes = await maybeUploadEditedPhoto(removeFile, createFromLocalFile)(
      await loadImageFromFile(file),
      changes
    );

    setUploadingImage(false);

    onBrandingChanged(changes);
  };

  const onRemoveButtonClick = async () => {
    if (brandingData.editedLogoFileId) {
      removeFile(brandingData.editedLogoFileId, true);
    }
    removeFile(brandingData.logoFileId!, true);

    onBrandingChanged({
      logoFileId: null,
      editedLogoFileId: null,
    });
  };

  const preview =
    img || brandingData.logoFileUrl ? (
      <Preview sourceImage={img} options={getPreviewOptions(brandingData)} />
    ) : (
      <styles.UploadCTA />
    );

  const onInfoClick = () => {
    setShowInstructions(!showInstructions);
  };
  return (
    <>
      <containerStyles.CobrandingLayout>
        <containerStyles.NavSidebar title="">
          <styles.Controls
            preview={preview}
            hasPreview={!!img}
            showInstructions={showInstructions}
            uploadingImage={uploadingImage}
            onInfoClick={onInfoClick}
            onRemoveButtonClick={onRemoveButtonClick}
            onLogoFileChange={onFileInputChange}
            logoPresets={
              <>
                <styles.LogoPresetButton
                  rainbow
                  selected={preset === LogoPreset.COLOR}
                  onClick={() => {
                    onLogoPresetClick(LogoPreset.COLOR);
                  }}
                />
                <styles.LogoPresetButton
                  white
                  selected={preset === LogoPreset.WHITE}
                  onClick={() => {
                    onLogoPresetClick(LogoPreset.WHITE);
                  }}
                />
                {/* <styles.LogoPresetButton
                  black
                  selected={preset === LogoPreset.BLACK}
                  onClick={() => {
                    onLogoPresetClick(LogoPreset.BLACK);
                  }}
                /> */}
              </>
            }
            primaryColors={
              <>
                {PRIMARY_COLORS.map((color) => {
                  return (
                    <styles.PrimaryColorButton
                      key={color}
                      selected={primaryColor === color}
                      style={{
                        background: color,
                      }}
                      onClick={() => onPrimaryColorClick(color)}
                    />
                  );
                })}
              </>
            }
          />
        </containerStyles.NavSidebar>
        <containerStyles.CobrandingRight darker>
          <styles.ThemePreview
            logo={
              brandingData.editedLogoFileUrl && (
                <img src={brandingData.editedLogoFileUrl} />
              )
            }
            companyName={companyName}
            style={{
              '--primary-theme-color': primaryColor,
            }}
          />
        </containerStyles.CobrandingRight>
      </containerStyles.CobrandingLayout>
    </>
  );
};

const getPreviewOptions = ({ preset }: Branding) => ({
  hue: {
    [LogoPreset.COLOR]: undefined,
    [LogoPreset.BLACK]: 0,
    [LogoPreset.WHITE]: 255,
  }[preset || LogoPreset.COLOR],
});

/**
 * Utility for creating an HTMLImageElement from a local file - used for uploading a new edited
 * logo if the source image changes
 */

const loadImageFromFile = (file: File) => {
  return new Promise<HTMLImageElement>((resolve) => {
    const image = document.createElement('img');
    image.onload = () => {
      resolve(image);
    };

    const reader = new FileReader();
    reader.onload = () => {
      image.src = reader.result as string;
    };

    reader.readAsDataURL(file);
  });
};

const useImage = (url?: string) => {
  const [img, setImg] = useState<HTMLImageElement | undefined>();
  useEffect(() => {
    if (url == null) {
      return;
    }
    const logoImg = new Image();
    logoImg.crossOrigin = 'anonymous';
    logoImg.onload = () => {
      setImg(logoImg);
    };
    logoImg.src = url;
  }, [url]);

  return img;
};

/**
 * Utility for uploading logo with filters. Note that "maybe" is included in here because img may not exist -- this would
 * happen if a preset (or any other filter) is defined before a source image.
 */

const maybeUploadEditedPhoto = (
  removeFile: any,
  createFromLocalFile: any
) => async (
  image: HTMLImageElement | undefined,
  branding: Branding
): Promise<Branding> => {
  if (!image) {
    return branding;
  }

  const blob = await getFilteredImageBlob(image, getPreviewOptions(branding));

  // there can only be one edited logo, so if one already exists, then deleted it.
  if (branding.editedLogoFileId) {
    removeFile(branding.editedLogoFileId, true);
  }

  const file = new File([blob], 'edited-logo.png', { type: 'image/png' });

  const result = await createFromLocalFile({
    input: {
      name: file.name,
      type: file.type,
      documentType: CompanyFileDocumentType.LogoCompany,
    },
    file,
  });

  return {
    ...branding,
    editedLogoFileId: result.id,
  };
};
