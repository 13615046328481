import React, { useMemo } from 'react';
import {
  MoreButton,
  MoreButtonItemsType,
  TextType,
  Text,
} from '@hum/ui-library';
import { fileIconMap } from './fileIconMap';

export type FileItemProps = {
  file: File;
  onRemove: () => void;
};

export const FileItem = ({ file, onRemove }: FileItemProps) => {
  const moreItems: MoreButtonItemsType[] = [
    {
      label: 'Remove',
      onClick: onRemove,
    },
  ];

  const FileIcon = fileIconMap[file.type]
    ? fileIconMap[file.type]
    : fileIconMap.default;

  const lastModified = useMemo(() => {
    if (file) {
      const date = new Date(file.lastModified);
      const format = Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
      }).format(date);
      return format;
    }
  }, [file]);

  return (
    <div className="flex items-center mt-8 justify-between">
      <div className="flex items-center">
        <FileIcon className="text-icon-subdued" />
        <Text type={TextType.UI_ELEMENT} className="ml-[18px]">
          {file.name}
        </Text>
      </div>
      <div className="flex items-center">
        <Text type={TextType.BODY_MICO} subdued>
          {lastModified}
        </Text>
        <MoreButton items={moreItems} />
      </div>
    </div>
  );
};
