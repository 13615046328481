import React from 'react';
import { ConnectorOption, Field } from '@hum/types';
import { Button, ButtonType, TextInput } from '@hum/ui-library';

export type AuthenticationFormProps = {
  connector: ConnectorOption | null;
  parameters: Record<string, string>;
  setParameters: (params: any) => void;
  formFilled?: boolean;
  onClose?: () => void;
  onConnect: () => void;
};

export const AuthenticationForm = ({
  connector,
  parameters,
  setParameters,
  formFilled = false,
  onClose = () => {},
  onConnect,
}: AuthenticationFormProps) => {
  return (
    <>
      {connector &&
        connector.config.parameters.map((parameter: Field) => (
          <div key={parameter.fieldName} className="m-6">
            <TextInput
              placeholder={parameter.label}
              id={parameter.fieldName}
              name={parameter.fieldName}
              value={parameters[parameter.fieldName] || ''}
              onChange={(value) =>
                setParameters({
                  ...parameters,
                  [parameter.fieldName]: value,
                })
              }
            />
          </div>
        ))}
      <div className="flex bg-surface-dark p-4 gap-4">
        <div className="grow">
          <Button type={ButtonType.QUIET} onClick={onClose}>
            Cancel
          </Button>
        </div>
        <Button disabled={!formFilled} onClick={onConnect}>
          Connect
        </Button>
      </div>
    </>
  );
};
