import React, { useCallback } from 'react';
import { ConnectorOption, Connector } from '@hum/types';
import { expandConnectUrl } from '@hum/icm-app/src/utils';
import { useApiMutation, createConnector } from '@hum/api';
import { toast } from '@hum/common/src/modules/toast';

import { useAuthState } from '.';
import { ConnectorModalProps } from '../modals/ConnectorModal';
import { NewConnectAnimation } from '../modals/ConnectorModal/AnimationModalFx';

export type IUseCodatConnector = {
  onConnect: () => Promise<void>;
  modalProps: Omit<ConnectorModalProps, 'type'>;
  connection: Connector | null;
  isConnectionError: boolean;
};

export type UseCodatConnectorArgs = {
  connector: ConnectorOption | null;
};

export const useCodatConnector = ({
  connector,
}: UseCodatConnectorArgs): IUseCodatConnector => {
  const { setConnectionId } = useAuthState();
  const {
    mutateAsync: connect,
    isError: isConnectionError,
    data: connection = null,
    reset,
  } = useApiMutation(createConnector, {
    onError: (e: any) => {
      reset();
      toast.error(e.message);
    },
  });

  const onConnect = useCallback(async () => {
    if (!connector?.config) {
      return;
    }

    const newConnection = await connect({
      connectorConfigId: connector.config.id,
    });

    if (!newConnection) {
      return;
    }

    setConnectionId(newConnection.id);

    const connectUrl = expandConnectUrl(newConnection.meta.connectUrl || '');
    window.open(connectUrl, '_self');
  }, [connector]);

  return {
    onConnect,
    modalProps: {
      icon: <NewConnectAnimation />,
      title: `You’re being redirected to ${connector?.config.label ?? ''}`,
      description:
        "After connecting your account you will return to Hum's Intelligent Capital Market.",
      isOpen: true,
      testId: 'onboarding-connect:connector-success-modal',
    },
    isConnectionError,
    connection,
  };
};
