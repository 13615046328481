import { AvailableServiceInfo } from '@hum/icm-app/src/pages/connectors/Integrations/Services/base';
import {
  ConnectorType,
  CodatConnectorType,
  ConnectorSource,
  Connector,
  ConnectorHealthStatus,
} from '@hum/icm-app/src/state';
import { CompanyFileDocumentType } from '@hum/types';
import { AVAILABLE_SERVICES } from '@hum/icm-app/src/pages/connectors/Integrations/Services';
import { FileManager } from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { ConnectorStatus } from '@hum/icm-app/src/backend/api/models/connectorModels';

export const serviceIsCodat = ({
  service,
}: {
  service: AvailableServiceInfo;
}) => {
  return service.source === ConnectorSource.Codat;
};

// This list is what tells us what services should appear in the UI for onboarding flow
export const SERVICES_SHOWN_IN_ONBOARDING: AvailableServiceInfo[] = AVAILABLE_SERVICES.filter(
  (service) =>
    [
      CodatConnectorType.Quickbooks,
      CodatConnectorType.QuickbooksDesktop,
      CodatConnectorType.QuickbooksSandbox,
      CodatConnectorType.Netsuite,
      ConnectorType.Quickbooks,
      ConnectorType.Xero,
      ConnectorType.Netsuite,
    ].includes(service.type)
);

// default to all services
// If the company has uploaded one IS and one BS, we consider it as complete for onboarding and ready to move to the next stage
export const hasCompletedOnboardingFileUpload = ({ files }: FileManager) => {
  const hasIncomeStatement = files.some(
    ({ documentType }) =>
      documentType === CompanyFileDocumentType.IncomeStatement
  );
  const hasBalanceSheet = files.some(
    ({ documentType }) => documentType === CompanyFileDocumentType.BalanceSheet
  );

  return hasIncomeStatement && hasBalanceSheet;
};

export const showNewConnectAnimation = ({
  showDirectConnect,
  isConnecting,
}: {
  showDirectConnect: boolean;
  isConnecting: boolean;
}) => {
  // Show new big logo when is connecting
  if (isConnecting) {
    return true;
  }
  // but not when showing the small logo because of direct connect
  if (showDirectConnect) {
    return false;
  }
  // default to big logo (for other connecting states)
  return true;
};

export const showRegularServiceLogo = ({
  showDirectConnect,
  isConnecting,
}: {
  showDirectConnect: boolean;
  isConnecting: boolean;
}) => {
  // only show small service logo when the inverse of connecting and is a direct connector
  if (showDirectConnect) {
    return !isConnecting;
  } else {
    // hide small service logo when hot direct connect and not connecting
    if (isConnecting) {
      return false;
    }
  }
  return true;
};

// Create an easier to work with map of all of the possible health status for a connected service
export const getConnectedServiceHealth = (service: Connector) => {
  const isActive =
    [
      ConnectorHealthStatus.Healthy,
      ConnectorHealthStatus.PullInProgress,
      ConnectorHealthStatus.InitialPullInProgress,
    ].includes(service.health) ||
    [
      ConnectorHealthStatus.Healthy,
      ConnectorHealthStatus.PullInProgress,
      ConnectorHealthStatus.InitialPullInProgress,
    ].includes(service.healthStatus);

  const isPending =
    service.health === ConnectorHealthStatus.Pending ||
    service.healthStatus === ConnectorHealthStatus.Pending;

  const isCritical =
    [
      ConnectorHealthStatus.Critical,
      ConnectorHealthStatus.Recovering,
      ConnectorHealthStatus.Stale,
    ].includes(service.health) ||
    [
      ConnectorHealthStatus.Critical,
      ConnectorHealthStatus.Recovering,
      ConnectorHealthStatus.Stale,
    ].includes(service.healthStatus);

  return { isActive, isPending, isCritical };
};

// Create an easier to work with map of all of the possible health status for a connected service
export const getConnectedServiceStatus = (service: Connector) => {
  const isActive = service.status === ConnectorStatus.Active;
  const isPending = service.status === ConnectorStatus.Pending;

  const isCritical = [
    ConnectorStatus.Disconnected,
    ConnectorStatus.Deauthorized,
  ].includes(service.status);

  return { isActive, isPending, isCritical };
};
