import React from "react";
import _pub778Aed3, {Wrapper as _pub778Aed3_Wrapper,CobrandingLayout as _pub778Aed3_CobrandingLayout,NavSidebar as _pub778Aed3_NavSidebar,CobrandingRight as _pub778Aed3_CobrandingRight} from "../../../../components/Split/styles.pc";
import _pubA53A8Fae, {TablePreview as _pubA53A8Fae_TablePreview} from "../../../../../../design-system/src/Table.pc";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var CobrandingMenuItem = React.memo(React.forwardRef(function CobrandingMenuItem(props, ref) {
  return React.createElement("a", {
    "className": "_f7a0feff _5c97d0b8 _pub-5c97d0b8" + " " + "_5c97d0b8_cobranding_menu_item _pub-5c97d0b8_cobranding_menu_item cobranding_menu_item " + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["active"] ? " " + "_5c97d0b8_active _pub-5c97d0b8_active active" : ""),
    "ref": ref,
    "href": props["href"],
  }, 
    props["children"]
  )
}));

var CobrandingMenu = React.memo(React.forwardRef(function CobrandingMenu(props, ref) {
  return React.createElement("nav", {
    "className": "_1018d3f8 _5c97d0b8 _pub-5c97d0b8" + " " + "_5c97d0b8_cobranding_menu _pub-5c97d0b8_cobranding_menu cobranding_menu",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { CobrandingMenu };


