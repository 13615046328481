import React, { useState } from 'react';
import { Modal } from '@hum/design-system';
import {
  Field,
  TextInput,
  InputMode,
  SelectInput,
  SelectOption,
} from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import { useField, useForm } from '@hum/common/src/modules/form';
import {
  SYNDICATION_INVESTOR_INVESTMENT_RANGE_OPTIONS,
  SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS,
  BUSINESS_TYPE_SELECT_OPTIONS,
} from '@hum/icm-app/src/components/constants';
import { toast } from '@hum/common/src/modules/toast';
import * as Yup from 'yup';
import { Company, SyndicationInvestorDetails } from '@hum/types';
import {
  adminEditCompanyFormClosed,
  investorFormSaved,
} from '@hum/icm-app/src/actions';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { editCompanyFormClosed, useApiMutation } from '@hum/api';

export type FundingProfileProps = {
  currentCompany?: Company;
  isOpen: boolean;
  onClose: () => void;
};

function getNullOrNumber(value: number | null | undefined) {
  return value == null ? null : Number(value);
}
const getValuesFromSelectOption = (options: SelectOption[]) => {
  return (options || []).map((option) => option.value);
};

const outputSyndicationInvestorMultiSelectValues = (
  syndicationInvestor: UpdateSyndicationInvestorInformationFormPayload['syndicationInvestor'],
  toValues: string[]
) => {
  const formattedValues = toValues
    .map((key) => ({
      [key]: getValuesFromSelectOption(syndicationInvestor[key]),
    }))
    .reduce((acc, current) => ({ ...acc, ...current }), {});
  return {
    ...syndicationInvestor,
    ...formattedValues,
  };
};
const formatPayload = (
  payload: UpdateSyndicationInvestorInformationFormPayload
) => {
  const syndicationInvestorValues = outputSyndicationInvestorMultiSelectValues(
    payload.syndicationInvestor,
    ['investmentRanges', 'investmentTypes', 'investmentBusinessTypes']
  );
  return {
    company: normalize.company.out(payload.company),
    syndicationInvestor: normalize.syndicationInvestorDetail.out({
      ...syndicationInvestorValues,
      investmentYearlyRevenueMin: getNullOrNumber(
        syndicationInvestorValues.investmentYearlyRevenueMin
      ),
      investmentYearlyRevenueMax: getNullOrNumber(
        syndicationInvestorValues.investmentYearlyRevenueMax
      ),
    }),
  };
};

type UpdateSyndicationInvestorInformationFormPayload = {
  company: Pick<Company, 'description' | 'website'>;
  syndicationInvestor: Pick<
    SyndicationInvestorDetails,
    | 'location'
    | 'investmentRanges'
    | 'investmentTypes'
    | 'investmentBusinessTypes'
    | 'investmentYearlyRevenueMin'
    | 'investmentYearlyRevenueMax'
  >;
};

const validationSchema = Yup.object().shape({
  company: Yup.object().shape({
    description: Yup.string().required('Description is required'),
    website: Yup.string().nullable(),
  }),
  syndicationInvestor: Yup.object().shape({
    location: Yup.string().required('Investor location is required'),
    investmentRanges: Yup.array().required('Investment ranges is required'),
    investmentTypes: Yup.array().required('Investment type is required'),
    investmentBusinessTypes: Yup.array().nullable(),
    investmentYearlyRevenueMin: Yup.string().nullable(),
    investmentYearlyRevenueMax: Yup.string().nullable(),
  }),
});

const prepareForUpdate = (company: Partial<Company> = {}) => {
  return {
    company: {
      description: company.description || '',
      website: company.website || '',
    },
    syndicationInvestor: {
      location: company.syndicationInvestor?.location || '',
      investmentRanges: company.syndicationInvestor?.investmentRanges || [],
      investmentTypes: company.syndicationInvestor?.investmentTypes || [],
      investmentBusinessTypes:
        company.syndicationInvestor?.investmentBusinessTypes || [],
      investmentYearlyRevenueMin:
        company.syndicationInvestor?.investmentYearlyRevenueMin || null,
      investmentYearlyRevenueMax:
        company.syndicationInvestor?.investmentYearlyRevenueMax || null,
    },
  };
};

export const UpdateFundingProfile = ({
  currentCompany,
  isOpen,
  onClose,
}: FundingProfileProps) => {
  const api = useAPI();
  const { dispatch } = useAppStore();
  const [loading, setLoading] = useState(false);
  const flags = useFlags();
  const { mutate: updateInfo } = useApiMutation(editCompanyFormClosed, {
    onSuccess: () => {
      onClose();
    },
  });
  const updateInfoSagas = async (
    payload: UpdateSyndicationInvestorInformationFormPayload
  ) => {
    try {
      setLoading(true);

      const companyId = currentCompany?.id;
      const formattedPayload = formatPayload(payload);

      const response = await api.legacy.adminEditCompanyFormClosed(
        companyId,
        formattedPayload.syndicationInvestor,
        formattedPayload.company
      );

      if (currentCompany && companyId && response.company.id) {
        // TODO: only one dispatch per action!
        dispatch(adminEditCompanyFormClosed({ companyId: currentCompany?.id }));
        dispatch(investorFormSaved({ companyId }));
        toast.success('Updated syndication investor successfully!');
        onClose();
      }
    } catch (error: any) {
      console.error(error);
      toast.error(
        error?.message || 'Failed to update syndication investor information.'
      );
    } finally {
      setLoading(false);
    }
  };

  const initialValues = React.useMemo(() => prepareForUpdate(currentCompany), [
    currentCompany,
  ]);

  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: flags.enabled('use-react-query') ? updateInfo : updateInfoSagas,
  });

  const handleClose = () => {
    form.resetForm();
    onClose();
  };

  const itemsField = [
    {
      testid: 'user-settings:funding-profile:location',
      type: 'input',
      label: 'Location',
      property: 'syndicationInvestor.location',
    },
    {
      testid: 'user-settings:funding-profile:website',
      type: 'input',
      label: 'Website',
      property: 'company.website',
    },
    {
      testid: 'user-settings:funding-profile:description',
      type: 'input',
      label: 'Description',
      property: 'company.description',
    },
    {
      testid: 'user-settings:funding-profile:investment-range',
      type: 'select',
      label: 'Investment Range',
      property: 'syndicationInvestor.investmentRanges',
      options: SYNDICATION_INVESTOR_INVESTMENT_RANGE_OPTIONS,
    },
    {
      testid: 'user-settings:funding-profile:investment-type',
      type: 'select',
      label: 'Investment Type',
      property: 'syndicationInvestor.investmentTypes',
      options: SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS,
    },
    {
      testid: 'user-settings:funding-profile:investment-yearly-revenue-min',
      type: 'input',
      label: 'Target yearly min. Revenue',
      property: 'syndicationInvestor.investmentYearlyRevenueMin',
    },
    {
      testid: 'user-settings:funding-profile:investment-yearly-revenue-max',
      type: 'input',
      label: 'Target yearly max. Revenue',
      property: 'syndicationInvestor.investmentYearlyRevenueMax',
    },
    {
      testid: 'user-settings:funding-profile:investment-business-type',
      type: 'select',
      label: 'Target business types',
      property: 'syndicationInvestor.investmentBusinessTypes',
      options: BUSINESS_TYPE_SELECT_OPTIONS,
    },
  ];

  return (
    <Modal
      visible={isOpen}
      header="Edit profile"
      onClose={handleClose}
      onSubmit={form.handleSubmit}
      side
      wide
      scrollable
      footer={
        <>
          <Button
            type={ButtonType.SECONDARY}
            disabled={loading || form.isSubmitting}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            submit
            loading={loading || form.isSubmitting}
            disabled={loading || form.isSubmitting}
          >
            Save changes
          </Button>
        </>
      }
    >
      {itemsField.map((item) => (
        <Field
          key={item.testid}
          label={item.label}
          v3
          {...useField(`${item.property}`, 'text', form).input}
        >
          {(props) =>
            item.type == 'input' ? (
              item.testid ===
                'user-settings:funding-profile:investment-yearly-revenue-min' ||
              item.testid ===
                'user-settings:funding-profile:investment-yearly-revenue-max' ? (
                <TextInput
                  inputMode={InputMode.DOLLAR}
                  {...props}
                  data-testid={`${item.testid}-input`}
                />
              ) : (
                <TextInput
                  {...props}
                  data-testid={`${item.testid}-input`}
                  {...(item.testid === 'user-settings:funding-profile:location'
                    ? { autoFocus: true }
                    : { autoFocus: false })}
                />
              )
            ) : (
              <SelectInput
                {...props}
                aria-label={item.label}
                placeholder=""
                data-testid={item.testid}
                options={item.options}
                isMulti
                isCreatable
              />
            )
          }
        </Field>
      ))}
    </Modal>
  );
};
