import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonType,
  Icon,
  OnboardingLayout,
  Text,
  TextType,
  Topbar,
} from '@hum/ui-library';
import { Routes } from '@hum/icm-app/src/state';
import { useAnalyticsEvent } from '@hum/api';

export default function RaiseOrInvest() {
  const history = useHistory();
  const trackEvent = useAnalyticsEvent();
  const title = 'What is your reason for signing up with Hum, today?';
  useEffect(() => {
    trackEvent('company-visit-raise-or-invest');
  }, []);

  return (
    <>
      <Topbar
        logo={<Icon.Hum />}
        title="Signup as a company"
        background="bg-[#12181E66]"
        className="md:hidden"
      />
      <OnboardingLayout>
        <OnboardingLayout.LeftContent>
          <div className="hidden md:block">
            <Icon.Hum />
          </div>
          <OnboardingLayout.Header title={title} />
          <OnboardingLayout.Footer>
            <Text type={TextType.BODY_MICO} subdued>
              Already a member?{' '}
              <a href="/login" className="ui-link text-bodyMicoSize">
                Login
              </a>
            </Text>
          </OnboardingLayout.Footer>
        </OnboardingLayout.LeftContent>
        <OnboardingLayout.RightContent>
          <div className="max-w-[400px] w-full flex flex-col justify-center items-center">
            <Button
              type={ButtonType.SECONDARY}
              testId="link:company-signup"
              onClick={() =>
                history.push(Routes.SIGNUP + history.location.search)
              }
              className="!p-5 w-full mb-4 bg-borderColor"
            >
              I’m looking to fundraise
            </Button>
            <Button
              type={ButtonType.SECONDARY}
              testId="link:investor-signup"
              onClick={() =>
                history.push(Routes.SIGNUP_INVESTOR + history.location.search)
              }
              className="!p-5 w-full bg-borderColor"
            >
              I’m looking to invest
            </Button>
            <OnboardingLayout.FooterMobile>
              <Text type={TextType.BODY_MICO} subdued>
                Already a member?{' '}
                <a href="/login" className="ui-link text-bodyMicoSize">
                  Login
                </a>
              </Text>
            </OnboardingLayout.FooterMobile>
          </div>
        </OnboardingLayout.RightContent>
      </OnboardingLayout>
    </>
  );
}
