import React from 'react';
import Lottie from 'lottie-react-web';

import connectAnimation3 from '@hum/assets/connect-service-animation/HumAnimation.json';

export const NewConnectAnimation = () => {
  return (
    <Lottie
      options={{
        animationData: connectAnimation3,
      }}
      width={140}
      height={140}
    />
  );
};
