import React, { useCallback } from 'react';
import clsx from 'clsx';

import { ConnectorOption, ConnectorStatus, ConnectorType } from '@hum/types';
import { Icon, Spinner, ConnectorItem } from '@hum/ui-library';
import { isWindowsOS } from '@hum/icm-app/src/utils';

export type ConnectorListProps = {
  selectedConnector: ConnectorOption | null;
  selectConnector: (connector: ConnectorOption | null) => void;
  connectors: ConnectorOption[];
  isOnboarding?: boolean;
  isLoading?: boolean;
  className?: string;
};

export const ConnectorList = ({
  selectedConnector,
  selectConnector,
  connectors,
  isLoading = false,
  className,
}: ConnectorListProps) => {
  const onConnectorSelected = useCallback(
    (connector: ConnectorOption) => {
      selectConnector(
        selectedConnector?.config.id === connector.config.id ? null : connector
      );
    },
    [selectedConnector]
  );
  const isWindows = isWindowsOS();
  const normalizeString = (str: string) => (str || '').toLocaleLowerCase();
  const isLabelDescriptionSame = (connector: ConnectorOption) =>
    normalizeString(connector.config.label) ===
    normalizeString(connector.config?.description || '');
  const getConnectorDescription = (
    connector: ConnectorOption,
    windowsOS: boolean
  ) => {
    if (isLabelDescriptionSame(connector)) {
      return '';
    }
    if (
      !windowsOS &&
      connector.config.connectorType === ConnectorType.QuickBooksDesktop
    ) {
      return connector.config.description + ' (Windows only)';
    }
    return connector.config.description;
  };

  return (
    <div
      data-testid={
        isLoading ? 'connector-list-items-loading' : 'connector-list-items'
      }
      className={clsx('grid', 'gap-4', className)}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        connectors.map((connector) => (
          <ConnectorItem
            testId={`connector-item-${connector.id || connector.config.id}-${
              connector.config.label
            }`}
            key={connector.id || connector.config.id}
            onClick={() =>
              !isWindows &&
              connector.config.connectorType === ConnectorType.QuickBooksDesktop
                ? null
                : onConnectorSelected(connector)
            }
            title={connector.config.label}
            description={getConnectorDescription(connector, isWindows)}
            icon={
              connector.config.icon ? (
                <Icon
                  src={connector.config.icon}
                  className="-translate-x-1/2 -translate-y-1/2"
                />
              ) : (
                <Icon.DefaultFile />
              )
            }
            selected={connector === selectedConnector}
            connected={
              !!connector.id && connector.status === ConnectorStatus.Active
            }
            disabled={
              !isWindows &&
              connector.config.connectorType === ConnectorType.QuickBooksDesktop
            }
          />
        ))
      )}
    </div>
  );
};
