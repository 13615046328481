import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';

// See event details and documentation here:
// https://www.notion.so/capitaltechnology/3e79df34b12845fc82314b95f3191a4c?v=ec610dbdd9fc4940b8c375083775452e

export enum CompanySignupActionType {
  COMPANY_VISIT_REVIEW_AND_CONFIRM = 'COMPANY_VISIT_REVIEW_AND_CONFIRM',
  COMPANY_VISIT_RAISE_OR_INVEST = 'COMPANY_VISIT_RAISE_OR_INVEST',
  COMPANY_VISIT_SIGN_UP = 'COMPANY_VISIT_SIGN_UP',
  COMPANY_VISIT_TELL_US_ABOUT_YOUR_BUSINESS = 'COMPANY_VISIT_TELL_US_ABOUT_YOUR_BUSINESS',
  COMPANY_VISIT_SHARE_YOUR_GOALS = 'COMPANY_VISIT_SHARE_YOUR_GOALS',
  COMPANY_VISIT_FUNDRAISING_HISTORY = 'COMPANY_VISIT_FUNDRAISING_HISTORY',
  COMPANY_VISIT_VERIFY_EMAIL = 'COMPANY_VISIT_VERIFY_EMAIL',
}

export type TrackPageviewReviewAndConfirm = BaseAction<CompanySignupActionType.COMPANY_VISIT_REVIEW_AND_CONFIRM>;
export type TrackPageviewRaiseOrInvest = BaseAction<CompanySignupActionType.COMPANY_VISIT_RAISE_OR_INVEST>;
export type TrackPageviewCompanySignUp = BaseAction<CompanySignupActionType.COMPANY_VISIT_SIGN_UP>;
export type TrackPageviewCompanyTellUsAboutYourBusiness = BaseAction<CompanySignupActionType.COMPANY_VISIT_TELL_US_ABOUT_YOUR_BUSINESS>;
export type TrackPageviewCompanyShareYourGoals = BaseAction<CompanySignupActionType.COMPANY_VISIT_SHARE_YOUR_GOALS>;
export type TrackPageviewCompanyFundraisingHistory = BaseAction<CompanySignupActionType.COMPANY_VISIT_FUNDRAISING_HISTORY>;
export type TrackPageviewCompanyVerifyEmail = BaseAction<CompanySignupActionType.COMPANY_VISIT_VERIFY_EMAIL>;

export const trackPageviewReviewAndConfirm = actionCreator<TrackPageviewReviewAndConfirm>(
  CompanySignupActionType.COMPANY_VISIT_REVIEW_AND_CONFIRM
);
export const trackPageviewRaiseOrInvest = actionCreator<TrackPageviewRaiseOrInvest>(
  CompanySignupActionType.COMPANY_VISIT_RAISE_OR_INVEST
);
export const trackPageviewCompanySignUp = actionCreator<TrackPageviewCompanySignUp>(
  CompanySignupActionType.COMPANY_VISIT_SIGN_UP
);
export const trackPageviewCompanyTellUsAboutYourBusiness = actionCreator<TrackPageviewCompanyTellUsAboutYourBusiness>(
  CompanySignupActionType.COMPANY_VISIT_TELL_US_ABOUT_YOUR_BUSINESS
);
export const trackPageviewCompanyShareYourGoals = actionCreator<TrackPageviewCompanyShareYourGoals>(
  CompanySignupActionType.COMPANY_VISIT_SHARE_YOUR_GOALS
);
export const trackPageviewCompanyFundraisingHistory = actionCreator<TrackPageviewCompanyFundraisingHistory>(
  CompanySignupActionType.COMPANY_VISIT_FUNDRAISING_HISTORY
);
export const trackPageviewCompanyVerifyEmail = actionCreator<TrackPageviewCompanyVerifyEmail>(
  CompanySignupActionType.COMPANY_VISIT_VERIFY_EMAIL
);

export enum CompanyOnboardingActionType {
  COMPANY_VISIT_WELCOME_TO_HUM = 'COMPANY_VISIT_WELCOME_TO_HUM',
  COMPANY_VISIT_MNDA = 'COMPANY_VISIT_MNDA',
  COMPANY_ACCEPT_MNDA = 'COMPANY_ACCEPT_MNDA',
  COMPANY_VISIT_CONNECT = 'COMPANY_VISIT_CONNECT',
  COMPANY_SELECT_OTHER_SOFTWARE = 'COMPANY_SELECT_OTHER_SOFTWARE',
  COMPANY_UPLOAD_FILE = 'COMPANY_UPLOAD_FILE',
  COMPANY_VISIT_FINAL_PAGE = 'COMPANY_VISIT_FINAL_PAGE',
}

export type TrackPageviewCompanyVisitWelcomeToHum = BaseAction<CompanyOnboardingActionType.COMPANY_VISIT_WELCOME_TO_HUM>;
export type TrackCompanyVisitMnda = BaseAction<CompanyOnboardingActionType.COMPANY_VISIT_MNDA>;
export type TrackCompanyAcceptMnda = BaseAction<CompanyOnboardingActionType.COMPANY_ACCEPT_MNDA>;
export type TrackPageviewCompanyVisitConnect = BaseAction<CompanyOnboardingActionType.COMPANY_VISIT_CONNECT>;
export type TrackCompanySelectOtherSoftware = BaseAction<CompanyOnboardingActionType.COMPANY_SELECT_OTHER_SOFTWARE>;
export type TrackCompanyUploadFile = BaseAction<CompanyOnboardingActionType.COMPANY_UPLOAD_FILE>;
export type TrackPageviewCompanyFinalPage = BaseAction<CompanyOnboardingActionType.COMPANY_VISIT_FINAL_PAGE>;

export const trackPageviewCompanyVisitWelcomeToHum = actionCreator<TrackPageviewCompanyVisitWelcomeToHum>(
  CompanyOnboardingActionType.COMPANY_VISIT_WELCOME_TO_HUM
);
export const trackCompanyVisitMnda = actionCreator<TrackCompanyVisitMnda>(
  CompanyOnboardingActionType.COMPANY_VISIT_MNDA
);
export const trackCompanyAcceptMnda = actionCreator<TrackCompanyAcceptMnda>(
  CompanyOnboardingActionType.COMPANY_ACCEPT_MNDA
);
export const trackPageviewCompanyVisitConnect = actionCreator<TrackPageviewCompanyVisitConnect>(
  CompanyOnboardingActionType.COMPANY_VISIT_CONNECT
);
export const trackCompanySelectOtherSoftware = actionCreator<TrackCompanySelectOtherSoftware>(
  CompanyOnboardingActionType.COMPANY_SELECT_OTHER_SOFTWARE
);
export const trackCompanyUploadFile = actionCreator<TrackCompanyUploadFile>(
  CompanyOnboardingActionType.COMPANY_UPLOAD_FILE
);
export const trackPageviewCompanyFinalPage = actionCreator<TrackPageviewCompanyFinalPage>(
  CompanyOnboardingActionType.COMPANY_VISIT_FINAL_PAGE
);

export type MetricsActionType =
  | CompanySignupActionType
  | CompanyOnboardingActionType;
export type MetricsActions =
  | TrackPageviewReviewAndConfirm
  | TrackPageviewRaiseOrInvest
  | TrackPageviewCompanySignUp
  | TrackPageviewCompanyTellUsAboutYourBusiness
  | TrackPageviewCompanyShareYourGoals
  | TrackPageviewCompanyFundraisingHistory
  | TrackPageviewCompanyVerifyEmail
  | TrackPageviewCompanyVisitWelcomeToHum
  | TrackCompanyVisitMnda
  | TrackCompanyAcceptMnda
  | TrackPageviewCompanyVisitConnect
  | TrackCompanySelectOtherSoftware
  | TrackCompanyUploadFile
  | TrackPageviewCompanyFinalPage;
