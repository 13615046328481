import React from 'react';
import { Help, Box } from '@hum/legacy-ui';
import { Button, Icon, Splash, Topbar } from '@hum/ui-library';
import infoIcon from '@hum/assets/icons/info.svg';
import ConfettiAnimation from '../ConfettiAnimation';
import TopbarProfileMenu from '@hum/icm-app/src/components/TopbarProfileMenu';
import { useFinalPage } from '@hum/icm-app/src/pages/onboarding-v5/hooks/useFinalPage';

export function FinalPage() {
  const { goToDashboard } = useFinalPage();

  return (
    <>
      <Topbar
        fixedBar
        background="transparent"
        logo={<Icon.Hum />}
        userLinks={<TopbarProfileMenu showAuthSection />}
      />
      <div className="flex h-screen bg-[#12181E] pt-[48px]">
        <div className="flex flex-col items-center justify-around w-full">
          <Splash
            testId="signup-complete"
            graphic={
              <div className="relative">
                <div className="absolute -z-[1] w-full">
                  <ConfettiAnimation />
                </div>
                <Icon.FinancialGrowth className="max-w-[200px] mx-auto z-1" />
              </div>
            }
            title="You’re ready to go"
            subtitle="We’re already reviewing your information, this process takes about 15 minutes. In the meantime, follow our instructions on what to do next."
          >
            <Button onClick={goToDashboard}>Continue to dashboard</Button>
          </Splash>
          <Box alignCenter>
            <Help
              testId="company-activation:connectors:footer"
              icon={<img src={infoIcon} height="24px" width="24px" />}
            >
              <a
                className="ui-link"
                href="https://info.humcapital.com/hum-help-center/the-fundraising-process"
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn more about our process
              </a>
            </Help>
          </Box>
        </div>
      </div>
    </>
  );
}
