import React from "react";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../design-system/src/utils.pc";
import _pub7Ab5Bb97 from "../syndication-v2/base.pc";
import _pubBa0551B0, {PageContent as _pubBa0551B0_PageContent} from "../syndication-v2/sidebar.pc";
import _pub2Dbb4493 from "../../../../design-system/src/Button.pc";
import _pub8A05Fd1A from "../../../../design-system/src/BlankSlate.pc";
import _pubA53A8Fae, {TableRow as _pubA53A8Fae_TableRow,TableCell as _pubA53A8Fae_TableCell} from "../../../../design-system/src/Table.pc";
import _pub5448A231 from "../../../../design-system/src/Detail.pc";
import _pub4435Fe58 from "../../../../design-system/src/Pill.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var SettingsWrapper = React.memo(React.forwardRef(function SettingsWrapper(props, ref) {
  return React.createElement("div", {
    "className": "_22eeb02a _13a1ce8a _pub-13a1ce8a _pub-a97d53f3" + (props["narrow"] ? " " + "_13a1ce8a_narrow _pub-13a1ce8a_narrow _pub-a97d53f3_narrow narrow" : ""),
    "ref": ref,
    "data-testid": props["testId"],
  }, 
    props["children"]
  )
}));
export { SettingsWrapper };

var FormContent = React.memo(React.forwardRef(function FormContent(props, ref) {
  return React.createElement("div", {
    "className": "_cce0d106 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_97a3c10e _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    }, 
      props["children"]
    )
  )
}));
export { FormContent };

var ModalFooter = React.memo(React.forwardRef(function ModalFooter(props, ref) {
  return React.createElement("div", {
    "className": "_25837433 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { ModalFooter };

var Title = React.memo(React.forwardRef(function Title(props, ref) {
  return React.createElement("div", {
    "className": "_cb8d151f _13a1ce8a _pub-13a1ce8a _pub-a97d53f3" + (props["subtitle"] ? " " + "_13a1ce8a_subtitle _pub-13a1ce8a_subtitle _pub-a97d53f3_subtitle subtitle" : "") + (props["marginless"] ? " " + "_13a1ce8a_marginless _pub-13a1ce8a_marginless _pub-a97d53f3_marginless marginless" : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_90aa69d2 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    }, 
      props["title"],
      props["description"]&&React.createElement("p", {
        "className": "_84c13aa _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
      }, 
        props["description"]
      )
    )
  )
}));
export { Title };

var WrapperData = React.memo(React.forwardRef(function WrapperData(props, ref) {
  return React.createElement("div", {
    "className": "_2c353818 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { WrapperData };

var TextModal = React.memo(React.forwardRef(function TextModal(props, ref) {
  return React.createElement("div", {
    "className": "_9c3e3e9 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
  }, 
    props["text"]
  )
}));
export { TextModal };

var FundingProfileInfo = React.memo(React.forwardRef(function FundingProfileInfo(props, ref) {
  return React.createElement("div", {
    "className": "_e7cd82c5 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
    "data-testid": props["dataTestid"],
  }, 
    React.createElement(_pubBa0551B0_PageContent, {
      "class": "_f2115e76 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_c59b18b8 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
      }, 
        React.createElement(Title, {
          "class": "_f5d3157",
          "title": "Funding profile",
          "description": props["description"],
        }, null),
        props["isPrimaryContact"]&&React.createElement(_pub2Dbb4493, {
          "class": "_2af61584 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
          "v3": true,
          "secondary": true,
          "medium": true,
          "onClick": props["onClick"],
        }, 
          "\n        Edit profile\n      "
          
        )
      ),
      React.createElement(Title, {
        "class": "_5c924902",
        "subtitle": true,
        "title": "General",
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_2b957994 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        "horizontal": true,
        "title": "Location",
        "description": props["location"],
        "padded": true,
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_b5f1ec37 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        "horizontal": true,
        "title": "Website",
        "description": props["website"],
        "padded": true,
      }, null),
      React.createElement(Title, {
        "class": "_c2f6dca1",
        "subtitle": true,
        "title": "Investment preferences",
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_5bff8d1b _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        "horizontal": true,
        "padded": true,
        "title": "Investment range",
        "description": props["investmentRanges"],
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_2cf8bd8d _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        "horizontal": true,
        "padded": true,
        "title": "Investment types",
        "description": props["investmentTypes"],
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_bc47a01c _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        "horizontal": true,
        "padded": true,
        "title": "Target yearly min. Revenue",
        "description": props["targetYearlyMinRevenue"],
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_cb40908a _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        "horizontal": true,
        "padded": true,
        "title": "Target yearly max. Revenue",
        "description": props["targetYearlyMaxRevenue"],
      }, null),
      React.createElement(_pub5448A231, {
        "class": "_31a47f87 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        "horizontal": true,
        "padded": true,
        "title": "Target business types",
        "description": props["targetBusinessTypes"],
      }, null)
    )
  )
}));
export { FundingProfileInfo };

var SettingsPage = React.memo(React.forwardRef(function SettingsPage(props, ref) {
  return React.createElement("div", {
    "className": "_eae27f0 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
    "title": props["title"],
    "description": props["description"],
    "data-testid": props["dataTestid"],
  }, 
    React.createElement(_pubBa0551B0_PageContent, {
      "class": "_f69c22c4 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_4adbed18 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
      }, 
        React.createElement(Title, {
          "class": "_6c8d046d",
          "title": props["title"]||"Settings",
          "description": props["description"],
        }, null)
      ),
      props["children"]
    )
  )
}));
export { SettingsPage };

var CardWithNda = React.memo(React.forwardRef(function CardWithNda(props, ref) {
  return React.createElement("div", {
    "className": "_e0a046dc _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { CardWithNda };

var FieldWrapper = React.memo(React.forwardRef(function FieldWrapper(props, ref) {
  return React.createElement("div", {
    "className": "_7186bdb _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
    "data-testid": props["dataTestId"],
  }, 
    props["children"]
  )
}));
export { FieldWrapper };

var SettingsSection = React.memo(React.forwardRef(function SettingsSection(props, ref) {
  return React.createElement("div", {
    "className": "_10d8d2a8 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
    "data-testid": props["dataTestid"],
  }, 
    React.createElement("div", {
      "className": "_4929ed7d _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    }, 
      React.createElement(Title, {
        "class": "_7407987d",
        "subtitle": true,
        "title": props["title"],
        "description": props["data"],
      }, null)
    ),
    props["children"],
    React.createElement(_pub2Dbb4493, {
      "class": "_a7278c51 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
      "v3": true,
      "secondary": true,
      "medium": true,
      "onClick": props["onClick"],
    }, 
      props["caption"]
    )
  )
}));
export { SettingsSection };

var SettingsPrimarySection = React.memo(React.forwardRef(function SettingsPrimarySection(props, ref) {
  return React.createElement("div", {
    "className": "_fed6b384 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
    "data-testid": props["dataTestid"],
  }, 
    React.createElement("div", {
      "className": "_4aad3913 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    }, 
      React.createElement(Title, {
        "class": "_ec7cb1d",
        "subtitle": true,
        "title": props["title"],
        "description": props["data"],
      }, null)
    ),
    props["children"],
    React.createElement(_pub2Dbb4493, {
      "class": "_a4a3583f _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
      "v3": true,
      "primary": true,
      "medium": true,
      "testId": props["dataTestIdButton"],
      "onClick": props["onClick"],
    }, 
      props["caption"]
    )
  )
}));
export { SettingsPrimarySection };

var ArchiveBlank = React.memo(React.forwardRef(function ArchiveBlank(props, ref) {
  return React.createElement("div", {
    "className": "_17b516b1 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub8A05Fd1A, {
      "class": "_39277537 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
      "title": "Nothing here yet",
      "description": "Come here to see the potential deals you passed on after meeting being introducted to the company",
    }, null)
  )
}));
export { ArchiveBlank };

var TableBox = React.memo(React.forwardRef(function TableBox(props, ref) {
  return React.createElement("div", {
    "className": "_f9bb779d _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubA53A8Fae, {
      "class": "_4da491cf _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
      "secondary": true,
    }, 
      React.createElement(_pubA53A8Fae_TableRow, {
        "class": "_8c405d4b _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        "header": true,
      }, 
        React.createElement(_pubA53A8Fae_TableCell, {
          "class": "_417d4f7b _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        }, 
          React.createElement("FilteredCell", {
            "className": "_6d82c5fd _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
          }, 
            "COMPANY"
            
          )
        ),
        React.createElement(_pubA53A8Fae_TableCell, {
          "class": "_367a7fed _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        }, 
          React.createElement("FilteredCell", {
            "className": "_6c40afca _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
            "reverse": true,
          }, 
            "LOCATION"
            
          )
        ),
        React.createElement(_pubA53A8Fae_TableCell, {
          "class": "_af732e57 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        }, 
          React.createElement("FilteredCell", {
            "className": "_6e061193 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
          }, 
            "VERTICAL"
            
          )
        ),
        React.createElement(_pubA53A8Fae_TableCell, {
          "class": "_d8741ec1 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        }, 
          React.createElement("FilteredCell", {
            "className": "_6fc47ba4 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
          }, 
            "REV MODEL"
            
          )
        )
      ),
      props["children"]
    )
  )
}));
export { TableBox };

var TableRowContent = React.memo(React.forwardRef(function TableRowContent(props, ref) {
  return React.createElement(_pubA53A8Fae_TableRow, {
    "class": "_1e035a9a _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubA53A8Fae_TableCell, {
      "class": "_473abcc5 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    }, 
      props["companyName"]
    ),
    React.createElement(_pubA53A8Fae_TableCell, {
      "class": "_303d8c53 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    }, 
      props["companyLocation"]
    ),
    React.createElement(_pubA53A8Fae_TableCell, {
      "class": "_a934dde9 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    }, 
      props["companyVertical"]
    ),
    React.createElement(_pubA53A8Fae_TableCell, {
      "class": "_de33ed7f _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    }, 
      props["companyRevModel"]
    )
  )
}));
export { TableRowContent };

var ArchivePage = React.memo(React.forwardRef(function ArchivePage(props, ref) {
  return React.createElement("div", {
    "className": "_5f99446f _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubBa0551B0_PageContent, {
      "class": "_d4fed5c4 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
      "padded": true,
      "fixedWidth": true,
      "center": true,
      "title": "Archive",
      "tooltip": "Potential deals you’ve passed on after an introduction",
    }, 
      props["children"]
    )
  )
}));
export { ArchivePage };

var BackButtonWrapper = React.memo(React.forwardRef(function BackButtonWrapper(props, ref) {
  return React.createElement("div", {
    "className": "_b1972543 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    "ref": ref,
    "testId": props["testId"],
  }, 
    React.createElement("div", {
      "className": "_d77a01aa _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
    }, 
      React.createElement(_pub2Dbb4493, {
        "class": "_13c2fba5 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
        "testId": "investor-settings-back-button",
        "transparent": true,
        "onClick": props["clickGoBack"],
      }, 
        React.createElement("img", {
          "className": "_c93238d0 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
          "src": getDefault(require("./assets/icon.svg")),
        }, null)
      ),
      props["children"]
    )
  )
}));
export { BackButtonWrapper };

var Nda = React.memo(React.forwardRef(function Nda(props, ref) {
  return React.createElement("div", {
    "className": "_df8b2494 _13a1ce8a _pub-13a1ce8a _pub-a97d53f3" + " " + "_13a1ce8a_text-body-medium _pub-13a1ce8a_text-body-medium _pub-a97d53f3_text-body-medium text-body-medium",
    "ref": ref,
  }, 
    React.createElement("img", {
      "className": "_19033c6e _13a1ce8a _pub-13a1ce8a _pub-a97d53f3",
      "src": getDefault(require("./assets/nda.svg")),
    }, null)
  )
}));
export { Nda };


