import {
  AccordionContent,
  Accordion,
  FileDropZone as StyleFileDropZone,
} from '@hum/legacy-ui';
import { ButtonType, MoreButton, MoreButtonItemsType } from '@hum/ui-library';
import React, { memo, useCallback, useState } from 'react';
import * as styles from './index.pc';
import {
  FileManager,
  useFileManager,
} from '@hum/icm-app/src/__DEPRECATED__modules/files';
import { CompanyFile, CompanyFileDocumentType } from '@hum/types';
import dayjs from 'dayjs';
import { UploadProgressPopup } from '@hum/icm-app/src/components/UploadProgressPopup';
import { useCurrentCompany } from '../../../hooks/useCurrentCompany';
import { Tooltip } from '../Tooltip';
import kebabCase from 'lodash/kebabCase';

export const csvAndExcelOnlyMimeTypes = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.google-apps.spreadsheet',
];
export const csvAndExcelOnlyAcceptString =
  '.xls,.xlsx,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv';
export const csvAndExcelOnlyLabel = 'Excel and CSV files only';

export const pdfOnlyAcceptString = '.pdf';

export const pdfOnlyMimeTypes = ['application/pdf'];

type ChecklistItem = {
  label: string;
  description?: string;
};

export type ImportDocumentSection = {
  label: string;
  documentType: CompanyFileDocumentType;
  meta?: Record<string, any>;
  description?: string;
  open?: boolean;
  checklist?: ChecklistItem[];
  accept?: string;
  acceptedFileTypesLabel?: string;
  acceptedFileMimeTypes?: string[];
  invalidFileMimeTypeError?: string;
};

type ImportDocumentsTabProps = {
  sections: ImportDocumentSection[];
};

export const ImportDocuments = memo(({ sections }: ImportDocumentsTabProps) => {
  const { fileManager } = useImportDocumentsTab();
  return (
    <>
      <styles.Sections>
        {sections.map((section) => {
          return (
            <UploadSection
              key={section.documentType}
              fileManager={fileManager}
              section={section}
            />
          );
        })}
      </styles.Sections>
      <UploadProgressPopup fileManager={fileManager} />
    </>
  );
});

const useImportDocumentsTab = () => {
  const company = useCurrentCompany();
  const fileManager = useFileManager({ companyId: Number(company.data?.id) });
  return { fileManager };
};

type UploadSectionProps = {
  section: ImportDocumentSection;
  fileManager: FileManager;
};

export const UploadSection = memo(
  ({ section, fileManager }: UploadSectionProps) => {
    const {
      showModal,
      onFileUploadClick,
      onFileUploadChange,
      cancelUpload,
      accept,
      acceptedFileTypesLabel,
      acceptedFileMimeTypes,
      invalidFileMimeTypeError,
    } = useUploadSection(section, fileManager);

    let label = (
      <styles.ImportFileAccordionLabel
        hasDescription={Boolean(section.description)}
        title={section.label}
      />
    );

    if (section.description) {
      label = <Tooltip content={section.description}>{label}</Tooltip>;
    }

    return (
      <>
        <Accordion
          label={label}
          testId={`${kebabCase(section.label)}-accordion`}
          open={section.open}
        >
          {() => {
            return (
              <AccordionContent>
                <FileDropZone
                  onFileUploadClick={onFileUploadClick}
                  onFileUploadChange={onFileUploadChange}
                  accept={accept}
                  acceptedFileTypesLabel={acceptedFileTypesLabel}
                  acceptedFileMimeTypes={acceptedFileMimeTypes}
                  invalidFileMimeTypeError={invalidFileMimeTypeError}
                />
                <styles.UploadItems>
                  {fileManager.files
                    .filter((file) => {
                      return file.documentType === section.documentType;
                    })
                    .map((file) => {
                      return (
                        <UploadItem
                          key={file.id}
                          file={file}
                          fileManager={fileManager}
                        />
                      );
                    })}
                </styles.UploadItems>
              </AccordionContent>
            );
          }}
        </Accordion>
        {section.checklist ? (
          <styles.UploadInfoModal
            fixed
            title={section.label}
            isVisible={showModal}
            onBackgroundClick={cancelUpload}
            onCancelClick={cancelUpload}
            onFileUploadChange={onFileUploadChange}
            accept={accept}
          >
            {section.checklist.map((item) => {
              return (
                <styles.UploadChecklistItem key={item.label}>
                  {item.label} {item.description && <p>{item.description}</p>}
                </styles.UploadChecklistItem>
              );
            })}
          </styles.UploadInfoModal>
        ) : null}
      </>
    );
  }
);

type FileDropZoneProps = {
  onFileUploadClick: (event: any) => void;
  onFileUploadChange: (event: any) => void;
  accept?: string;
  style?: React.CSSProperties;
  acceptedFileTypesLabel?: string;
  acceptedFileMimeTypes?: string[];
  invalidFileMimeTypeError?: string;
  invalid?: boolean;
};

export const FileDropZone = ({
  onFileUploadClick,
  onFileUploadChange,
  accept,
  style,
  acceptedFileTypesLabel,
  acceptedFileMimeTypes,
  invalidFileMimeTypeError,
}: FileDropZoneProps) => {
  const [draggingOver, setDraggingOver] = useState(false);
  const [showRejectType, setShowRejectType] = useState(false);

  const onDragEnter = useCallback(() => {
    setDraggingOver(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setDraggingOver(false);
  }, []);

  const onDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    const fileType = e.dataTransfer.files[0].type;

    if (
      acceptedFileMimeTypes &&
      acceptedFileMimeTypes.length &&
      !acceptedFileMimeTypes.includes(fileType)
    ) {
      e.preventDefault();
      setShowRejectType(true);
    } else {
      setShowRejectType(false);
    }

    setDraggingOver(false);
  }, []);

  const onChange = (e: React.ChangeEventHandler<HTMLInputElement>) => {
    // @ts-ignore
    const fileValue = e.target.value;
    if (typeof fileValue === 'string') {
      if (accept && accept !== '*') {
        const selectedFileExtension = fileValue.split('.').pop();
        if (
          accept.split(',').some((ext) => ext.includes(selectedFileExtension!))
        ) {
          onFileUploadChange(e);
        } else {
          setShowRejectType(true);
          return;
        }
      }
    }
    onFileUploadChange(e);
  };

  return (
    <div style={{ flex: 1 }}>
      <StyleFileDropZone
        invalid={showRejectType}
        invalidFileMimeTypeErrorText={invalidFileMimeTypeError}
        fileHover={draggingOver}
        onFileUploadClick={onFileUploadClick}
        onFileUploadChange={onChange}
        onDragEnter={onDragEnter}
        onDragExit={onDragLeave}
        onDrop={onDrop}
        accept={accept}
        acceptedFileTypesLabel={acceptedFileTypesLabel}
        style={style}
      />
    </div>
  );
};

export const useUploadSection = (
  section: ImportDocumentSection,
  fileManager: FileManager
) => {
  const [showModal, setShowModal] = useState(false);
  const [userSawProfitLossWindow, setUserSawProfitLossWindow] = useState(false);

  const onFileUploadClick = useCallback(
    (event: React.MouseEvent<any>) => {
      if (
        section.checklist &&
        section.checklist.length > 0 &&
        !userSawProfitLossWindow
      ) {
        event.preventDefault();
        setShowModal(true);
        setUserSawProfitLossWindow(true);
      }
    },
    [userSawProfitLossWindow, setUserSawProfitLossWindow]
  );

  const cancelUpload = () => {
    setShowModal(false);
  };

  const onFileUploadChange = fileManager.handleFileChange(
    section.documentType,
    section.meta,
    () => {
      setShowModal(false);
    }
  );

  return {
    fileManager,
    showModal,
    cancelUpload,
    onFileUploadClick,
    onFileUploadChange,
    accept: section.accept,
    acceptedFileTypesLabel: section.acceptedFileTypesLabel,
    acceptedFileMimeTypes: section.acceptedFileMimeTypes,
    invalidFileMimeTypeError: section.invalidFileMimeTypeError,
  };
};

type UploadItemProps = {
  file: CompanyFile;
  fileManager: FileManager;
  hideRemove?: boolean;
  hideDownload?: boolean;
  hideDates?: boolean;
};

const renderDate = (date: string) => dayjs(date).format('H:mmA, MMM D');

const FILE_ICONS = {
  unknown: require('@hum/figma/spot-icons/symbols/files-file@1.svg'),
  csv: require('@hum/figma/spot-icons/symbols/files-csv@1.svg'),
  xlsx: require('@hum/figma/spot-icons/symbols/files-xls@1.svg'),
  pdf: require('@hum/figma/spot-icons/symbols/files-pdf@1.svg'),
  xls: require('@hum/figma/spot-icons/symbols/files-xls@1.svg'),
  ods: require('@hum/figma/spot-icons/symbols/files-file@1.svg'),
};

export const UploadItem = memo(
  ({
    file,
    fileManager,
    hideRemove,
    hideDownload,
    hideDates,
  }: UploadItemProps) => {
    const [removing, setRemoving] = useState(false);

    const onDownloadClick = useCallback(async () => {
      await fileManager.downloadFile(file.id);
    }, []);

    const onRemoveClick = useCallback(() => {
      fileManager.removeFile(file.id);
      setRemoving(true);
    }, []);
    const menuItems: MoreButtonItemsType[] = [
      {
        label: 'Download',
        onClick: onDownloadClick,
        className: hideDownload ? 'hidden' : '',
      },
      {
        label: 'Remove',
        onClick: onRemoveClick,
        className: hideRemove ? 'hidden' : '',
        type: ButtonType.DESTRUCTIVE,
      },
    ];
    return (
      <styles.UploadedItem
        icon={<img src={FILE_ICONS[file.ext] || FILE_ICONS.unknown} />}
        label={file.name}
        uploadedDate={Boolean(hideDates) ? null : renderDate(file.updatedAt)}
        controls={<MoreButton items={menuItems} disabled={removing} />}
      />
    );
  }
);

UploadItem.displayName = 'UploadItem';
