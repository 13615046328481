// all onboarding v4 events are tracked here so that we have a high level of what's tracked and what's not

import { trackActions } from '@hum/icm-app/src/modules/analytics';
import {
  ActionType,
  AuthActionType,
  CompanyActivationActionType,
} from '@hum/icm-app/src/actions';
import { route } from '@hum/icm-app/src/sagas/utils';
import { OnboardingV4ActionType } from '../actions';
import { AppState, routeMatches, Routes } from '@hum/icm-app/src/state';

export function* trackOnboardingV4Actions() {
  yield trackActions([
    // Auth
    {
      actionType: AuthActionType.PAGEVIEW_LOGIN,
      track: 'pageview:login-page',
    },
    {
      actionType: AuthActionType.PAGEVIEW_COMPANY_SIGNUP, // the screen after you enter your email, same path as signup
      track: 'visit:company-signup:enter-your-email',
    },
    {
      actionType: AuthActionType.PAGEVIEW_COMPANY_SIGNUP_ADDITIONAL_FIELDS, // the screen after you enter your email, same path as signup
      track: 'visit:company-signup:additional-fields',
    },
    {
      actionType: AuthActionType.PAGEVIEW_COMPANY_SIGNUP_WELCOME_TO_HUM, // choose your adventure, the screen after you enter your email, welcome to hum
      track: 'visit:company-signup:welcome-to-hum',
    },
    {
      actionType: AuthActionType.PAGEVIEW_COMPANY_SIGNUP_SHARE_YOUR_GOALS, // DEPRECATE IN 2022 – choose your adventure, the screen after welcome to hum, share your goals
      track: 'visit:company-signup:share-your-goals',
    },
    {
      actionType: AuthActionType.PAGEVIEW_COMPANY_SIGNUP_SHARE_YOUR_GOALS, // choose your adventure, the screen after welcome to hum, share your goals
      track: 'company-visit-share-your-goals',
    },
    {
      actionType: AuthActionType.PAGEVIEW_COMPANY_SIGNUP_GIVE_US_A_SNAPSHOT, // choose your adventure, the screen after share your goals, give us a snapshot
      track: 'visit:company-signup:give-us-a-snapshot',
    },
    {
      actionType: AuthActionType.PAGEVIEW_COMPANY_SIGNUP_VERIFY_YOUR_EMAIL, // DEPRECATE IN 2022 – choose your adventure, the screen after give us a snapshot, verify your emaik a.k.a. completed signup
      track: 'visit:company-signup:verify-your-email',
    },
    {
      actionType: route(Routes.SIGNUP_V4_COMPLETED), // the screen after you enter your email, same path as signup
      track: 'visit:company-signup:completed',
    },
    // MNDA
    {
      actionType: OnboardingV4ActionType.USER_ACCEPT_MNDA,
      track: 'company:onboarding:mnda:accept',
    },
    {
      actionType: OnboardingV4ActionType.ACCEPT_MNDA_BUTTON_CLICKED,
      track: 'company:onboarding:mnda:seen',
    },
    // Onboarding
    {
      actionType: CompanyActivationActionType.PAGEVIEW_BUSINESS_DETAILS,
      track: 'pageview:company-onboarding:business-details',
    },
    // accept fee
    {
      actionType: route(Routes.ONBOARDING_V4_FEES),
      track: 'visit:fees',
    },
    {
      actionType: OnboardingV4ActionType.ACCEPT_TOS_BUTTON_CLICKED,
      track: 'company:onboarding:fee:accept',
    },

    // Fundraising (Financial Details)
    {
      actionType: OnboardingV4ActionType.USER_FILL_FUNDRAISING_FORM,
      track: ({ payload }) => ({
        name: 'company:user:onboarding:fundraising:clicked-continue',
        payload,
      }),
    },

    // connectors analytics
    {
      actionType: OnboardingV4ActionType.MANUAL_IMPORT_CONTINUE_BUTTON_CLICKED,
      track: 'onboarding-v4:import-button:clicked',
    },
    {
      actionType: OnboardingV4ActionType.SHOW_MANUAL_IMPORT_PAGE_BUTTON_CLICKED,
      track: 'onboarding-v4:show-manual-import-button:clicked',
    },
    {
      actionType: route(Routes.ONBOARDING_V4_CONNECTORS),
      track: 'onboarding-v4:connectors-page:visited',
    },
    {
      actionType: ActionType.API_FILE_UPLOADED,
      testState(state: AppState) {
        return routeMatches(Routes.ONBOARDING_V4_CONNECTORS)(
          state.history!.pathname
        );
      },
      track: 'onboarding-v4:connectors-page:document:uploaded',
    },
    {
      actionType: route(Routes.ONBOARDING_V4_CONNECTORS_CONNECTED),
      track: 'onboarding-v4:linked-accounts-page:visited',
    },
    {
      actionType: CompanyActivationActionType.PAGEVIEW_DOCUMENT_DATAROOM,
      track: 'pageview:company-activation:document-dataroom',
    },
  ]);
}
