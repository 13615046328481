import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import {
  trackCompanyAcceptMnda,
  trackCompanyVisitMnda,
} from '@hum/icm-app/src/pages/onboarding-v5/metrics/actions';
import { acceptMndaButtonClicked } from './ducks/actions';
import contentNDA from '@hum/icm-app/src/pages/onboarding-v5/agreements/Hum_capital_NDA_1.md';
import blankNDA from '@hum/icm-app/src/pages/onboarding-v5/agreements/company.mnda.form.pdf';
import { getSession, Routes } from '@hum/icm-app/src/state';
import { toast } from '@hum/common/src/modules/toast';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { useApiMutation, acceptMNDA } from '@hum/api';

const MISSING_POSITION_ERROR_MESSAGE = 'User position is required to continue';

export function useMNDA() {
  const flags = useFlags();
  const { mutate: userAcceptMNDA } = useApiMutation(acceptMNDA, {
    onSuccess: () => {
      history.push(Routes.ONBOARDING_V5_OVERVIEW + history.location.search);
    },
  });
  const dispatch = useDispatch();
  const currentUser = useSelector(getSession).data;
  const company = useCurrentCompany();
  const history = useHistory();

  useEffect(() => {
    if (!company.loaded) return;

    if (company.data?.acceptedNDA) {
      history.push(Routes.ONBOARDING_V5_OVERVIEW + history.location.search);
    }

    dispatch(trackCompanyVisitMnda());
  }, [company.loaded, company.data]);

  // TODO: This should be a form, it has to be "submitted" even if it has 0 fields
  // because it has validation that we're doing manually, would make it easier to show errors
  // Jira ticket: https://humcapital.atlassian.net/browse/TD-116
  const onAcceptClick = () => {
    // Position is required because the MNDA is signed using the position as argument
    if (!currentUser?.position) {
      toast.error(MISSING_POSITION_ERROR_MESSAGE);
      throw new Error(MISSING_POSITION_ERROR_MESSAGE);
    }

    const { position, firstName, lastName } = currentUser;

    flags.enabled('use-react-query')
      ? userAcceptMNDA({
          companyId: company.data!.id,
          firstName: firstName,
          lastName: lastName,
          role: position,
        })
      : dispatch(
          acceptMndaButtonClicked({
            companyId: company.data!.id,
            payload: {
              firstName: firstName,
              lastName: lastName,
              role: position,
            },
          })
        );

    dispatch(trackCompanyAcceptMnda());
  };

  type ModalInfo = {
    DATE: string;
    COMPANY_NAME: string;
    USER_NAME: string;
    POSITION: string;
    SPACE: string;
  };

  const mndaData: ModalInfo = {
    DATE: new Intl.DateTimeFormat('en').format(new Date()),
    COMPANY_NAME: currentUser?.company?.name || `______`,
    USER_NAME: `${currentUser?.firstName} ${currentUser?.lastName}` || `______`,
    POSITION: currentUser?.position || `______`,
    SPACE: `&nbsp;`,
  };

  return {
    loaded: company.loaded,
    onAcceptClick,
    mnda: renderMNDA(contentNDA, mndaData),
  };
}

export function useScrollHandler() {
  const [hasReachedBottom, setHasReachedBottom] = useState(false);
  const contentRef = useRef<any>(null);
  const SCROLL_BOTTOM_MARGIN = 20;

  useEffect(() => {
    if (!contentRef.current) return;

    contentRef.current.addEventListener('scroll', handleScroll);
    return () => {
      if (!contentRef.current) return;

      contentRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [contentRef.current]);

  const handleScroll = (event: Event) => {
    const node = event.target as any;
    const closeToBottom =
      node.scrollHeight - node.scrollTop <
      node.clientHeight + SCROLL_BOTTOM_MARGIN;
    //only set it once.
    if (closeToBottom) {
      if (!hasReachedBottom) setHasReachedBottom(true);
    }
  };

  return {
    contentRef,
    hasReachedBottom,
  };
}

export function useBlankMNDADownload() {
  return () => {
    const element = document.createElement('a');
    element.setAttribute('href', blankNDA);
    element.setAttribute('download', 'mnda.pdf');
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
}

function renderMNDA(template, data) {
  let content = template;
  if (data) {
    for (const key in data) {
      content = content.replace(new RegExp(`<<${key}>>`, 'gi'), data[key]);
    }
  }
  return content;
}
