import React from 'react';
import * as Yup from 'yup';
import { Modal } from '@hum/design-system';
import { InputMode, TextInputField } from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';

import { useField, useForm } from '@hum/common/src/modules/form';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { PortfolioMonitoringInviteForm } from '@hum/icm-app/src/state';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import {
  createPortfolioInvite,
  updatePortfolioInvite,
  useApiMutation,
} from '@hum/api';

export const defaultValues = {
  companyName: '',
  companyUrl: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPosition: '',
  investedCapital: 0,
  dateOfFirstInvestment: '',
  // companyName: 'Patito corp',
  // companyUrl: 'https://patito.dev',
  // contactFirstName: 'conde',
  // contactLastName: 'patula',
  // contactEmail: 'conde@patula.com',
  // contactPosition: 'CEO',
  // investedCapital: 666999111,
  // dateOfFirstInvestment: '2020-10-28',
};

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  companyUrl: Yup.string().required('Required'),
  contactFirstName: Yup.string().required('Required'),
  contactLastName: Yup.string().required('Required'),
  contactEmail: Yup.string().required('Required'),
  contactPosition: Yup.string().required('Required'),
  investedCapital: Yup.number()
    .typeError('Numbers only, do not use $')
    .required('Required'),
  dateOfFirstInvestment: Yup.string().required('Required'),
});

export const CreateInviteModal = ({
  companyId,
  isOpen,
  onClose,
  invite,
}: {
  companyId: number;
  isOpen: boolean;
  onClose: () => void;
  invite?: PortfolioMonitoringInviteForm & { id: number };
}) => {
  const flags = useFlags();
  const { mutate: createInvite } = useApiMutation(createPortfolioInvite, {
    onSuccess: (data) => {
      handleClose();
      if (data.message === 'This company is already invited') {
        form.setFieldError(
          'contactEmail',
          'This email already exists in your invites list'
        );
      }
    },
  });
  const { mutate: updateInvite } = useApiMutation(updatePortfolioInvite, {
    onSuccess: () => {
      handleClose();
    },
  });
  const { dispatch } = useAppStore();
  const isUpdate = !!invite?.id;
  const api = useAPI();

  // Wrap in memo to avoid calling this infinite times
  const initialValues = React.useMemo(
    () => (invite?.id ? invite : defaultValues),
    [invite, companyId]
  );

  const form = useForm({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (fields) => {
      if (isUpdate) {
        if (flags.enabled('use-react-query')) {
          updateInvite({ companyId, inviteId: invite!.id, ...fields });
        } else {
          const response = await api.portfolioMonitoring.updatePortfolioInvite(
            dispatch
          )(companyId, invite!.id, {
            companyName: fields.companyName,
            companyUrl: fields.companyUrl,
            contactFirstName: fields.contactFirstName,
            contactLastName: fields.contactLastName,
            contactEmail: fields.contactEmail,
            contactPosition: fields.contactPosition,
            investedCapital: fields.investedCapital,
            dateOfFirstInvestment: fields.dateOfFirstInvestment,
          });

          if (response.data.id) {
            handleClose();
          }
        }
      } else {
        if (flags.enabled('use-react-query')) {
          createInvite({ companyId, ...fields });
        } else {
          const response = await api.portfolioMonitoring.createPortfolioInvite(
            dispatch
          )(companyId, {
            companyName: fields.companyName,
            companyUrl: fields.companyUrl,
            contactFirstName: fields.contactFirstName,
            contactLastName: fields.contactLastName,
            contactEmail: fields.contactEmail,
            contactPosition: fields.contactPosition,
            investedCapital: fields.investedCapital,
            dateOfFirstInvestment: fields.dateOfFirstInvestment,
          });

          if (response.data.id) {
            handleClose();
          }
          // Manually set error @ input field
          if (response.data.message === 'This company is already invited') {
            form.setFieldError(
              'contactEmail',
              'This email already exists in your invites list'
            );
          }
        }
      }
    },
  });

  const handleClose = () => {
    form.resetForm();
    onClose();
  };

  return (
    <>
      <Modal
        visible={isOpen}
        onClose={handleClose}
        onSubmit={form.handleSubmit}
        wide
        side
        padded
        header="Add company to queue"
        scrollable
        footer={
          <>
            <Button
              loading={form.isSubmitting}
              disabled={form.isSubmitting}
              submit
              testId="portfolio:create-invite-modal:create-update-button"
            >
              {isUpdate ? 'Update' : 'Create'} Invite
            </Button>
            <Button
              type={ButtonType.SECONDARY}
              onClick={onClose}
              testId="portfolio:create-invite-modal:cancel-button"
            >
              Cancel
            </Button>
          </>
        }
      >
        <TextInputField
          label="Company Name"
          inputTestId="portfolio:create-invite-modal:companyName"
          {...useField('companyName', 'text', form).input}
        />
        <TextInputField
          label="Company Website"
          inputTestId="portfolio:create-invite-modal:companyUrl"
          {...useField('companyUrl', 'text', form).input}
        />
        <TextInputField
          label="Contact First Name"
          inputTestId="portfolio:create-invite-modal:contactFirstName"
          {...useField('contactFirstName', 'text', form).input}
        />
        <TextInputField
          label="Contact Last Name"
          inputTestId="portfolio:create-invite-modal:contactLastName"
          {...useField('contactLastName', 'text', form).input}
        />
        <TextInputField
          label="Contact Email"
          type="email"
          inputTestId="portfolio:create-invite-modal:contactEmail"
          {...useField('contactEmail', 'text', form).input}
        />
        <TextInputField
          label="Contact Position"
          inputTestId="portfolio:create-invite-modal:contactPosition"
          {...useField('contactPosition', 'text', form).input}
        />
        <TextInputField
          label="Invested Capital Amount"
          inputMode={InputMode.DOLLAR}
          inputTestId="portfolio:create-invite-modal:investedCapital"
          {...useField('investedCapital', 'text', form).input}
        />
        <TextInputField
          spaceBottom
          label="Date of First Investment (YYYY-MM-DD)"
          inputTestId="portfolio:create-invite-modal:dateOfFirstInvestment"
          {...useField('dateOfFirstInvestment', 'text', form).input}
        />
      </Modal>
    </>
  );
};
