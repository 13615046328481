import React from "react";
import _pubA97D53F3 from "../../../../design-system/src/atoms.pc";
import _pubBab5A68F from "../../../../design-system/src/utils.pc";
import _pubD7465D49, {BottomCtaBar as _pubD7465D49_BottomCtaBar} from "../syndication-v2/company-detail.pc";
import _pub7Ab5Bb97, {Container as _pub7Ab5Bb97_Container} from "../syndication-v2/base.pc";
import _pubBa0551B0, {PageContent as _pubBa0551B0_PageContent} from "../syndication-v2/sidebar.pc";
import _pub2F51C399 from "../../../../design-system/src/breakpoints.pc";
import _pub348A5C93 from "../../components/ChartList/insight.pc";
import _pub2Dbb4493 from "../../../../design-system/src/Button.pc";
import _pub14733275 from "../../../../design-system/src/Checkbox.pc";
import _pub8D610C4 from "../../../../design-system/src/PageContent.pc";
import _pubA53A8Fae, {TableRow as _pubA53A8Fae_TableRow,TableCell as _pubA53A8Fae_TableCell} from "../../../../design-system/src/Table.pc";
import _pub622F8858, {MenuContainer as _pub622F8858_MenuContainer,MenuButton as _pub622F8858_MenuButton} from "../../../../design-system/src/Menu.pc";
import _pub5448A231 from "../../../../design-system/src/Detail.pc";
import _pub2F5Be189 from "../../../../design-system/src/LinkAccordion.pc";
import "./styles.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var BackArrowWrapper = React.memo(React.forwardRef(function BackArrowWrapper(props, ref) {
  return React.createElement("div", {
    "className": "_52c04578 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
    "testId": props["testId"],
  }, 
    React.createElement("div", {
      "className": "_a67a6c9d _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      React.createElement(_pub2Dbb4493, {
        "class": "_b0c63774 _438f3407 _pub-438f3407 _pub-a97d53f3",
        "testId": "preview-invite-back-button",
        "transparent": true,
        "onClick": props["clickGoBack"],
      }, 
        React.createElement("img", {
          "className": "_c3c3e111 _438f3407 _pub-438f3407 _pub-a97d53f3",
          "src": getDefault(require("./assets/icon.svg")),
        }, null)
      )
    ),
    React.createElement("div", {
      "className": "_d17d5c0b _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["children"]
    )
  )
}));
export { BackArrowWrapper };

var CheckedCell = React.memo(React.forwardRef(function CheckedCell(props, ref) {
  return React.createElement("div", {
    "className": "_b578687f _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub14733275, {
      "class": "_dbe37101 _438f3407 _pub-438f3407 _pub-a97d53f3",
      "checked": true,
    }, null),
    props["children"]
  )
}));

var FilteredCell = React.memo(React.forwardRef(function FilteredCell(props, ref) {
  return React.createElement("div", {
    "className": "_908eb38e _438f3407 _pub-438f3407 _pub-a97d53f3" + (props["reverse"] ? " " + "_438f3407_reverse _pub-438f3407_reverse _pub-a97d53f3_reverse reverse" : ""),
    "ref": ref,
  }, 
    props["children"],
    React.createElement("i", {
      "className": "_5e4cdeed _438f3407 _pub-438f3407 _pub-a97d53f3",
      "onClick": props["onClick"],
    }, null)
  )
}));

var ActionsInviteCompanies = React.memo(React.forwardRef(function ActionsInviteCompanies(props, ref) {
  return React.createElement("div", {
    "className": "_7e80d2a2 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub2Dbb4493, {
      "class": "_c4c15b39 _438f3407 _pub-438f3407 _pub-a97d53f3",
      "v3": true,
      "transparent": true,
    }, 
      "+ Manually add company"
      
    ),
    React.createElement(_pub2Dbb4493, {
      "class": "_5dc80a83 _438f3407 _pub-438f3407 _pub-a97d53f3",
      "v3": true,
      "transparent": true,
    }, 
      "Preview invite"
      
    )
  )
}));
export { ActionsInviteCompanies };

var ActionsBottomCTA = React.memo(React.forwardRef(function ActionsBottomCTA(props, ref) {
  return React.createElement("div", {
    "className": "_97e37797 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub2Dbb4493, {
      "class": "_c04c278b _438f3407 _pub-438f3407 _pub-a97d53f3",
      "v3": true,
      "transparent": true,
      "onClick": props["onClickSecondary"],
    }, 
      props["skip"]
    ),
    React.createElement(_pub2Dbb4493, {
      "class": "_59457631 _438f3407 _pub-438f3407 _pub-a97d53f3",
      "v3": true,
      "primary": props["bottomCtaPrimary"],
      "onClick": props["onClickPrimary"],
    }, 
      props["cta"]
    )
  )
}));
export { ActionsBottomCTA };

var StepInvestOnboard = React.memo(React.forwardRef(function StepInvestOnboard(props, ref) {
  return React.createElement("div", {
    "className": "_79ed16bb _438f3407 _pub-438f3407 _pub-a97d53f3" + (props["arrow"] ? " " + "_438f3407_arrow _pub-438f3407_arrow _pub-a97d53f3_arrow arrow" : "") + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    props["illustration"]&&React.createElement("div", {
      "className": "_24780125 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["illustration"]
    ),
    React.createElement(_pub5448A231, {
      "class": "_5ac1a25f _438f3407 _pub-438f3407 _pub-a97d53f3",
      "title": props["title"],
      "description": props["description"],
    }, null)
  )
}));
export { StepInvestOnboard };

var Onboarding = React.memo(React.forwardRef(function Onboarding(props, ref) {
  return React.createElement("div", {
    "className": "_9e553bbc _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubBa0551B0_PageContent, {
      "class": "_be51ee79 _438f3407 _pub-438f3407 _pub-a97d53f3",
      "fixedWidth": true,
      "center": true,
      "padded": true,
      "title": props["title"],
    }, 
      React.createElement("div", {
        "className": "_fe65cb2d _438f3407 _pub-438f3407 _pub-a97d53f3",
      }, 
        React.createElement(StepInvestOnboard, {
          "class": "_1cb1f798",
          "illustration": React.createElement("img", {
            "className": "_a1375a0 _438f3407 _pub-438f3407 _pub-a97d53f3",
            "src": getDefault(require("./assets/invite.svg")),
          }, null),
          "title": "Invite",
          "description": "Add your portfolio companies that you would like to monitor on Hum Capital",
        }, null),
        React.createElement(StepInvestOnboard, {
          "class": "_85b8a622" + " " + "_438f3407_arrow _pub-438f3407_arrow _pub-a97d53f3_arrow arrow",
          "illustration": React.createElement("img", {
            "className": "_213e2663 _438f3407 _pub-438f3407 _pub-a97d53f3",
            "src": getDefault(require("./assets/arrow-1.svg")),
          }, null),
        }, null),
        React.createElement(StepInvestOnboard, {
          "class": "_f2bf96b4",
          "illustration": React.createElement("img", {
            "className": "_38251722 _438f3407 _pub-438f3407 _pub-a97d53f3",
            "src": getDefault(require("./assets/connect.svg")),
          }, null),
          "title": "Connect",
          "description": "Companies connect data to track their performance as they keep you in the loop",
        }, null),
        React.createElement(StepInvestOnboard, {
          "class": "_6cdb0317" + " " + "_438f3407_arrow _pub-438f3407_arrow _pub-a97d53f3_arrow arrow",
          "illustration": React.createElement("img", {
            "className": "_776481e5 _438f3407 _pub-438f3407 _pub-a97d53f3",
            "src": getDefault(require("./assets/arrow-2.svg")),
          }, null),
        }, null),
        React.createElement(StepInvestOnboard, {
          "class": "_1bdc3381",
          "illustration": React.createElement("img", {
            "className": "_6e7fb0a4 _438f3407 _pub-438f3407 _pub-a97d53f3",
            "src": getDefault(require("./assets/monitor.svg")),
          }, null),
          "title": "Explore",
          "description": "Follow your portfolio’s performance in real time and evaluate real financing options for them",
        }, null)
      ),
      React.createElement("div", {
        "className": "_8962fbbb _438f3407 _pub-438f3407 _pub-a97d53f3",
      }, 
        React.createElement(_pub2Dbb4493, {
          "class": "_1d739daf _438f3407 _pub-438f3407 _pub-a97d53f3",
          "v3": true,
          "primary": true,
          "onClick": props["onClick"],
        }, 
          "Get started"
          
        )
      )
    )
  )
}));
export { Onboarding };

var InvitePage = React.memo(React.forwardRef(function InvitePage(props, ref) {
  return React.createElement("div", {
    "className": "_899582cf _438f3407 _pub-438f3407 _pub-a97d53f3" + (props["humDark"] ? " " + "_438f3407_hum _pub-438f3407_hum _pub-a97d53f3_hum hum _438f3407_dark _pub-438f3407_dark _pub-a97d53f3_dark dark" : ""),
    "ref": ref,
  }, 
    React.createElement(_pub7Ab5Bb97_Container, {
      "class": "_7ff9e832 _438f3407 _pub-438f3407 _pub-a97d53f3",
      "height100": true,
      "width100": true,
      "noScrollX": true,
    }, 
      React.createElement(_pubBa0551B0_PageContent, {
        "class": "_48b397e _438f3407 _pub-438f3407 _pub-a97d53f3",
        "fixedWidth": true,
        "center": true,
        "padded": true,
        "title": "Invite your portfolio companies",
        "description": "Send invitations to the portfolio companies you want to onboard. You can invite more companies later in Manage Companies.",
      }, 
        React.createElement(LinkAccordion, {
          "class": "_a993a047",
          "title": "Invite with direct link",
          "active": props["linkAccordionActive"],
          "onClick": props["onClick"],
          "copyLink": props["copyLink"],
          "button": React.createElement(_pub2Dbb4493, {
            "class": "_1ba75cab _438f3407 _pub-438f3407 _pub-a97d53f3",
            "v3": true,
            "primary": props["copyLinkPrimary"],
            "onClick": props["copyLink"],
          }, 
            "\n          Copy link\n        "
            
          ),
        }, 
          props["link"]
        ),
        React.createElement(LinkAccordion, {
          "class": "_de9490d1",
          "title": "Send invitations to join your portfolio",
          "active": props["inviteListAccordianActive"],
          "onClick": props["inviteListOnClick"],
          "button": props["companiesTable"]&&React.createElement("div", {
            "className": "_9cd8f849 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            React.createElement(_pub2Dbb4493, {
              "class": "_a2f05a90 _438f3407 _pub-438f3407 _pub-a97d53f3",
              "v3": true,
              "transparent": true,
              "onClick": props["onClickPreviewInvites"],
            }, 
              "\n            Preview Invite\n          "
              
            ),
            React.createElement(_pub2Dbb4493, {
              "class": "_3bf90b2a _438f3407 _pub-438f3407 _pub-a97d53f3",
              "testId": "portfolio-monitoring:send-invitaations-button",
              "v3": true,
              "primary": props["sendInvitationsButtonStatus"],
              "disabled": !props["sendInvitationsButtonStatus"],
              "onClick": props["onClickSendInvites"],
            }, 
              "\n            Send Invitations\n          "
              
            )
          ),
        }, 
          React.createElement(TableContainer, {
            "class": "_2f06c132",
            "table": props["companiesTable"],
          }, null)
        )
      ),
      React.createElement(_pubD7465D49_BottomCtaBar, {
        "class": "_738c09e8 _438f3407 _pub-438f3407 _pub-a97d53f3",
        "bottom": true,
        "fullwidth": true,
      }, 
        React.createElement(ActionsBottomCTA, {
          "class": "_a851ca70",
          "bottomCtaPrimary": props["bottomCtaPrimary"],
          "cta": "Continue to Dashboard",
          "onClickPrimary": props["onClickDashboard"],
        }, null)
      )
    )
  )
}));
export { InvitePage };

var TableContainer = React.memo(React.forwardRef(function TableContainer(props, ref) {
  return React.createElement("div", {
    "className": "_679be3e3 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    !props["table"]&&React.createElement("div", {
      "className": "_8f618e83 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      "\n    There are no pre-loaded companies for you\n  "
      
    ),
    props["table"]
  )
}));
export { TableContainer };

var LinkAccordion = React.memo(React.forwardRef(function LinkAccordion(props, ref) {
  return React.createElement("div", {
    "className": "_8ef846d6 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pub2F5Be189, {
      "class": "_78f040ee _438f3407 _pub-438f3407 _pub-a97d53f3",
      "onClick": props["onClick"],
      "active": props["active"],
      "title": props["title"],
      "button": props["button"],
    }, 
      React.createElement("div", {
        "className": "_f10b9fbe _438f3407 _pub-438f3407 _pub-a97d53f3",
      }, 
        props["children"]
      )
    )
  )
}));
export { LinkAccordion };

var InviteCompanies = React.memo(React.forwardRef(function InviteCompanies(props, ref) {
  return React.createElement("div", {
    "className": "_60f627fa _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubBa0551B0_PageContent, {
      "class": "_c73a416 _438f3407 _pub-438f3407 _pub-a97d53f3",
      "fixedWidth": true,
      "center": true,
      "padded": true,
      "title": props["title"],
      "description": props["description"],
    }, 
      React.createElement(_pubA53A8Fae, {
        "class": "_8a09a6e9 _438f3407 _pub-438f3407 _pub-a97d53f3",
        "secondary": true,
      }, 
        React.createElement(_pubA53A8Fae_TableRow, {
          "class": "_f723bccd _438f3407 _pub-438f3407 _pub-a97d53f3",
          "header": true,
        }, 
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_fba9d93b _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            React.createElement(CheckedCell, {
              "class": "_8497065d",
            }, 
              "Company"
              
            )
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_8caee9ad _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Name"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_15a7b817 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Email"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_62a08881 _438f3407 _pub-438f3407 _pub-a97d53f3",
            "style": castStyle("width:20%; hegith:100%"),
          }, 
            "\n          Actions\n        "
            
          )
        ),
        React.createElement(_pubA53A8Fae_TableRow, {
          "class": "_80248c5b _438f3407 _pub-438f3407 _pub-a97d53f3",
        }, 
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_fa6bb30c _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            React.createElement(CheckedCell, {
              "class": "_b9f72fed",
            }, 
              "Company"
              
            )
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_8d6c839a _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Name"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_1465d220 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Email"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_6362e2b6 _438f3407 _pub-438f3407 _pub-a97d53f3",
            "style": castStyle("width:20%"),
          }, 
            React.createElement(_pub622F8858_MenuContainer, {
              "class": "_bbb191b4 _438f3407 _pub-438f3407 _pub-a97d53f3",
            }, 
              React.createElement(_pub622F8858_MenuButton, {
                "class": "_408b0b63 _438f3407 _pub-438f3407 _pub-a97d53f3",
              }, null)
            )
          )
        ),
        React.createElement(_pubA53A8Fae_TableRow, {
          "class": "_192ddde1 _438f3407 _pub-438f3407 _pub-a97d53f3",
        }, 
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_f82d0d55 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            React.createElement(CheckedCell, {
              "class": "_fe57553d",
            }, 
              "Company"
              
            )
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_8f2a3dc3 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Name"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_16236c79 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Email"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_61245cef _438f3407 _pub-438f3407 _pub-a97d53f3",
            "style": castStyle("width:20%"),
          }, 
            React.createElement(_pub622F8858_MenuContainer, {
              "class": "_fc11eb64 _438f3407 _pub-438f3407 _pub-a97d53f3",
            }, 
              React.createElement(_pub622F8858_MenuButton, {
                "class": "_716311fe _438f3407 _pub-438f3407 _pub-a97d53f3",
              }, null)
            )
          )
        ),
        React.createElement(_pubA53A8Fae_TableRow, {
          "class": "_6e2aed77 _438f3407 _pub-438f3407 _pub-a97d53f3",
        }, 
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_f9ef6762 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            React.createElement(CheckedCell, {
              "class": "_c3377c8d",
            }, 
              "Company"
              
            )
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_8ee857f4 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Name"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_17e1064e _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Email"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_60e636d8 _438f3407 _pub-438f3407 _pub-a97d53f3",
            "style": castStyle("width:20%"),
          }, 
            React.createElement(_pub622F8858_MenuContainer, {
              "class": "_c171c2d4 _438f3407 _pub-438f3407 _pub-a97d53f3",
            }, 
              React.createElement(_pub622F8858_MenuButton, {
                "class": "_d7141a4a _438f3407 _pub-438f3407 _pub-a97d53f3",
              }, null)
            )
          )
        ),
        React.createElement(_pubA53A8Fae_TableRow, {
          "class": "_f04e78d4 _438f3407 _pub-438f3407 _pub-a97d53f3",
        }, 
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_fca071e7 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            React.createElement(CheckedCell, {
              "class": "_7117a09d",
            }, 
              "Company"
              
            )
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_8ba74171 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Name"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_12ae10cb _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Email"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_65a9205d _438f3407 _pub-438f3407 _pub-a97d53f3",
            "style": castStyle("width:20%"),
          }, 
            React.createElement(_pub622F8858_MenuContainer, {
              "class": "_73511ec4 _438f3407 _pub-438f3407 _pub-a97d53f3",
            }, 
              React.createElement(_pub622F8858_MenuButton, {
                "class": "_12b324c4 _438f3407 _pub-438f3407 _pub-a97d53f3",
              }, null)
            )
          )
        ),
        React.createElement(_pubA53A8Fae_TableRow, {
          "class": "_87494842 _438f3407 _pub-438f3407 _pub-a97d53f3",
        }, 
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_fd621bd0 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            React.createElement(CheckedCell, {
              "class": "_4c77892d",
            }, 
              "Company"
              
            )
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_8a652b46 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Name"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_136c7afc _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Email"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_646b4a6a _438f3407 _pub-438f3407 _pub-a97d53f3",
            "style": castStyle("width:20%"),
          }, 
            React.createElement(_pub622F8858_MenuContainer, {
              "class": "_4e313774 _438f3407 _pub-438f3407 _pub-a97d53f3",
            }, 
              React.createElement(_pub622F8858_MenuButton, {
                "class": "_b4c42f70 _438f3407 _pub-438f3407 _pub-a97d53f3",
              }, null)
            )
          )
        ),
        React.createElement(_pubA53A8Fae_TableRow, {
          "class": "_1e4019f8 _438f3407 _pub-438f3407 _pub-a97d53f3",
        }, 
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_ff24a589 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            React.createElement(CheckedCell, {
              "class": "_bd7f3fd",
            }, 
              "Company"
              
            )
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_8823951f _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Name"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_112ac4a5 _438f3407 _pub-438f3407 _pub-a97d53f3",
          }, 
            "Contact Email"
            
          ),
          React.createElement(_pubA53A8Fae_TableCell, {
            "class": "_662df433 _438f3407 _pub-438f3407 _pub-a97d53f3",
            "style": castStyle("width:20%"),
          }, 
            React.createElement(_pub622F8858_MenuContainer, {
              "class": "_9914da4 _438f3407 _pub-438f3407 _pub-a97d53f3",
            }, 
              React.createElement(_pub622F8858_MenuButton, {
                "class": "_852c35ed _438f3407 _pub-438f3407 _pub-a97d53f3",
              }, null)
            )
          )
        )
      ),
      React.createElement(ActionsInviteCompanies, {
        "class": "_fd0e967f",
      }, null)
    ),
    React.createElement(_pubD7465D49_BottomCtaBar, {
      "class": "_7b749480 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      React.createElement(ActionsBottomCTA, {
        "class": "_8bcbccde",
        "skip": "I'll do this later",
        "cta": "Send 6 invitations",
      }, null)
    )
  )
}));
export { InviteCompanies };

var CounterContainer = React.memo(React.forwardRef(function CounterContainer(props, ref) {
  return React.createElement("div", {
    "className": "_874e0afd _438f3407 _pub-438f3407 _pub-a97d53f3" + (props["halfSize"] ? " " + "_438f3407_half-size _pub-438f3407_half-size _pub-a97d53f3_half-size half-size" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));

var PageContainer = React.memo(React.forwardRef(function PageContainer(props, ref) {
  return React.createElement("div", {
    "className": "_c6d41408 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { PageContainer };

var Header = React.memo(React.forwardRef(function Header(props, ref) {
  return React.createElement("div", {
    "className": "_28da7524 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_e1aa04e5 _438f3407 _pub-438f3407 _pub-a97d53f3" + " " + "_438f3407_text-heading-large _pub-438f3407_text-heading-large _pub-a97d53f3_text-heading-large text-heading-large",
    }, 
      props["title"]
    ),
    React.createElement("div", {
      "className": "_78a3555f _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["manageCompanies"]&&React.createElement(_pub2Dbb4493, {
        "class": "_d9b83848 _438f3407 _pub-438f3407 _pub-a97d53f3",
        "onClick": props["onClick"],
      }, 
        "Manage Companies"
        
      )
    )
  )
}));

var Detail = React.memo(React.forwardRef(function Detail(props, ref) {
  return React.createElement("div", {
    "className": "_c1b9d011 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_e5277857 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["title"]
    ),
    props["description"]&&React.createElement("div", {
      "className": "_2a1c6fcd _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["description"]
    )
  )
}));

var CountCompany = React.memo(React.forwardRef(function CountCompany(props, ref) {
  return React.createElement("div", {
    "className": "_2fb7b13d _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_e6a3ac39 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["counter"]
    ),
    React.createElement("div", {
      "className": "_7faafd83 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["text"]
    )
  )
}));
export { CountCompany };

var Separator = React.memo(React.forwardRef(function Separator(props, ref) {
  return React.createElement("div", {
    "className": "_c80f9c3a _438f3407 _pub-438f3407 _pub-a97d53f3" + " " + "_438f3407_separator _pub-438f3407_separator _pub-a97d53f3_separator separator",
    "ref": ref,
  }, null)
}));
export { Separator };

var TableBox = React.memo(React.forwardRef(function TableBox(props, ref) {
  return React.createElement("div", {
    "className": "_dfcf2549 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubA53A8Fae, {
      "class": "_2d958778 _438f3407 _pub-438f3407 _pub-a97d53f3",
      "secondary": true,
    }, 
      React.createElement(_pubA53A8Fae_TableRow, {
        "class": "_d310b054 _438f3407 _pub-438f3407 _pub-a97d53f3",
        "header": true,
      }, 
        React.createElement(_pubA53A8Fae_TableCell, {
          "class": "_529c8070 _438f3407 _pub-438f3407 _pub-a97d53f3",
        }, 
          React.createElement(FilteredCell, {
            "class": "_85add0f6",
          }, 
            "COMPANY"
            
          )
        ),
        React.createElement(_pubA53A8Fae_TableCell, {
          "class": "_259bb0e6 _438f3407 _pub-438f3407 _pub-a97d53f3",
        }, 
          React.createElement(FilteredCell, {
            "class": "_846fbac1",
            "reverse": true,
          }, 
            "LTM REVENUE"
            
          )
        ),
        React.createElement(_pubA53A8Fae_TableCell, {
          "class": "_bc92e15c _438f3407 _pub-438f3407 _pub-a97d53f3",
        }, 
          React.createElement(FilteredCell, {
            "class": "_86290498",
          }, 
            "CASH ON HAND"
            
          )
        ),
        React.createElement(_pubA53A8Fae_TableCell, {
          "class": "_cb95d1ca _438f3407 _pub-438f3407 _pub-a97d53f3",
        }, 
          React.createElement(FilteredCell, {
            "class": "_87eb6eaf",
          }, 
            "MONTHLY BURN"
            
          )
        )
      ),
      props["children"]
    )
  )
}));
export { TableBox };

var TableRowContent = React.memo(React.forwardRef(function TableRowContent(props, ref) {
  return React.createElement(_pubA53A8Fae_TableRow, {
    "class": "_31c14465 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(_pubA53A8Fae_TableCell, {
      "class": "_2e115316 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["companyName"]
    ),
    React.createElement(_pubA53A8Fae_TableCell, {
      "class": "_59166380 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["ltmRevenue"]
    ),
    React.createElement(_pubA53A8Fae_TableCell, {
      "class": "_c01f323a _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["cashOnHand"]
    ),
    React.createElement(_pubA53A8Fae_TableCell, {
      "class": "_b71802ac _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["lastMonthEBITDA"]
    )
  )
}));
export { TableRowContent };

var InviteBox = React.memo(React.forwardRef(function InviteBox(props, ref) {
  return React.createElement("div", {
    "className": "_d8a2e150 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(Detail, {
      "class": "_5d9b1f32",
      "title": props["title"],
      "description": props["description"],
    }, null),
    props["buttonText"]&&React.createElement(_pub2Dbb4493, {
      "class": "_177c467d _438f3407 _pub-438f3407 _pub-a97d53f3",
      "v3": true,
      "primary": props["primary"],
      "secondary": props["secondary"],
      "onClick": props["onClick"],
    }, 
      props["buttonText"]
    )
  )
}));
export { InviteBox };

var DashboardPortfolioInvestor = React.memo(React.forwardRef(function DashboardPortfolioInvestor(props, ref) {
  return React.createElement("div", {
    "className": "_36ac807c _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(PageContainer, {
      "class": "_2918fbca",
    }, 
      React.createElement(Header, {
        "class": "_5c5045f4",
        "title": props["title"],
        "manageCompanies": props["manageCompanies"],
        "onClick": props["onClick"],
      }, null),
      React.createElement(CounterContainer, {
        "class": "_2b577562",
        "halfSize": true,
      }, 
        React.createElement(CountCompany, {
          "class": "_308edf7d",
          "counter": props["connectedCompaniesCount"],
          "text": "Onboarded companies",
        }, null),
        React.createElement(Separator, {
          "class": "_4789efeb",
        }, null),
        React.createElement(CountCompany, {
          "class": "_de80be51",
          "counter": props["pendingCompaniesCount"],
          "text": "Invited companies",
        }, null)
      ),
      React.createElement("div", {
        "className": "_b25e24d8 _438f3407 _pub-438f3407 _pub-a97d53f3" + " " + "_438f3407_text-heading-medium _pub-438f3407_text-heading-medium _pub-a97d53f3_text-heading-medium text-heading-medium",
      }, 
        "\n      Portfolio companies\n    "
        
      ),
      props["children"]
    )
  )
}));
export { DashboardPortfolioInvestor };

var CompanyDetail = React.memo(React.forwardRef(function CompanyDetail(props, ref) {
  return React.createElement("div", {
    "className": "_d114ad7b _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(PageContainer, {
      "class": "_5481e656",
    }, 
      React.createElement(BackArrowWrapper, {
        "class": "_e2a291a2",
        "clickGoBack": props["clickGoBack"],
      }, 
        React.createElement("div", {
          "className": "_5c13e7a7 _438f3407 _pub-438f3407 _pub-a97d53f3" + " " + "_438f3407_text-heading-large _pub-438f3407_text-heading-large _pub-a97d53f3_text-heading-large text-heading-large",
        }, 
          props["title"]
        ),
        React.createElement(CounterContainer, {
          "class": "_2b14d731",
        }, 
          React.createElement(CountCompany, {
            "class": "_99c0136d",
            "counter": props["ltmRevenue"],
            "text": "LTM Revenue",
          }, null),
          React.createElement(Separator, {
            "class": "_eec723fb",
          }, null),
          React.createElement(CountCompany, {
            "class": "_77ce7241",
            "counter": props["cashOnHand"],
            "text": "Cash on Hand",
          }, null),
          React.createElement(Separator, {
            "class": "_c942d7",
          }, null),
          React.createElement(CountCompany, {
            "class": "_9eadd774",
            "counter": props["lastMonthEBITDA"],
            "text": "Last Month's EBITDA",
          }, null)
        )
      ),
      React.createElement("div", {
        "className": "_95a5a134 _438f3407 _pub-438f3407 _pub-a97d53f3",
      }, 
        props["children"]
      )
    )
  )
}));
export { CompanyDetail };

var WrapperData = React.memo(React.forwardRef(function WrapperData(props, ref) {
  return React.createElement("div", {
    "className": "_f4e2768a _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { WrapperData };

var WelcomePortfolioMonitoring = React.memo(React.forwardRef(function WelcomePortfolioMonitoring(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_1aec17a6 _438f3407 _pub-438f3407 _pub-a97d53f3" + (props["humDark"] ? " " + "_438f3407_hum _pub-438f3407_hum _pub-a97d53f3_hum hum" + " " + " " + " " + "_438f3407_dark _pub-438f3407_dark _pub-a97d53f3_dark dark" : ""),
    "ref": ref,
  }, 
    React.createElement(Onboarding, {
      "class": "_3ca4fcf8",
      "title": "Welcome, [Investor name]",
      "onClick": props["onClick"],
    }, null)
  )
}));
export { WelcomePortfolioMonitoring };

var RecommendationList = React.memo(React.forwardRef(function RecommendationList(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_f38fb293 _438f3407 _pub-438f3407 _pub-a97d53f3" + (props["humDark"] ? " " + "hum dark" : ""),
    "ref": ref,
  }, 
    React.createElement(InviteCompanies, {
      "class": "_3829804a",
      "title": "Invite companies",
      "description": "Send invitations to the portfolio companies you want to onboard",
    }, null)
  )
}));
export { RecommendationList };

var DashboardContainer = React.memo(React.forwardRef(function DashboardContainer(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_1d81d3bf _438f3407 _pub-438f3407 _pub-a97d53f3" + (props["humDark"] ? " " + "hum dark" : ""),
    "ref": ref,
  }, 
    React.createElement(DashboardPortfolioInvestor, {
      "class": "_3bad5424",
      "title": "Welcome, Investor Group",
      "manageCompanies": true,
      "connectedCompaniesCount": "2",
      "pendingCompaniesCount": "3",
    }, 
      React.createElement(InviteBox, {
        "class": "_dac4375a",
        "title": "Invite companies to your Capital portfolio",
        "description": "You aren’t monitoring any companies yet",
        "buttonText": "Invite companies",
        "primary": true,
        "onClick": props["onClick"],
      }, null)
    )
  )
}));
export { DashboardContainer };

var DashboardTable = React.memo(React.forwardRef(function DashboardTable(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_fa39feb8 _438f3407 _pub-438f3407 _pub-a97d53f3" + (props["humDark"] ? " " + "hum dark" : ""),
    "ref": ref,
  }, 
    React.createElement(DashboardPortfolioInvestor, {
      "class": "_3133792e",
      "title": "Welcome, Investor Group",
      "manageCompanies": true,
      "connectedCompanies": "2",
      "pendingCompaniesCount": "3",
    }, 
      React.createElement(TableBox, {
        "class": "_65f4893b",
      }, 
        React.createElement(TableRowContent, {
          "class": "_effcc9f4",
          "companyName": "Company Name",
          "ltmRevenue": "&#x24; 5M",
          "cashOnHand": "&#x24; 5M",
          "lastMonthEBITDA": "&#x24; 5M",
        }, null),
        React.createElement(TableRowContent, {
          "class": "_98fbf962",
          "companyName": "Company Name",
          "ltmRevenue": "&#x24; 5M",
          "cashOnHand": "&#x24; 5M",
          "lastMonthEBITDA": "&#x24; 5M",
        }, null),
        React.createElement(TableRowContent, {
          "class": "_1f2a8d8",
          "companyName": "Company Name",
          "ltmRevenue": "&#x24; 5M",
          "cashOnHand": "&#x24; 5M",
          "lastMonthEBITDA": "&#x24; 5M",
        }, null),
        React.createElement(TableRowContent, {
          "class": "_76f5984e",
          "companyName": "Company Name",
          "ltmRevenue": "&#x24; 5M",
          "cashOnHand": "&#x24; 5M",
          "lastMonthEBITDA": "&#x24; 5M",
        }, null),
        React.createElement(TableRowContent, {
          "class": "_e8910ded",
          "companyName": "Company Name",
          "ltmRevenue": "&#x24; 5M",
          "cashOnHand": "&#x24; 5M",
          "lastMonthEBITDA": "&#x24; 5M",
        }, null)
      )
    )
  )
}));
export { DashboardTable };

var ViewCompanyDetail = React.memo(React.forwardRef(function ViewCompanyDetail(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_edf947cb _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(CompanyDetail, {
      "class": "_879c4ff3",
      "title": "BlueBird",
      "ltmRevenue": "&#x24;5.7M",
      "cashOnHand": "&#x24;2.8M",
      "lastMonthEBITDA": "&#x24;-550K",
    }, null)
  )
}));
export { ViewCompanyDetail };

var BottomBarFixed = React.memo(React.forwardRef(function BottomBarFixed(props, ref) {
  return React.createElement("div", {
    "className": "_3f726e7 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
    "transparent": props["transparent"],
  }, 
    React.createElement("div", {
      "className": "_f31fab0b _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      React.createElement(_pub2Dbb4493, {
        "class": "_92dd189e _438f3407 _pub-438f3407 _pub-a97d53f3",
        "v3": true,
        "transparent": true,
        "onClick": props["copyLink"],
      }, 
        "Share link "
        ,
        props["buttonType"]
      ),
      React.createElement("div", {
        "className": "_bd44924 _438f3407 _pub-438f3407 _pub-a97d53f3",
      }, 
        React.createElement(_pub2Dbb4493, {
          "class": "_cec55b6c _438f3407 _pub-438f3407 _pub-a97d53f3",
          "v3": true,
          "transparent": true,
          "onClick": props["previewInvitation"],
        }, 
          "\n        Preview Invite\n      "
          
        ),
        React.createElement(_pub2Dbb4493, {
          "class": "_57cc0ad6 _438f3407 _pub-438f3407 _pub-a97d53f3",
          "v3": true,
          "primary": true,
          "onClick": props["addCompany"],
        }, 
          "Add Company"
          
        )
      )
    )
  )
}));
export { BottomBarFixed };

var ManageCompanies = React.memo(React.forwardRef(function ManageCompanies(props, ref) {
  return React.createElement("div", {
    "className": "_ea9483d2 _438f3407 _pub-438f3407 _pub-a97d53f3" + (props["primary"] ? " " + "_438f3407_primary _pub-438f3407_primary _pub-a97d53f3_primary primary" : "") + (props["transparent"] ? " " + "_438f3407_transparent _pub-438f3407_transparent _pub-a97d53f3_transparent transparent" : ""),
    "ref": ref,
  }, 
    React.createElement(PageContainer, {
      "class": "_8095e72f",
    }, 
      React.createElement(BackArrowWrapper, {
        "class": "_6b58b79f",
        "clickGoBack": props["clickGoBack"],
      }, 
        React.createElement("div", {
          "className": "_e4f6a31e _438f3407 _pub-438f3407 _pub-a97d53f3" + " " + "_438f3407_text-heading-large _pub-438f3407_text-heading-large _pub-a97d53f3_text-heading-large text-heading-large",
        }, 
          props["title"]
        ),
        React.createElement("div", {
          "className": "_93f19388 _438f3407 _pub-438f3407 _pub-a97d53f3",
        }, 
          props["children"]
        )
      ),
      React.createElement(BottomBarFixed, {
        "class": "_1c5f8709",
        "previewInvitation": props["previewInvitation"],
        "addCompany": props["addCompany"],
        "copyLink": props["copyLink"],
        "transparent": props["transparent"],
        "primary": props["primary"],
      }, null)
    )
  )
}));
export { ManageCompanies };

var __DEPRECATED__EmailTemplate = React.memo(React.forwardRef(function __DEPRECATED__EmailTemplate(props, ref) {
  return React.createElement("div", {
    "className": "_49ae2fe _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_f41603d7 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      React.createElement("img", {
        "className": "_675dbe5e _438f3407 _pub-438f3407 _pub-a97d53f3" + " " + "_438f3407_logo-img _pub-438f3407_logo-img _pub-a97d53f3_logo-img logo-img",
        "src": getDefault(require("@hum/assets/captec-logo-words.svg")),
      }, null),
      React.createElement("img", {
        "className": "_fe54efe4 _438f3407 _pub-438f3407 _pub-a97d53f3" + " " + "_438f3407_logo-img _pub-438f3407_logo-img _pub-a97d53f3_logo-img logo-img",
        "src": getDefault(require("@hum/assets/icons/logo-hum-primary.svg")),
      }, null)
    ),
    React.createElement("p", {
      "className": "_6d1f526d _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      props["investorName"],
      " from "
      ,
      props["investorFund"],
      " invited you to Hum Capital\n  "
      
    ),
    React.createElement("p", {
      "className": "_1a1862fb _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      "\n    Hum Capital allows you to easily track [Company Name]'s Performance and keep\n    [Investor Name], in the loop. Click below to set up your account.\n  "
      
    ),
    React.createElement("div", {
      "className": "_847cf758 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      React.createElement("img", {
        "className": "_61967cb5 _438f3407 _pub-438f3407 _pub-a97d53f3" + " " + "_438f3407_vector-img _pub-438f3407_vector-img _pub-a97d53f3_vector-img vector-img",
        "src": getDefault(require("./assets/vector.svg")),
      }, null)
    )
  )
}));

var EmailTemplate = React.memo(React.forwardRef(function EmailTemplate(props, ref) {
  return React.createElement("div", {
    "className": "_e322cff9 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_67817f67 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      React.createElement("img", {
        "className": "_da2bbe66 _438f3407 _pub-438f3407 _pub-a97d53f3" + " " + "_438f3407_logo-img _pub-438f3407_logo-img _pub-a97d53f3_logo-img logo-img",
        "src": getDefault(require("@hum/assets/logo-hum-indigo.svg")),
      }, null),
      React.createElement("div", {
        "className": "_4322efdc _438f3407 _pub-438f3407 _pub-a97d53f3",
      }, 
        "\n      [Investor Name] from [Investor Fund] invited you to Hum\n    "
        
      ),
      React.createElement("div", {
        "className": "_3425df4a _438f3407 _pub-438f3407 _pub-a97d53f3",
      }, 
        "\n      Hum allows you to easily track your company’s performance. Click below to\n      set up your account.\n    "
        
      )
    ),
    React.createElement("div", {
      "className": "_8ee2da52 _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      React.createElement("div", {
        "className": "_dea6c2d4 _438f3407 _pub-438f3407 _pub-a97d53f3",
      }, 
        React.createElement("img", {
          "className": "_a013350b _438f3407 _pub-438f3407 _pub-a97d53f3",
          "src": getDefault(require("@hum/assets/social-media-icons/linkedinSMI.svg")),
        }, null),
        React.createElement("img", {
          "className": "_391a64b1 _438f3407 _pub-438f3407 _pub-a97d53f3",
          "src": getDefault(require("@hum/assets/social-media-icons/twitterSMI.svg")),
        }, null)
      ),
      React.createElement("span", {
        "className": "_47af936e _438f3407 _pub-438f3407 _pub-a97d53f3",
      }, 
        "Hum Capital, 228 Park Ave. S PMB 44954 New York, New York 10003"
        
      )
    )
  )
}));

var Invitation = React.memo(React.forwardRef(function Invitation(props, ref) {
  return React.createElement("div", {
    "className": "_6a615b04 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(__DEPRECATED__EmailTemplate, {
      "class": "_a8f86f33",
    }, null),
    React.createElement(EmailTemplate, {
      "class": "_31f13e89",
    }, null),
    React.createElement("div", {
      "className": "_46f60e1f _438f3407 _pub-438f3407 _pub-a97d53f3",
    }, 
      React.createElement("p", {
        "className": "_1acf2d45 _438f3407 _pub-438f3407 _pub-a97d53f3",
      }, 
        "\n      When you invite a company to Hum Capital they will receive this message in\n      their inbox. You’ll be copied in the email.\n    "
        
      )
    )
  )
}));
export { Invitation };

var PreviewInvite = React.memo(React.forwardRef(function PreviewInvite(props, ref) {
  return React.createElement("div", {
    "className": "_846f3a28 _438f3407 _pub-438f3407 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement(PageContainer, {
      "class": "_dc7b8bcb",
    }, 
      React.createElement(BackArrowWrapper, {
        "class": "_154ef0ea",
        "clickGoBack": props["clickGoBack"],
        "testId": props["testId"],
      }, 
        React.createElement("div", {
          "className": "_5e068488 _438f3407 _pub-438f3407 _pub-a97d53f3" + " " + "_438f3407_text-heading-large _pub-438f3407_text-heading-large _pub-a97d53f3_text-heading-large text-heading-large",
        }, 
          props["title"]
        ),
        React.createElement(Invitation, {
          "class": "_2901b41e",
          "investorName": props["investorName"],
          "investorFund": props["investorFund"],
        }, null)
      )
    )
  )
}));
export { PreviewInvite };

var ViewPreviewCompanies = React.memo(React.forwardRef(function ViewPreviewCompanies(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_6d0c9f1d _438f3407 _pub-438f3407 _pub-a97d53f3" + (props["humDark"] ? " " + "hum dark" : ""),
    "ref": ref,
  }, 
    React.createElement(PreviewInvite, {
      "class": "_d8f6f779",
      "title": "Preview Invitation",
      "investorName": "Company One",
    }, null)
  )
}));
export { ViewPreviewCompanies };

var ViewManageCompanies = React.memo(React.forwardRef(function ViewManageCompanies(props, ref) {
  return React.createElement(_pub7Ab5Bb97_Container, {
    "class": "_64bad336 _438f3407 _pub-438f3407 _pub-a97d53f3" + (props["humDark"] ? " " + "hum dark" : ""),
    "ref": ref,
  }, 
    React.createElement(ManageCompanies, {
      "class": "_d1ec0e1d",
      "title": "Manage companies",
    }, 
      React.createElement(TableBox, {
        "class": "_5ffee84b",
      }, 
        React.createElement(TableRowContent, {
          "class": "_fa76dbc6",
          "companyName": "Company Name",
          "ltmRevenue": "&#x24; 5M",
          "cashOnHand": "&#x24; 5M",
          "lastMonthEBITDA": "&#x24; 5M",
        }, null),
        React.createElement(TableRowContent, {
          "class": "_8d71eb50",
          "companyName": "Company Name",
          "ltmRevenue": "&#x24; 5M",
          "cashOnHand": "&#x24; 5M",
          "lastMonthEBITDA": "&#x24; 5M",
        }, null),
        React.createElement(TableRowContent, {
          "class": "_1478baea",
          "companyName": "Company Name",
          "ltmRevenue": "&#x24; 5M",
          "cashOnHand": "&#x24; 5M",
          "lastMonthEBITDA": "&#x24; 5M",
        }, null),
        React.createElement(TableRowContent, {
          "class": "_637f8a7c",
          "companyName": "Company Name",
          "ltmRevenue": "&#x24; 5M",
          "cashOnHand": "&#x24; 5M",
          "lastMonthEBITDA": "&#x24; 5M",
        }, null),
        React.createElement(TableRowContent, {
          "class": "_fd1b1fdf",
          "companyName": "Company Name",
          "ltmRevenue": "&#x24; 5M",
          "cashOnHand": "&#x24; 5M",
          "lastMonthEBITDA": "&#x24; 5M",
        }, null)
      )
    )
  )
}));
export { ViewManageCompanies };


