import React from 'react';
import { HeaderWrapper, HeaderLeft, Content, Footer } from './index.pc';
import { OnboardingLayout as OnboardingLayoutStyles } from '@hum/icm-app/src/pages/onboarding-v5/styles/layout.pc';
import TopbarProfileMenu from '@hum/icm-app/src/components/TopbarProfileMenu';

export type Props = {
  headerTitle?: string;
  sideLayout?: boolean;
  children?: any;
  footer?: any;
  showAuthSection?: boolean;
};

export default function OnboardingLayout(
  props: React.PropsWithChildren<Props>
) {
  const { sideLayout, headerTitle, showAuthSection, footer, children } = props;

  return (
    <OnboardingLayoutStyles>
      <HeaderWrapper sideLayout={sideLayout}>
        <HeaderLeft>{headerTitle || ''}</HeaderLeft>
        <TopbarProfileMenu hideSettings showAuthSection={showAuthSection} />
      </HeaderWrapper>
      <Content sideLayout={sideLayout}>{children}</Content>
      <Footer sideLayout={sideLayout}>{footer}</Footer>
    </OnboardingLayoutStyles>
  );
}
