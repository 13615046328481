import React from 'react';
import ReactMarkdown from 'react-markdown';
import sc from 'styled-components';

import { useMNDA, useScrollHandler, useBlankMNDADownload } from './hooks';
import { Scrollable, Spinner } from '@hum/ui-library';
import { MNDAContent, MNDADisclaimer } from './index.pc';
import { FooterOnboarding } from '../FooterOnboarding';
import OnboardingLayout from '../OnboardingLayout';

export default function MNDAPage() {
  const { loaded, onAcceptClick, mnda } = useMNDA();

  const { contentRef, hasReachedBottom } = useScrollHandler();

  const downloadNDA = useBlankMNDADownload();

  return (
    <OnboardingLayout
      headerTitle="Review and sign your MNDA"
      footer={<FooterOnboarding />}
      showAuthSection
    >
      {!loaded && <Spinner fullScreen />}
      {loaded && (
        <>
          <MNDAContent
            onClick={onAcceptClick}
            disabled={!hasReachedBottom}
            downloadClick={downloadNDA}
            content={
              <div className="h-[calc(80vh-180px)]">
                <Scrollable ref={contentRef}>
                  <MNDAContentWrapper>
                    <ReactMarkdown
                      children={mnda}
                      includeElementIndex={true}
                      rawSourcePos={true}
                      skipHtml={false}
                      components={{
                        hr: 'br', // hack to bring spaces into our markdown files
                      }}
                    />
                  </MNDAContentWrapper>
                </Scrollable>
              </div>
            }
          />
          <MNDADisclaimer />
        </>
      )}
    </OnboardingLayout>
  );
}

/*
react-markdown automatically wraps every line in <p> tags making it diffcult to style.
Adding differnt header level markdown (###...) to different sections allows for targeting
specific elements for styling.
*/
const MNDAContentWrapper = sc.div`
  height: 100%;
  max-width: calc(100% - 16px);

  h1, h3 {
    margin-bottom: 8px;
  }

  h1 {
    font-weight: bold;
  }

  h3 {
    color: #7E899A;
  }

  h5 {
    padding-bottom: 12px;
  }

  h4 {
    font-weight: bold;
  }

  h4, p {
    display: inline;
  }

  @media (max-width: 830px) {
    font-size: 14px;
  }
`;
