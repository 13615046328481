import React from 'react';
import { useHistory } from 'react-router';
import ReactMarkdown from 'react-markdown';
import {
  REVENUE_MODEL,
  REVENUE_MODEL_V6,
  ApplicationEconomicTiers,
} from '@hum/types';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import {
  getUserCompanyId,
  Routes,
  SyndicationMatchActionStatus,
  SyndicationSectionDeals,
} from '@hum/icm-app/src/state';
import { client as backend } from '@hum/common/src/api/client';
import { useSyndicationMatch } from '@hum/icm-app/src/hooks/syndication';
import { syndicationInvestorV2MatchesHookUsed } from '@hum/icm-app/src/actions';
import { getLocation } from '@hum/icm-app/src/pages/syndication-v2/syndicationUtils';
import {
  BUSINESS_TYPES,
  EBITDA_BURN,
  EQUITY_RAISED,
  EXISTING_DEBT,
  MARGINS_VALUES,
  YEAR_OVER_YEAR_GROWTH_RATE,
} from '@hum/icm-app/src/components/constants';
import { toast } from '@hum/common/src/modules/toast';
import * as styles from '@hum/icm-app/src/pages/syndication-v2/company-detail.pc';
import * as styles_local from '@hum/icm-app/src/pages/settings/styles.pc';
import { Button, ButtonType, Spinner } from '@hum/ui-library';
import { money } from '@hum/icm-app/src/utils';

export const ArchiveCompanyDetailPage = () => {
  const history = useHistory();
  const { state, dispatch } = useAppStore();
  const companyId = getUserCompanyId(state);
  const uuid = state.history?.query.uuid;
  const notAvailable = 'N/A';
  const { data: matchData, loaded } = useSyndicationMatch({
    companyId,
    uuid,
  });
  const moveToLiveDeals = async () => {
    try {
      const path = `/companies/${companyId}/matches/${uuid}`;
      const response = await backend.put(path, {
        syndication_investor_action_status:
          SyndicationMatchActionStatus.Accepted,
      });
      dispatch(
        syndicationInvestorV2MatchesHookUsed({
          companyId,
          sectionDeals: SyndicationSectionDeals.archiveDeals,
        })
      );
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to update the match status');
    }
  };

  if (!companyId || !uuid || !loaded || !matchData) {
    return <Spinner fullScreen />;
  }

  return (
    <>
      <Topbar />
      <styles_local.BackButtonWrapper
        clickGoBack={() => {
          history.goBack();
        }}
      >
        <styles.CompanyDetails
          name={matchData.company.name}
          description={
            <ReactMarkdown
              children={matchData.company.publicDescription || ''}
            />
          }
          buttons={
            <Button
              testId="move-to-live-deals"
              type={ButtonType.SECONDARY}
              onClick={() => {
                moveToLiveDeals();
                history.push(`${Routes.SETTINGS_SYNDICATION_ARCHIVE}`);
              }}
            >
              Move to live deals
            </Button>
          }
          location={
            getLocation(matchData.company.state, matchData.company.country) ||
            notAvailable
          }
          industryVertical={
            BUSINESS_TYPES[matchData.company.application.industryVertical!] ||
            notAvailable
          }
          revenueModel={
            REVENUE_MODEL_V6[matchData.company.application.revenueModel] ||
            REVENUE_MODEL[matchData.company.application.revenueModel!] ||
            notAvailable
          }
          totalEquityRaised={
            EQUITY_RAISED[matchData.company.application.totalEquityRaised!] ||
            notAvailable
          }
        />
      </styles_local.BackButtonWrapper>
      <styles.FinancialInformation
        name="Key Financial Highlights"
        revenue={
          ApplicationEconomicTiers[
            matchData.company.application.yearlyRevenue!
          ] || notAvailable
        }
        growthRate={
          YEAR_OVER_YEAR_GROWTH_RATE[
            matchData.company.application.yearOverYearGrowthRate!
          ] || notAvailable
        }
        margins={
          MARGINS_VALUES[matchData.company.application.margins!] || notAvailable
        }
        burn={
          EBITDA_BURN[matchData.company.application.ebitdaBurn!] || notAvailable
        }
      />
      <styles.DealInformation
        name="Deal Information"
        existingDebt={
          EXISTING_DEBT[matchData.company.application.existingDebt!] ||
          notAvailable
        }
        targetDebt={
          money(matchData.company.application.investmentAmount!) || notAvailable
        }
        useOfFunds={matchData.company.application.useOfFunds || notAvailable}
      />
    </>
  );
};
