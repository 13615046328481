import React from 'react';
import memoize from 'fast-memoize';
import * as Yup from 'yup';
import { Dispatch } from 'redux';
import { toast } from '@hum/common/src/modules/toast';
import { Button, Spinner, Text, TextType } from '@hum/ui-library';
import { useField, useForm } from '@hum/common/src/modules/form';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { Company, CompanyType } from '@hum/types';
import { CompanySettings } from '@hum/icm-app/src/state';
import { SubdomainInput } from '@hum/icm-app/src/components/SubdomainInput';
import { TextInputField, Field } from '@hum/legacy-ui';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  subdomain: Yup.string().required('Required'),
});

export const SubdomainPage = () => {
  const {
    state: { currentCompany, currentCompanySettings },
    dispatch,
  } = useAppStore();

  // Disable this page for Hum Capital as a company 👀
  // mistakes were made
  if (currentCompany.data?.type.includes(CompanyType.Admin)) {
    return null;
  }

  return currentCompany.data && currentCompanySettings.data ? (
    <SubdomainContent
      company={currentCompany.data}
      settings={currentCompanySettings.data}
      dispatch={dispatch}
    />
  ) : (
    <Spinner fullScreen />
  );
};

type SubdomainContentProps = {
  company: Company;
  settings: CompanySettings;
  dispatch: Dispatch<any>;
};

const getInitialValues = memoize(
  (company: Company, settings: CompanySettings) => {
    return {
      companyName: company.name,
      subdomain: settings.subdomain,
    };
  }
);

const SubdomainContent = ({
  company,
  settings,
  dispatch,
}: SubdomainContentProps) => {
  const api = useAPI();
  const form = useForm({
    initialValues: getInitialValues(company, settings),
    validationSchema,
    async onSubmit({ subdomain, companyName }) {
      try {
        await Promise.all([
          api.legacy.updateCompanySettings(dispatch)(company.id, { subdomain }),
          api.legacy.updateCompany(company.id, { name: companyName }),
        ]);
        toast.success(`Saved`);
      } catch (e: any) {
        toast.error(e.message);
      }
    },
  });

  return (
    <div className="p-6 w-full mx-auto my-6 max-w-4xl">
      <form onSubmit={form.handleSubmit}>
        <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-4">
          <div>
            <Text type={TextType.BODY_M} className="mb-4">
              Company Name
            </Text>
            <Text type={TextType.BODY_S} subdued>
              The name of your company appears in email notifications, it will
              be used to communicate with companies and other investors.
            </Text>
          </div>
          <div>
            <TextInputField
              secondary
              inputTestId="companyName"
              {...useField('companyName', 'text', form).input}
            />
          </div>
          <div>
            <Text type={TextType.BODY_M} className="mb-4">
              Subdomain
            </Text>
            <Text type={TextType.BODY_S} subdued>
              This is a unique URL for your company, it allows your users and
              associates to easily bookmark and find your investor portal. If
              you need to change it, please contact support.
            </Text>
          </div>
          <div>
            <Field
              v3
              spaceBottom
              {...useField('subdomain', 'text', form).input}
            >
              {(props) => <SubdomainInput data-testid="subdomain" {...props} />}
            </Field>
            <Button
              submit
              loading={form.isSubmitting}
              testId="settings:portfolio:subdomain:button"
            >
              Save Changes
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
