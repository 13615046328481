import React from 'react';
import { Route, Switch } from 'react-router';
import { Routes } from '@hum/icm-app/src/state';

import * as baseStyles from '@hum/icm-app/src/pages/syndication-v2/base.pc';
import { PortfolioMonitoringDashboard } from '@hum/icm-app/src/pages/portfolio-monitoring/dashboard';
import { PortfolioMonitoringOnboarding } from '@hum/icm-app/src/pages/portfolio-monitoring/onboarding';
import { PortfolioMonitoringCompany } from '@hum/icm-app/src/pages/portfolio-monitoring/company-detail';
import { PortfolioMonitoringManageCompanies } from '@hum/icm-app/src/pages/portfolio-monitoring/manage-companies';
import { PortfolioMonitoringPreviewInvitation } from '@hum/icm-app/src/pages/portfolio-monitoring/preview-invitation';

export const PortfolioMonitoringPage = () => {
  return (
    <baseStyles.Container>
      <Switch>
        <Route path={Routes.PORTFOLIO_MONITORING_PREVIEW_INVITATION}>
          <PortfolioMonitoringPreviewInvitation />
        </Route>
        <Route path={Routes.PORTFOLIO_MONITORING_MANAGE_COMPANIES}>
          <PortfolioMonitoringManageCompanies />
        </Route>
        <Route path={Routes.PORTFOLIO_MONITORING_ONBOARDING}>
          <PortfolioMonitoringOnboarding />
        </Route>
        <Route path={Routes.PORTFOLIO_MONITORING_COMPANY}>
          <PortfolioMonitoringCompany />
        </Route>
        <Route path={Routes.PORTFOLIO_MONITORING}>
          <PortfolioMonitoringDashboard />
        </Route>
      </Switch>
    </baseStyles.Container>
  );
};
