import React, { useCallback } from 'react';
import { TextType, Text, Icon } from '@hum/ui-library';
import { useDropzone } from 'react-dropzone';
import mime from 'mime-types';
import { FileItem } from './FileItem';

export type DropZoneProps = {
  title: string;
  file?: File;
  onFileUpload?: (file: File) => void;
  onRemove: () => void;
  className?: string;
  testid?: string;
  extensions?: string[];
  label?: string;
};

export const DropZone = ({
  title,
  label,
  file,
  onFileUpload,
  onRemove,
  className,
  testid,
  extensions,
}: DropZoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFileUpload && onFileUpload(acceptedFiles[0]);
    },
    [file]
  );

  let allowedExtensions = label ? label : 'All formats accepted';
  if (extensions && !label) {
    if (extensions.length > 1) {
      allowedExtensions = `${extensions
        .slice(0, -1)
        .map((e) => e.toLocaleUpperCase())
        .join(', ')} or ${extensions
        .slice(-1)[0]
        .toLocaleUpperCase()} files only`;
    } else {
      allowedExtensions = `${extensions[0].toLocaleUpperCase()} files only`;
    }
  }

  const accept = extensions
    ? extensions
        .map((extension) => mime.types[extension])
        .reduce(
          (types, mimetype) => ({
            ...types,
            [mimetype]: mime.extensions[mimetype].map((t) => '.' + t),
          }),
          {}
        )
    : extensions;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    fileRejections,
    open,
  } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1,
  });

  const dragActive =
    isDragActive || isFocused
      ? 'bg-gray-600 border-gray-500'
      : 'bg-surface-subdued border-borderColor';

  return (
    <div className={className}>
      <div className="bg-surface-subdued rounded-default p-4">
        <div className="flex items-center mb-4">
          <Icon.Folder className="text-icon-subdued h-5 w-5" />
          <Text type={TextType.BODY_M} className="ml-[18px]">
            {title}
          </Text>
        </div>

        {file ? (
          <FileItem file={file} onRemove={onRemove} />
        ) : (
          <>
            <div
              data-testid={testid}
              {...getRootProps({
                className: `rounded-default border-2 border-dashed ${dragActive} px-5 py-8 text-center`,
              })}
            >
              <input {...getInputProps()} />
              <Text type={TextType.BODY_S} subdued className="mb-2">
                <a
                  role="button"
                  className="text-interactive-default"
                  onClick={open}
                >
                  Browse
                </a>{' '}
                or drop files here
              </Text>
              <Text type={TextType.LABEL} subdued className="mb-2">
                {allowedExtensions}
              </Text>
            </div>
            {fileRejections.length > 0 && (
              <Text
                type={TextType.BODY_S}
                destructive
                className="mt-4 break-words"
              >
                {fileRejections[0].errors[0].message}
              </Text>
            )}
          </>
        )}
      </div>
    </div>
  );
};
