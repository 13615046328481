import React from 'react';
import { Text, TextType, Icon, Badge, Spinner } from '@hum/ui-library';

export function InsightsCard({
  onDownload,
  isDownloadingFile,
}: {
  onDownload: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isDownloadingFile: boolean;
}) {
  return (
    <div className="grid lg:grid-cols-6 grid-cols-1 gap-4 p-8 my-8 border border-borderColor rounded-default">
      <div className="lg:col-span-5 grid-span-1">
        <div>
          <div className="flex">
            <Text type={TextType.HEADING_M}>Download your financial data</Text>
            <Badge className="ml-4 hidden lg:block">
              <Text type={TextType.LOZENGE} className="text-[#151C23]">
                NEW
              </Text>
            </Badge>
          </div>
        </div>
        <Text type={TextType.BODY_S} subdued className="mt-1">
          Review the analytics Hum provides to investors generated from your
          company’s financial and transactional data.
        </Text>
      </div>
      <div className="flex lg:justify-end justify-between items-start col-span-1">
        {isDownloadingFile ? (
          <div className="relative px-3 my-2">
            <Spinner />
          </div>
        ) : (
          <>
            <Badge className="lg:hidden block">
              <Text type={TextType.LOZENGE} className="text-[#151C23]">
                NEW
              </Text>
            </Badge>
            <Icon.Download onClick={onDownload} className="cursor-pointer" />
          </>
        )}
      </div>
    </div>
  );
}
