import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import {
  ConnectorOption,
  Connector,
  XeroOAuthConnectUrl,
  OAuthParameters,
} from '@hum/types';
import { useApiMutation, createConnector, createConnectUrl } from '@hum/api';
import { toast } from '@hum/common/src/modules/toast';

import { useAuthState } from '.';
import { ConnectorModalProps } from '../modals/ConnectorModal';

export type IUseOAuthConnector = {
  onConnect: (
    formFilled: boolean,
    parameters: Partial<OAuthParameters>
  ) => Promise<void>;
  modalProps: Omit<ConnectorModalProps, 'type'>;
  connection: Connector | null;
  isConnectionError: boolean;
};

export type UseOAuthConnectorArgs = {
  connector: ConnectorOption | null;
};

export const useOAuthConnector = ({
  connector,
}: UseOAuthConnectorArgs): IUseOAuthConnector => {
  const location = useLocation();
  const history = useHistory();

  const {
    setOauthConfigId,
    setOauthUrl,
    setUserParams,
    setCompanyId,
    oauthConfigId,
    oauthUrl,
    clearOauthState,
    isConnecting,
    userParams,
    companyId,
  } = useAuthState();

  const callbackUrl = `${window.location.origin}${location.pathname || ''}`;
  const queryParams = qs.parse(location.search);

  const {
    mutateAsync: connect,
    isError: isConnectionError,
    data: connection = null,
    reset: resetConnect,
  } = useApiMutation(createConnector, {
    onError: (e: any) => {
      resetConnect();
      toast.error(e.message);
    },
  });

  const { mutateAsync: createConnectionUrl, reset: resetUrl } = useApiMutation(
    createConnectUrl,
    {
      onError: (e: any) => {
        resetUrl();
        toast.error(e.message);
      },
    }
  );

  const onConnect = useCallback(
    async (formFilled, parameters) => {
      if (!connector?.config || !formFilled) {
        return;
      }

      const data = await createConnectionUrl({
        connectorConfigId: connector.config.id,
        parameters: {
          ...parameters,
          callbackUrl,
        } as XeroOAuthConnectUrl,
      });

      if (data) {
        setOauthUrl(data.connectUrl);
        setOauthConfigId(connector.config.id);
        setUserParams(parameters);
        const companyId = queryParams.company_id as string;
        if (companyId) {
          setCompanyId(parseInt(companyId, 10));
        }

        window.open(data.connectUrl, '_self');
      }
    },
    [connector]
  );

  useEffect(() => {
    // TODO: See if this is needed for direct connectors as well
    if (oauthConfigId && 'code' in queryParams) {
      connect({
        connectorConfigId: oauthConfigId,
        parameters: {
          ...(userParams as XeroOAuthConnectUrl),
          state: queryParams.state,
          code: queryParams.code,
          callbackUrl,
          url: oauthUrl,
        },
      });
      clearOauthState({ isConnecting });
      // strip '/connect' from URL so we go back to the connector listing
      const newQueryParams = {
        company_id: companyId,
        ...queryParams,
      };
      history.replace(`${location.pathname}?${qs.stringify(newQueryParams)}`);
    }
  }, [oauthConfigId, queryParams]);

  return {
    onConnect,
    modalProps: {
      title:
        'Connect your ' +
        (connector?.config.label ? connector?.config.label + ' ' : '') +
        'account',
      description:
        'To authorize your account, please enter your credentials below',
      isOpen: true,
      testId: 'cannot-connect-modal',
    },
    isConnectionError,
    connection,
  };
};
