import React from "react";
import _pubF29676E3 from "../../../../../design-system/src/components.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../design-system/src/utils.pc";
import _pubA97D53F3 from "../../../../../design-system/src/atoms.pc";
import _pub2Dbb4493 from "../../../../../design-system/src/Button.pc";
import _pub8C547Ae7 from "../../../../../design-system/src/Select.pc";
import _pubC68937F5 from "../../../../../design-system/src/Scrollable.pc";
import _pubA53A8Fae from "../../../../../design-system/src/Table.pc";
import _pub2F51C399 from "../../../../../design-system/src/breakpoints.pc";
import _pub1Fe26F90 from "../../../components/CompanyDocuments/index.pc";
import "./layout.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var SlidePageItem = React.memo(React.forwardRef(function SlidePageItem(props, ref) {
  return React.createElement("div", {
    "className": "_d3d40567 _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { SlidePageItem };

var SlidePage = React.memo(React.forwardRef(function SlidePage(props, ref) {
  return React.createElement("div", {
    "className": "_3dda644b _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
    "data-pc-label": "SlidePage",
    "style": castStyle("--index:" + props["index"]),
  }, 
    React.createElement("div", {
      "className": "_697f467f _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399",
    }, 
      props["children"]
    )
  )
}));
export { SlidePage };

var OnboardingSideInfo = React.memo(React.forwardRef(function OnboardingSideInfo(props, ref) {
  return React.createElement("div", {
    "className": "_d4b9c17e _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_6df23acd _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399",
    }, 
      React.createElement("div", {
        "className": "_ff7265ff _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399",
      }, 
        props["title"]
      ),
      props["description"]
    )
  )
}));

var OnboardingLayout = React.memo(React.forwardRef(function OnboardingLayout(props, ref) {
  return React.createElement("div", {
    "className": "_3ab7a052 _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399" + " " + "_972e797_hum _pub-972e797_hum _pub-a97d53f3_hum _pub-2f51c399_hum hum _972e797_dark _pub-972e797_dark _pub-a97d53f3_dark _pub-2f51c399_dark dark _972e797_v3 _pub-972e797_v3 _pub-a97d53f3_v3 _pub-2f51c399_v3 v3",
    "ref": ref,
    "data-pc-label": "Onboarding layout",
    "data-testid": props["testId"],
  }, 
    props["children"]
  )
}));
export { OnboardingLayout };

var OnboardingSide = React.memo(React.forwardRef(function OnboardingSide(props, ref) {
  return React.createElement("div", {
    "className": "_dd0f8d55 _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_64e8c3a9 _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399",
      "data-pc-label": "header",
    }, 
      React.createElement("img", {
        "className": "_d48ce9d2 _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399",
        "src": getDefault(require("@hum/figma/components/capital-logo/sizelarge-color-white@1.svg")),
      }, null),
      React.createElement("img", {
        "className": "_a38bd944 _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399",
        "src": getDefault(require("@hum/figma/components/logo/capital@1.svg")),
      }, null)
    ),
    React.createElement("div", {
      "className": "_fde19213 _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399",
      "data-pc-label": "outer",
    }, 
      React.createElement("div", {
        "className": "_4fc30631 _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399",
        "data-pc-label": "inner",
      }, 
        React.createElement(OnboardingSideInfo, {
          "class": "_1f13a56e",
          "title": props["title"],
          "description": props["subtitle"],
        }, null)
      ),
      props["footer"]
    )
  )
}));
export { OnboardingSide };

var OnboardingContent = React.memo(React.forwardRef(function OnboardingContent(props, ref) {
  return React.createElement("div", {
    "className": "_f8f956a4 _972e797 _pub-972e797 _pub-a97d53f3 _pub-2f51c399" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["centered"] ? " " + "_972e797_centered _pub-972e797_centered _pub-a97d53f3_centered _pub-2f51c399_centered centered" : ""),
    "ref": ref,
    "data-pc-label": "Onboarding content",
  }, 
    props["children"]
  )
}));
export { OnboardingContent };


