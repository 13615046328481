import React, {
  cloneElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as styles from './index.pc';

export type TooltipProps = {
  children: ReactElement;
  content: any;
};

export const Tooltip = ({ children, content }: TooltipProps) => {
  const {
    onMouseEnter,
    visible,
    onMouseLeave,
    childRef,
    tooltipStyle,
  } = useTooltip();

  const child = cloneElement(children, {
    ref: childRef,
  });

  return (
    <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {child}
      <div style={tooltipStyle}>
        <styles.Tooltip visible={visible} bottom>
          {content}
        </styles.Tooltip>
      </div>
    </span>
  );
};

const TRIGGER_TIMEOUT = 400;

const useTooltip = () => {
  const [childRect, setChildRect] = useState<ClientRect | undefined>();
  const [visible, setVisible] = useState(false);
  const childRef = useRef<HTMLElement>();
  const [mouseOver, setMouseOver] = useState(false);

  const onMouseEnter = useCallback(() => {
    setMouseOver(true);
  }, [childRef]);

  const onMouseLeave = useCallback(() => {
    setMouseOver(false);
    setVisible(false);
  }, [childRef]);

  // some delay after user hovers over element
  useEffect(() => {
    if (mouseOver && childRef) {
      const timer = setTimeout(() => {
        setChildRect(childRef.current?.getBoundingClientRect());
        setVisible(true);
      }, TRIGGER_TIMEOUT);

      return () => clearTimeout(timer);
    }
  }, [mouseOver]);

  const tooltipStyle = useMemo(
    () =>
      (childRect && {
        top: childRect.top + childRect.height,
        left: childRect.left + childRect.width / 2,
        zIndex: 999,
        position: 'fixed',
      }) || {
        position: 'fixed',
      },
    [childRect]
  ) as any;

  // close on wheel
  useEffect(() => {
    if (!visible) {
      return;
    }

    const onDocumentInteraction = () => {
      setVisible(false);
    };

    document.addEventListener('wheel', onDocumentInteraction);

    return () => {
      document.removeEventListener('wheel', onDocumentInteraction);
    };
  }, [visible]);

  return {
    onMouseEnter,
    visible,
    onMouseLeave,
    childRef,
    tooltipStyle,
    childRect,
  };
};
