import { OverviewActions } from '@hum/icm-app/src/pages/onboarding-v5/components/Overview/ducks/actions';
import { MNDAActions } from '@hum/icm-app/src/pages/onboarding-v5/components/MNDA/ducks/actions';
import { MetricsActions } from '@hum/icm-app/src/pages/onboarding-v5/metrics/actions';
import { OtherSoftwareActions } from '@hum/icm-app/src/pages/onboarding-v5/components/OnboardingConnectStep_DeprecateAfterM2_5/OtherSoftware/ducks/actions';
import { metricsReducers } from '@hum/icm-app/src/pages/onboarding-v5/metrics/reducers';
import { otherSoftwareReducers } from '@hum/icm-app/src/pages/onboarding-v5/components/OnboardingConnectStep_DeprecateAfterM2_5/OtherSoftware/ducks/reducers';
import { AppState } from '@hum/icm-app/src/state';

export default function OnboardingV5Reducers(
  state: AppState,
  action: MNDAActions | OverviewActions | MetricsActions | OtherSoftwareActions
) {
  return {
    ...state,
    ...metricsReducers(state, action as MetricsActions),
    ...otherSoftwareReducers(state, action as OtherSoftwareActions),
  };
}
