// Libraries
import React, { useEffect, useState } from 'react';
import { Button } from '@hum/ui-library';
import { InvitedCompanies } from '@hum/icm-app/src/pages/admin/tabs/InviteCompanies/Companies';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import * as styles from './styles.pc';
import { useAnalyticsEvent } from '@hum/api';

export const Invites = () => {
  const {
    state: { session },
  } = useAppStore();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const onInviteModalClose = () => {
    setInviteModalOpen(false);
  };

  const trackEvent = useAnalyticsEvent();

  useEffect(() => {
    trackEvent('invite_portfolio_page:view', {}, true);
  }, []);

  return (
    <>
      <styles.ListHeader title="Select companies to invite to TCM">
        <Button
          testId="portfolio:add-company-button"
          onClick={() => setInviteModalOpen(true)}
        >
          Add company
        </Button>
      </styles.ListHeader>

      <div style={{ marginLeft: 24, marginRight: 24, marginTop: 12 }}>
        <InvitedCompanies
          companyId={session.data?.company?.id!}
          inviteModalOpen={inviteModalOpen}
          onInviteModalClose={onInviteModalClose}
        />
      </div>
    </>
  );
};
