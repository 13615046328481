import * as format from '@hum/icm-app/src/utils/format';

import React, { useEffect, useState } from 'react';

import { Company } from '@hum/types';
import { FundingProfileInfo } from '@hum/icm-app/src/pages/settings/styles.pc';
import { UpdateFundingProfile } from './UpdateFundingProfile';
import { useCurrentCompany } from '@hum/icm-app/src/hooks/useCurrentCompany';
import { useCompanyUsers } from '@hum/icm-app/src/hooks/useCompanyUsers';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { getCompanyUsers, useApiQuery } from '@hum/api';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

export const FundingProfile = () => {
  const flags = useFlags();
  const { data: company } = useCurrentCompany();
  const [showFundingProfileModal, setShowFundingProfileModal] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<Company>();
  const companyId = company?.id!;
  const { data: companyUsers } = useApiQuery(getCompanyUsers, {
    params: { companyId },
    enabled: flags.enabled('use-react-query'),
  });
  const { data: sagaUsers } = useCompanyUsers(companyId);
  const users = flags.enabled('use-react-query') ? companyUsers : sagaUsers;
  const {
    state: { session },
  } = useAppStore();

  const primaryContact = users?.find((user) => user.isPrimaryContact);
  const isPrimaryContact = primaryContact?.id === session.data?.id;

  const onCloseFundingProfileModal = () => {
    setShowFundingProfileModal(false);
  };

  useEffect(() => {
    if (company) {
      setCurrentCompany(company);
    }
  }, [company]);

  return (
    <>
      <FundingProfileInfo
        dataTestid="user-settings:funding-profile-info"
        isPrimaryContact={isPrimaryContact}
        onClick={() => {
          setShowFundingProfileModal(true);
        }}
        description={company?.description || 'N/A'}
        location={company?.syndicationInvestor?.location || 'N/A'}
        website={company?.website || 'N/A'}
        investmentRanges={
          company?.syndicationInvestor?.investmentRanges
            .map((item) => item.label)
            .join(', ') || 'N/A'
        }
        investmentTypes={
          company?.syndicationInvestor?.investmentTypes
            .map((item) => item.label)
            .join(', ') || 'N/A'
        }
        targetYearlyMinRevenue={
          format.money(
            company?.syndicationInvestor?.investmentYearlyRevenueMin
          ) || 'N/A'
        }
        targetYearlyMaxRevenue={
          format.money(
            company?.syndicationInvestor?.investmentYearlyRevenueMax
          ) || 'N/A'
        }
        targetBusinessTypes={
          company?.syndicationInvestor?.investmentBusinessTypes
            .map((item) => item.label)
            .join(', ') || 'N/A'
        }
      />
      <UpdateFundingProfile
        isOpen={showFundingProfileModal}
        onClose={onCloseFundingProfileModal}
        currentCompany={currentCompany}
      />
    </>
  );
};
