import React from "react";
import _pubA97D53F3 from "../../../../../design-system/src/atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../design-system/src/utils.pc";
import _pub8Ec1B7A, {LinkButton as _pub8Ec1B7A_LinkButton,ActionButton as _pub8Ec1B7A_ActionButton} from "./common.pc";
import _pub972E797, {OnboardingLayout as _pub972E797_OnboardingLayout,OnboardingContent as _pub972E797_OnboardingContent} from "./layout.pc";
import _pub7D0904Ad from "../../../../../design-system/src/Help.pc";
import "./splash.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var Spacer = React.memo(React.forwardRef(function Spacer(props, ref) {
  return React.createElement("div", {
    "className": "_3e6898ac _5079941c _pub-5079941c",
    "ref": ref,
  }, null)
}));

var $$Default = React.memo(React.forwardRef(function $$Default(props, ref) {
  return React.createElement("div", {
    "className": "_d9d0b5ab _5079941c _pub-5079941c",
    "ref": ref,
    "data-pc-label": "Splash",
  }, 
    React.createElement(Spacer, {
      "class": "_c6d5b3db",
    }, null),
    React.createElement("header", {
      "className": "_5fdce261 _5079941c _pub-5079941c",
    }, 
      props["graphic"],
      React.createElement("div", {
        "className": "_9bc69ffe _5079941c _pub-5079941c",
      }, 
        props["title"]
      ),
      React.createElement("div", {
        "className": "_ecc1af68 _5079941c _pub-5079941c",
        "data-pc-label": "Subtitle",
      }, 
        props["subtitle"]
      )
    ),
    React.createElement(Spacer, {
      "class": "_28dbd2f7",
    }, null),
    React.createElement("footer", {
      "className": "_b6bf4754 _5079941c _pub-5079941c",
    }, 
      props["footer"]
    )
  )
}));
export default $$Default;


