import { takeEvery, call } from 'redux-saga/effects';
import { OtherSoftwareActionType, OtherSoftwareOptionSaved } from './actions';
import { trackActions } from '@hum/icm-app/src/modules/analytics';
import { requestForConnectors } from '@hum/icm-app/src/sagas/utils/model';

// See event details and documentation here:
// https://www.notion.so/capitaltechnology/3e79df34b12845fc82314b95f3191a4c?v=ec610dbdd9fc4940b8c375083775452e

export function* handleOtherSoftware() {
  yield takeEvery(
    OtherSoftwareActionType.OTHER_SOFTWARE_OPTION_SAVED,
    saveOtherOption
  );
  yield trackActions([
    {
      actionType: OtherSoftwareActionType.OTHER_SOFTWARE_OPTION_SAVED,
      track: 'company-select-other-software',
    },
  ]);
}

function* saveOtherOption({ payload }: OtherSoftwareOptionSaved) {
  try {
    yield call(requestForConnectors, payload.companyId, [payload.software]);
  } catch (e) {
    return;
  }
}
