import React from 'react';
import { Switch, Route } from 'react-router';

import { Routes } from '@hum/icm-app/src/state';

import { FinalPage } from './components/FinalPage';
import MNDA from './components/MNDA';
import { Overview } from './components/Overview';
import { OnboardingConnectStep } from './components/OnboardingConnectStep';
import { UploadFiles } from './components/UploadFiles';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';

export const OnboardingV5 = () => {
  const flags = useFlags();

  return (
    <Switch>
      {/* Onboarding / Overview */}
      <Route path={Routes.ONBOARDING_V5_OVERVIEW}>
        <Overview />
      </Route>

      {/* MNDA */}
      <Route path={Routes.ONBOARDING_V5_MNDA}>
        <MNDA />
      </Route>

      {/* Connect services or upload files (in v5) */}
      {flags.ready && (
        <Route path={Routes.ONBOARDING_V5_CONNECT}>
          <OnboardingConnectStep isOnboarding />
        </Route>
      )}

      {/* Upload files (in HL M2.5) */}
      <Route path={Routes.ONBOARDING_V5_UPLOAD_FILES}>
        <UploadFiles />
      </Route>

      {/* Finished */}
      <Route path={Routes.ONBOARDING_V5_COMPLETED}>
        <FinalPage />
      </Route>
    </Switch>
  );
};
