import React from 'react';
import { NavLink, useParams, Redirect } from 'react-router-dom';
import { CompanyType } from '@hum/types';
import { getUserCompanyId, CompanyCharTabName } from '@hum/icm-app/src/state';
import { Routes } from '@hum/icm-app/src/state/routes';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { getFundingOpportunities, useApiQuery, useSessionUser } from '@hum/api';
import { useSyndicationInsightsCharts } from '@hum/icm-app/src/hooks/useSyndicationInsightsCharts';
import { CompanyFinancialAnalysisView } from './CompanyFinancialAnalysisView';
import {
  Spinner,
  SidebarNav,
  Splash,
  Icon,
  TextType,
  Text,
} from '@hum/ui-library';

export enum SIDEBAR_ITEMS {
  COMPANY_FINANCIAL_ANALYSIS = 'Financial Analysis',
  COMPANY_REVENUE_DETAILS = 'Revenue Details',
  COMPANY_COHORTS_ANALYSIS = 'Cohorts Analysis',
}

type MenuItem = {
  title: string;
  component: (companyId: number) => React.ReactElement;
  requires: CompanyType[];
};

const menuOptions: {
  [key: string]: MenuItem;
} = {
  [CompanyCharTabName.financialAnalysis]: {
    title: SIDEBAR_ITEMS.COMPANY_FINANCIAL_ANALYSIS,
    component: (companyId) => (
      <CompanyFinancialAnalysisView
        key={SIDEBAR_ITEMS.COMPANY_FINANCIAL_ANALYSIS}
        companyId={companyId}
        withCard
      />
    ),
    requires: [CompanyType.Company, CompanyType.Admin],
  },
  [CompanyCharTabName.customerAnalysis]: {
    title: SIDEBAR_ITEMS.COMPANY_REVENUE_DETAILS,
    component: (companyId) => (
      <CompanyFinancialAnalysisView
        key={SIDEBAR_ITEMS.COMPANY_REVENUE_DETAILS}
        companyId={companyId}
      />
    ),
    requires: [CompanyType.Company, CompanyType.Admin],
  },
  [CompanyCharTabName.cohortAnalysis]: {
    title: SIDEBAR_ITEMS.COMPANY_COHORTS_ANALYSIS,
    component: (companyId) => (
      <CompanyFinancialAnalysisView
        key={SIDEBAR_ITEMS.COMPANY_COHORTS_ANALYSIS}
        companyId={companyId}
      />
    ),
    requires: [CompanyType.Company, CompanyType.Admin],
  },
};

const NavSidebar = ({ items }: { items: typeof menuOptions }) => {
  const session = useSessionUser();
  const currentUserRoles = session.data?.roles || [];

  return (
    <SidebarNav fixedBar>
      {Object.entries(items).map(([chartName, menuItem]) => {
        if (menuItem.requires.some((role) => currentUserRoles.includes(role))) {
          return (
            <NavLink
              key={chartName}
              to={`${Routes.INSIGHTS}/${chartName}${window.location.search}`}
              activeClassName="bg-onSurface-pressed"
              className="hover:no-underline"
            >
              <SidebarNav.Item testId={`company:${chartName}`}>
                <Text type={TextType.UI_ELEMENT}>{menuItem.title}</Text>
              </SidebarNav.Item>
            </NavLink>
          );
        }
      })}
    </SidebarNav>
  );
};

// TODO figure out a way to call useSyndicationInsightsCharts once, because currently its being called multiple times in this flow
const CompanyInsightsContent = () => {
  const { state } = useAppStore();
  const { isLoading } = useApiQuery(getFundingOpportunities, {
    toastsEnabled: false,
  });
  const companyId = getUserCompanyId(state);
  const { chartTabName } = useParams<{ chartTabName?: string }>(); // @Erik @Ridwan @Rory pull the chart tab name from the URL

  if (!chartTabName) {
    return (
      <Redirect
        to={`${Routes.INSIGHTS_FINANCIAL_ANALYSIS}${window.location.search}`}
      />
    );
  }
  const chartsContent = useSyndicationInsightsCharts(companyId);

  if (isLoading) return <Spinner fullScreen />;

  return (
    <>
      <NavSidebar items={menuOptions} />
      {chartsContent.data ? (
        <SidebarNav.Content>
          {chartTabName ? menuOptions[chartTabName].component(companyId) : null}
        </SidebarNav.Content>
      ) : (
        <SidebarNav.Content>
          <div className="flex h-[calc(100vh-48px)]">
            <div className="flex flex-col items-center justify-around w-full">
              <Splash
                graphic={
                  <Icon.InvestorSignupStepThree className="max-w-[200px] mx-auto" />
                }
                title="Connect data to see insights"
                subtitle="Connect your accounting software in the Data Sharing tab or upload Income Statement and Balance sheet data in your data room. Some additional insights are also available when you connect to your bank and/or payment processor data as well."
              >
                <Text
                  type={TextType.BODY_S}
                  subdued
                  className="max-w-md mx-auto"
                >
                  Note: Insights can take some time to generate once your data
                  is connected, thank you for your patience
                </Text>
              </Splash>
            </div>
          </div>
        </SidebarNav.Content>
      )}
    </>
  );
};

export const CompanyInsights = () => {
  return (
    <>
      <Topbar fixedBar />
      <CompanyInsightsContent />
    </>
  );
};
