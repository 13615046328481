import React from "react";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../../design-system/src/utils.pc";
import _pubE434Fb6A from "../../../../../../design-system/src/Card.pc";
import _pub2Dbb4493 from "../../../../../../design-system/src/Button.pc";
import _pubFa41B678, {Icon as _pubFa41B678_Icon} from "../../../../../../design-system/src/Icon.pc";
import _pubA97D53F3 from "../../../../../../design-system/src/atoms.pc";
import _pub2F51C399 from "../../../../../../design-system/src/breakpoints.pc";
import "./index.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var MNDAContent = React.memo(React.forwardRef(function MNDAContent(props, ref) {
  return React.createElement("main", {
    "className": "_f07c20f1 _1353428 _pub-1353428 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_6e44862 _1353428 _pub-1353428 _pub-a97d53f3",
    }, 
      React.createElement("span", {
        "className": "_1fe18e49 _1353428 _pub-1353428 _pub-a97d53f3",
      }, 
        "\n      Please review and sign to continue\n    "
        
      ),
      React.createElement("span", {
        "className": "_86e8dff3 _1353428 _pub-1353428 _pub-a97d53f3",
        "onClick": props["downloadClick"],
      }, 
        React.createElement(_pubFa41B678_Icon, {
          "class": "_6ed7836f _1353428 _pub-1353428 _pub-a97d53f3",
          "masked": true,
          "src": "@hum/assets/feather/download-cloud.svg",
          "size": "24px",
        }, null)
      )
    ),
    React.createElement(_pubE434Fb6A, {
      "class": "_9fed19d8 _1353428 _pub-1353428 _pub-a97d53f3",
      "data-testid": "company-onboarding:mnda-node",
    }, 
      props["content"]
    ),
    React.createElement(_pub2Dbb4493, {
      "class": "_e8ea294e _1353428 _pub-1353428 _pub-a97d53f3",
      "type": "button",
      "primary": true,
      "v3": true,
      "wide": true,
      "loading": false,
      "data-testid": "onboarding:nda-close-button",
      "onClick": props["onClick"],
      "disabled": props["disabled"],
    }, 
      "\n    I accept and agree\n  "
      
    )
  )
}));
export { MNDAContent };

var MNDADisclaimer = React.memo(React.forwardRef(function MNDADisclaimer(props, ref) {
  return React.createElement("div", {
    "className": "_17c40df6 _1353428 _pub-1353428 _pub-a97d53f3",
    "ref": ref,
  }, 
    React.createElement("p", {
      "className": "_c7a6568 _1353428 _pub-1353428 _pub-a97d53f3",
    }, 
      "Any questions about our MNDA?"
      
    ),
    React.createElement("p", {
      "className": "_957334d2 _1353428 _pub-1353428 _pub-a97d53f3",
    }, 
      "\n    We're happy to help. Fill out our "
      ,
      React.createElement("a", {
        "className": "_a2978e71 _1353428 _pub-1353428 _pub-a97d53f3",
        "href": "https://info.humcapital.com/hum-help-center/kb-tickets/new",
        "target": "_blank",
      }, 
        "\n      support form\n    "
        
      ),
      " and we'll reach out as soon as possible.\n  "
      
    )
  )
}));
export { MNDADisclaimer };


