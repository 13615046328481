import * as styles from '@hum/icm-app/src/pages/portfolio-monitoring/styles.pc';

import {
  NoticeName,
  Routes,
  PortfolioCompanies,
  PortfolioMonitoringInvite,
  getUserCompanyId,
} from '@hum/icm-app/src/state';
import { Result } from '@hum/common/src/ducks/state';
import React, { useEffect, useState } from 'react';
import { Table, TableCellConfig } from '@hum/legacy-ui';
import { Spinner } from '@hum/ui-library';

import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { money as formatValue } from '@hum/icm-app/src/utils/format';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useCompanyNotice } from '@hum/icm-app/src/hooks/useCompanyNotice';
import { useHistory } from 'react-router';
import { useGetPortfolio } from '@hum/icm-app/src/hooks/useGetPortfolio';
import { usePortfolioDashboard } from '@hum/icm-app/src/hooks/usePortfolioDashboard';
import { usePortfolioConnectorsStatus } from '@hum/icm-app/src/hooks/usePortfolioConnectorsStatus';
import { useInvestorInvites } from '../../../hooks/useInvestorInvites';

interface TableDashboard {
  companyId: number;
  cashOnHand: number | null;
  ltmRevenue: number | null;
  lastMonthEBITDA: number | null;
  name: string;
}

const TABLE_CELLS: Record<string, TableCellConfig> = {
  companyName: {
    label: 'COMPANY',
    getSortValue: (item) => {
      return item.name;
    },
    renderer: ({ item }: { item: TableDashboard }) => {
      return <>{item.name}</>;
    },
  },
  ltmRevenue: {
    label: 'LTM REVENUE',
    getSortValue: (item) => {
      return item.ltmRevenue;
    },
    renderer: ({ item }: { item: TableDashboard }) => {
      return <>{item.ltmRevenue ? formatValue(item.ltmRevenue) : 'N/A'}</>;
    },
  },
  cashOnHand: {
    label: 'CASH ON HAND',
    getSortValue: (item) => {
      return item.cashOnHand;
    },
    renderer: ({ item }: { item: TableDashboard }) => {
      return <>{item.cashOnHand ? formatValue(item.cashOnHand) : 'N/A'}</>;
    },
  },
  lastMonthEBITDA: {
    label: "LAST MONTH'S EBITDA",
    getSortValue: (item) => {
      return item.lastMonthEBITDA;
    },
    renderer: ({ item }: { item: TableDashboard }) => {
      return (
        <>{item.lastMonthEBITDA ? formatValue(item.lastMonthEBITDA) : 'N/A'}</>
      );
    },
  },
};

export const PortfolioMonitoringDashboard = () => {
  const { state } = useAppStore();
  const companyId = getUserCompanyId(state);
  const { data: companies, loaded: companiesLoaded } = useGetPortfolio(
    companyId
  );
  const history = useHistory();

  const [
    portfolioGetStartedCompleted,
    portfolioGetStartedCompletedLoaded,
  ] = useCompanyNotice(NoticeName.PorfolioInvestorGetStarted, companyId);

  useEffect(() => {
    if (!portfolioGetStartedCompleted) {
      history.push(Routes.PORTFOLIO_MONITORING_ONBOARDING);
      return;
    }
  }, [portfolioGetStartedCompleted]);

  if (!portfolioGetStartedCompletedLoaded) return <Spinner />;
  if (!companiesLoaded) return <Spinner />;

  return (
    <>
      <Topbar />
      <Dashboard companies={companies || []} />
    </>
  );
};

const Dashboard = ({ companies }: { companies: PortfolioCompanies }) => {
  const { state } = useAppStore();
  const companyId = getUserCompanyId(state);
  const userCompany = state.session.data?.company;
  const {
    data: dashboardData,
    loaded: dashboardLoaded,
  } = usePortfolioDashboard(companies.map((c) => c.id));
  const {
    data: companiesConnectorStatus,
    loaded: companiesConnectorStatusLoaded,
  } = usePortfolioConnectorsStatus(companyId);
  const {
    data: invites = [],
  }: Result<PortfolioMonitoringInvite[]> = useInvestorInvites(companyId);
  const history = useHistory();
  const onRowClick = (companyId: number) => {
    history.push(`/portfolio-monitoring/${companyId}`);
  };
  const [tableCompanyData, setTableCompanyData] = useState<
    Array<TableDashboard>
  >([]);

  useEffect(() => {
    if (dashboardData) {
      const newTableCompanyData = companies.map((company) => {
        const tableItemRow = {} as TableDashboard;
        for (const dashboardRowItem in dashboardData[company.id]) {
          tableItemRow[dashboardRowItem] =
            dashboardData[company.id][dashboardRowItem];
        }
        tableItemRow['id'] = company.id;
        tableItemRow['name'] = company.name;
        return tableItemRow;
      });
      setTableCompanyData(newTableCompanyData);
    }
  }, [dashboardData]);

  return (
    <>
      {companiesConnectorStatusLoaded &&
      companiesConnectorStatus &&
      dashboardLoaded &&
      dashboardData ? (
        <styles.DashboardPortfolioInvestor
          title={`Welcome, ${userCompany?.name}`}
          onClick={() => {
            history.push(Routes.PORTFOLIO_MONITORING_MANAGE_COMPANIES);
          }}
          connectedCompaniesCount={companiesConnectorStatus.length}
          pendingCompaniesCount={invites.length}
          manageCompanies={companies.length >= 0}
        >
          {companies.length > 0 ? (
            <Table
              cellConfig={TABLE_CELLS}
              itemKey="id"
              data={tableCompanyData}
              secondary
              withChevron
              onRowClick={onRowClick}
              fixedCellWidth={true}
            />
          ) : (
            <styles.InviteBox
              title="Your companies are being onboarded"
              description="We’ll let you know when new information is available"
              buttonText={null}
            />
          )}
        </styles.DashboardPortfolioInvestor>
      ) : (
        <Spinner fullScreen />
      )}
    </>
  );
};
