import React from 'react';
import * as Yup from 'yup';

import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { SelectInput, Field, Modal } from '@hum/design-system';
import { Button, ButtonType } from '@hum/ui-library';
import { useField, useForm } from '@hum/common/src/modules/form';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import {
  PortfolioMonitoringStatusForm,
  PorfolioMonitoringInviteStatus,
} from '@hum/icm-app/src/state';
import { PORTFOLIO_MONITORING_STATUS_OPTIONS } from '@hum/icm-app/src/components/constants';

export const defaultValues = {
  status: PorfolioMonitoringInviteStatus.Created as PorfolioMonitoringInviteStatus,
};

const validationSchema = Yup.object().shape({
  status: Yup.string().required('Required'),
});

export const UpdateStatusModal = ({
  companyId,
  isOpen,
  onClose,
  invite,
}: {
  companyId: number;
  isOpen: boolean;
  onClose: () => void;
  invite?: PortfolioMonitoringStatusForm & { id: number };
}) => {
  const { dispatch } = useAppStore();

  // Wrap in memo to avoid calling this infinite times
  const initialValues = React.useMemo(
    () => (invite?.id ? invite : defaultValues),
    [invite, companyId]
  );
  const api = useAPI();

  const form = useForm({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (fields) => {
      const response = await api.portfolioMonitoring.updatePortfolioInvite(
        dispatch
      )(companyId, invite!.id, { status: fields.status });

      if (response.data.id) {
        handleClose();
      }
    },
  });

  const handleClose = () => {
    form.resetForm();
    onClose();
  };

  return (
    <>
      <Modal visible={isOpen} onClose={handleClose} header="Update status">
        <form onSubmit={form.handleSubmit}>
          <Field label="Status" v3 {...useField('status', 'text', form).input}>
            {(props) => (
              <SelectInput
                options={PORTFOLIO_MONITORING_STATUS_OPTIONS}
                {...props}
              />
            )}
          </Field>
          <Button
            loading={form.isSubmitting}
            disabled={form.isSubmitting}
            submit
          >
            Save status
          </Button>
          <Button type={ButtonType.QUIET} onClick={onClose}>
            Cancel
          </Button>
        </form>
      </Modal>
    </>
  );
};
