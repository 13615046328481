import { useCallback, useState } from 'react';
import { useAtom, useSetAtom, SetStateAction } from 'jotai';

import { atomWithStorage, createJSONStorage, RESET } from 'jotai/utils';
import { focusAtom } from 'jotai-optics';
import { OAuthParameters } from '@hum/types';

export type OAuthState = {
  oauthUrl?: string;
  isConnecting: boolean;
  userParams?: any;
  oauthConfigId?: number;
  connectionId?: number;
  companyId?: number;
};

const initialOauthState = {
  isConnecting: false,
};

const storage = createJSONStorage<OAuthState>(() => sessionStorage);
const oauthStateAtom = atomWithStorage<OAuthState>(
  'oauthState',
  initialOauthState,
  storage
);
const oauthUrlAtom = focusAtom(oauthStateAtom, (optic) =>
  optic.prop('oauthUrl')
);
const userParamsAtom = focusAtom(oauthStateAtom, (optic) =>
  optic.prop('userParams')
);
const oauthConfigIdAtom = focusAtom(oauthStateAtom, (optic) =>
  optic.prop('oauthConfigId')
);

const connectionIdAtom = focusAtom(oauthStateAtom, (optic) =>
  optic.prop('connectionId')
);

const companyIdAtom = focusAtom(oauthStateAtom, (optic) =>
  optic.prop('companyId')
);

const isConnectingAtom = focusAtom(oauthStateAtom, (optic) =>
  optic.prop('isConnecting')
);

export type IUseOAuthState = {
  clearOauthState: (
    state?: SetStateAction<Partial<OAuthState> | typeof RESET>
  ) => void;
  companyId?: number;
  setCompanyId: (id: number) => void;
  oauthConfigId?: number;
  setOauthConfigId: (id: number) => void;
  connectionId?: number;
  setConnectionId: (id: number) => void;
  oauthUrl?: string;
  setOauthUrl: (url: string) => void;
  parameters: Partial<OAuthParameters>;
  setParameters: (params: Partial<OAuthParameters>) => void;
  userParams?: any;
  setUserParams: (params: any) => void;
  isConnecting: boolean;
  setIsConnecting: (isConnecting: boolean) => void;
};

export const useAuthState = (): IUseOAuthState => {
  const [parameters, setParameters] = useState({});
  const [oauthUrl, setOauthUrl] = useAtom(oauthUrlAtom);
  const [oauthConfigId, setOauthConfigId] = useAtom(oauthConfigIdAtom);
  const [connectionId, setConnectionId] = useAtom(connectionIdAtom);
  const [userParams, setUserParams] = useAtom(userParamsAtom);
  const [companyId, setCompanyId] = useAtom(companyIdAtom);
  const [isConnecting, setIsConnecting] = useAtom(isConnectingAtom);
  const setOauthState = useSetAtom(oauthStateAtom);

  const clearOauthState = useCallback(
    (state: SetStateAction<Partial<OAuthState> | typeof RESET> = RESET) => {
      setOauthState(
        typeof state === 'symbol'
          ? state
          : {
              ...initialOauthState,
              ...state,
            }
      );
    },
    []
  );

  return {
    parameters,
    setParameters,
    oauthUrl,
    setOauthUrl,
    oauthConfigId,
    setOauthConfigId,
    connectionId,
    setConnectionId,
    userParams,
    setUserParams,
    clearOauthState,
    companyId,
    setCompanyId,
    setIsConnecting,
    isConnecting,
  };
};
