import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CompanyFileDocumentType,
  HUM_CAPITAL_TEMPLATE_FILES_URL,
  ddActions,
} from '@hum/types';
import { useApiMutation, uploadFile, deleteFile } from '@hum/api';
import { validateSpreadsheet } from './validation';

import clsx from 'clsx';
import {
  Button,
  ButtonType,
  Text,
  TextType,
  Modal,
  ModalFooter,
} from '@hum/ui-library';
import { Routes } from '@hum/icm-app/src/state';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { DropZone } from './DropZone';
import { datadogRum } from '@datadog/browser-rum';

export type Invalid = {
  file: File;
  callback: (file: File, potentiallyInvalid?: boolean) => void;
};

export const UploadFiles = () => {
  const { mutate: remove } = useApiMutation(deleteFile);
  const { mutate: upload, data: uploadedFile } = useApiMutation(uploadFile);
  const [invalid, setInvalid] = useState<Invalid | null>(null);

  const history = useHistory();
  const [balanceSheet, setBalanceSheet] = useState<File>();
  const [incomeStatement, setIncomeStatement] = useState<File>();

  const onValidate = useCallback(
    (
      setter: (file?: File) => void,
      documentType: CompanyFileDocumentType
    ) => async (file: File) => {
      const spreadSheetValid = await validateSpreadsheet(file);
      const callback = uploadSpreadsheet(setter, documentType);
      if (!spreadSheetValid) {
        setInvalid({ file, callback });
        datadogRum.addAction(ddActions.onboarding.upload.invalid);
        return;
      }

      await callback(file);
    },
    [balanceSheet, incomeStatement]
  );

  const uploadSpreadsheet = useCallback(
    (
      setter: (file?: File) => void,
      documentType: CompanyFileDocumentType
    ) => async (file: File, potentiallyInvalid?: boolean) => {
      upload({
        documentType,
        name: file.name,
        fileType: file.type,
        isExternal: false,
        blob: file,
        potentiallyInvalid: potentiallyInvalid || false,
      });
      datadogRum.addAction(ddActions.onboarding.upload.valid);
      setInvalid(null);
      setter(file);
    },
    [balanceSheet]
  );

  const onRemove = useCallback(
    (setter: (file: undefined) => void) => () => {
      if (!uploadedFile) {
        return;
      }

      remove({ fileId: uploadedFile.id });
      setter(undefined);
    },
    [balanceSheet, incomeStatement, uploadedFile]
  );

  return (
    <>
      <Modal
        title="Confirm Upload"
        isOpen={Boolean(invalid)}
        onClose={() => setInvalid(null)}
      >
        <Text type={TextType.BODY_M} className="m-8 mt-0">
          You appear to be uploading a file that doesn't include segmented
          monthly data, or doesn't have at least 12 months worth of data. For
          the best outcomes, please store all data in one file, segment data by
          month, and provide a minimum of 12 months data.
        </Text>
        <Text type={TextType.BODY_M} className="m-8">
          Are you sure you want to upload "{invalid?.file?.name}"?
        </Text>
        <ModalFooter>
          <Button
            className="mr-auto"
            type={ButtonType.QUIET}
            onClick={() => setInvalid(null)}
            dd-action={ddActions.onboarding.upload.cancel}
          >
            Cancel
          </Button>
          <Button
            onClick={() => invalid && invalid.callback(invalid.file, true)}
            dd-action={ddActions.onboarding.upload.confirm}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
      {/* TODO: Convert to new OnboardingLayout */}
      <Topbar
        links={[]}
        title="Build your Data room"
        fixedBar
        background="bg-defaultBg"
        flow
      />
      <div className="pt-32">
        <div className="bg-defaultBg h-[calc(100vh_-_8rem)]">
          {/* END TODO */}
          <div
            className={clsx(
              'mx-auto',
              'w-10/12',
              'md:w-6/12',
              'lg:w-10/12',
              'grid',
              'lg:grid-cols-[25%_1fr_25%]',
              'gap-x-14',
              'grid-rows-[32px_1fr_auto]',
              'h-full'
            )}
          >
            <Text
              className="lg:col-start-2 lg:col-end-2"
              type={TextType.PAGE_HEADING}
            >
              Upload files
            </Text>
            <div className="lg:col-start-2 my-8">
              <div
                className={clsx(
                  'p-10',
                  'border',
                  'border-borderColor',
                  'rounded'
                )}
              >
                <DropZone
                  className="mb-5"
                  title="Balance sheet"
                  onFileUpload={onValidate(
                    setBalanceSheet,
                    CompanyFileDocumentType.BalanceSheet
                  )}
                  extensions={['csv', 'xlsx']}
                  file={balanceSheet}
                  onRemove={onRemove(setBalanceSheet)}
                />
                <DropZone
                  className="mb-5"
                  title="Income statement"
                  onFileUpload={onValidate(
                    setIncomeStatement,
                    CompanyFileDocumentType.IncomeStatement
                  )}
                  file={incomeStatement}
                  extensions={['csv', 'xlsx']}
                  onRemove={onRemove(setIncomeStatement)}
                />
                <Button
                  onClick={() => history.push(Routes.ONBOARDING_V5_COMPLETED)}
                  fullWidth
                  disabled={!balanceSheet || !incomeStatement}
                >
                  Finish signing up
                </Button>
              </div>
            </div>
            <div className="lg:col-start-3">
              <Text type={TextType.HEADING_S} className="mb-4 lg:mt-8">
                How to prepare your file
              </Text>
              <ul
                className={clsx(
                  'list-disc list-inside',
                  'text-sm',
                  'text-subduedTextColor',
                  'mb-2'
                )}
              >
                <li>Store all data in 1 file</li>
                <li>Segment data by month</li>
                <li>Minimum 12 months of data</li>
              </ul>
              <div className="mb-2.5">
                Read more about
                <a
                  className="text-interactive-default"
                  target="_blank"
                  href="https://info.humcapital.com/hum-help-center/what-are-the-minimum-data-requirements-to-work-with-hum-capital"
                  dd-action={ddActions.onboarding.upload.requirements}
                >
                  {' '}
                  Hum's data requirements
                </a>
              </div>
              <a
                href={HUM_CAPITAL_TEMPLATE_FILES_URL}
                className="text-interactive-default block"
                dd-action={ddActions.onboarding.upload.template}
              >
                Download template
              </a>
            </div>
            <div className="lg:col-start-2 m-10">
              <Button
                type={ButtonType.QUIET}
                fullWidth
                onClick={() => history.push(Routes.ONBOARDING_V5_CONNECT)}
                dd-action={ddActions.onboarding.upload.connect}
              >
                Connect instead
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
