import React from "react";
import _pub824B8933, {FormLayout as _pub824B8933_FormLayout} from "../../../../../design-system/src/layout.pc";
import _pub4435Fe58 from "../../../../../design-system/src/Pill.pc";
import _pubE434Fb6A from "../../../../../design-system/src/Card.pc";
import _pubA97D53F3 from "../../../../../design-system/src/atoms.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../design-system/src/utils.pc";
import _pub2Dbb4493 from "../../../../../design-system/src/Button.pc";
import _pub2F51C399 from "../../../../../design-system/src/breakpoints.pc";
import "./common.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var OnboardingForm = React.memo(React.forwardRef(function OnboardingForm(props, ref) {
  return React.createElement(_pub824B8933_FormLayout, {
    "class": "_1c4a36c4 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "testId": props["testId"],
    "onSubmit": props["onSubmit"],
  }, 
    props["children"]
  )
}));
export { OnboardingForm };

var SignedByCard = React.memo(React.forwardRef(function SignedByCard(props, ref) {
  return React.createElement(_pubE434Fb6A, {
    "class": "_b90c28f7 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_5e652ea6 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399",
    }, 
      React.createElement("span", {
        "className": "_14a600ef _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399" + " " + "_8ec1b7a_text-body-small _pub-8ec1b7a_text-body-small _pub-a97d53f3_text-body-small _pub-2f51c399_text-body-small text-body-small",
      }, 
        props["name"]
      ),
      React.createElement("span", {
        "className": "_8daf5155 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399" + " " + "_8ec1b7a_color-text-subdued _pub-8ec1b7a_color-text-subdued _pub-a97d53f3_color-text-subdued _pub-2f51c399_color-text-subdued color-text-subdued",
      }, 
        props["position"]
      )
    ),
    props["signed"]&&React.createElement(_pub4435Fe58, {
      "class": "_f906de50 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399",
      "bold": true,
      "positive": true,
    }, 
      "Signed"
      
    )
  )
}));
export { SignedByCard };

var SetupCard = React.memo(React.forwardRef(function SetupCard(props, ref) {
  return React.createElement(_pubE434Fb6A, {
    "class": "_570249db _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
    "interactive": true,
    "style": castStyle(props["style"]),
  }, 
    props["children"]
  )
}));
export { SetupCard };

var FeedbackCard = React.memo(React.forwardRef(function FeedbackCard(props, ref) {
  return React.createElement(_pubE434Fb6A, {
    "class": "_be61ecee _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
    "style": castStyle("background-color: var(--feedback-informative-bg);"),
  }, 
    props["children"]
  )
}));
export { FeedbackCard };

var LinkButton = React.memo(React.forwardRef(function LinkButton(props, ref) {
  return React.createElement("button", {
    "className": "_506f8dc2 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399" + (props["class"] ? " " + props["class"] : ""),
    "ref": ref,
    "transparent": true,
    "onClick": props["onClick"],
    "href": props["href"],
  }, 
    props["children"]
  )
}));
export { LinkButton };

var AlreadyInHum = React.memo(React.forwardRef(function AlreadyInHum(props, ref) {
  return React.createElement("p", {
    "className": "_b7d7a0c5 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
  }, 
    props["label"],
    props["children"]
  )
}));
export { AlreadyInHum };

var ActionButton = React.memo(React.forwardRef(function ActionButton(props, ref) {
  return React.createElement("button", {
    "className": "_92217b34 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
    "transparent": true,
    "onClick": props["onClick"],
  }, 
    props["children"]
  )
}));
export { ActionButton };

var FooterBackButton = React.memo(React.forwardRef(function FooterBackButton(props, ref) {
  return React.createElement("div", {
    "className": "_7c2f1a18 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
  }, 
    React.createElement("button", {
      "className": "_385365b0 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399" + (props["isFirstStep"] ? " " + "_8ec1b7a_is-first-step _pub-8ec1b7a_is-first-step _pub-a97d53f3_is-first-step _pub-2f51c399_is-first-step is-first-step" : ""),
      "onClick": props["onClick"],
    }, 
      React.createElement("img", {
        "className": "_e9304b16 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399",
        "src": getDefault(require("@hum/figma/feather-component-library-v-4280/icons-master/arrow-left@1.svg")),
      }, null),
      props["children"]
    )
  )
}));
export { FooterBackButton };

var FooterContinue = React.memo(React.forwardRef(function FooterContinue(props, ref) {
  return React.createElement("div", {
    "className": "_954cbf2d _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
  }, 
    React.createElement(_pub2Dbb4493, {
      "class": "_4bd92994 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399",
      "primary": true,
      "wide": true,
      "onClick": props["onContinueClick"],
    }, 
      "Continue"
      
    )
  )
}));
export { FooterContinue };

var FormWrapper = React.memo(React.forwardRef(function FormWrapper(props, ref) {
  return React.createElement("div", {
    "className": "_7b42de01 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399" + (props["marginAuto"] ? " " + "_8ec1b7a_margin-auto _pub-8ec1b7a_margin-auto _pub-a97d53f3_margin-auto _pub-2f51c399_margin-auto margin-auto" : "") + (props["withOverflow"] ? " " + "_8ec1b7a_with-overflow _pub-8ec1b7a_with-overflow _pub-a97d53f3_with-overflow _pub-2f51c399_with-overflow with-overflow" : "") + (props["wide"] ? " " + "_8ec1b7a_wide _pub-8ec1b7a_wide _pub-a97d53f3_wide _pub-2f51c399_wide wide" : "") + (props["center"] ? " " + "_8ec1b7a_center _pub-8ec1b7a_center _pub-a97d53f3_center _pub-2f51c399_center center" : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { FormWrapper };

var FooterOption = React.memo(React.forwardRef(function FooterOption(props, ref) {
  return React.createElement("p", {
    "className": "_9cfaf306 _8ec1b7a _pub-8ec1b7a _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
    "labelFirst": props["labelFirst"],
  }, 
    props["labelFirst"]&&React.createElement(React.Fragment, {
    }, 
      props["label"],
      props["children"]
    ),
    !props["labelFirst"]&&React.createElement(React.Fragment, {
    }, 
      props["children"],
      props["label"]
    )
  )
}));
export { FooterOption };


