import React from 'react';
import { CompanyCharTabName } from '@hum/icm-app/src/state';
import { Company } from '@hum/types';
import { useCharts } from '@hum/icm-app/src/hooks/useCharts';
import { TabNavigator, Tab } from '@hum/legacy-ui';
import { ChartList } from '@hum/icm-app/src/components/ChartList';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { getChartsByTabName, useApiQuery } from '@hum/api';

const TabNames = {
  [CompanyCharTabName.preTermSheet]: 'Pre term sheet',
  [CompanyCharTabName.diligence]: 'Diligence',
  [CompanyCharTabName.servicing]: 'Servicing',
  [CompanyCharTabName.historicalData]: 'Revenue Analytics',
  [CompanyCharTabName.projection]: 'Financing Terms',
  [CompanyCharTabName.syndication]: 'Syndication (post-intro)',
  [CompanyCharTabName.portfolioMonitoring]: 'Portfolio Monitoring',
  [CompanyCharTabName.syndicationPreIntro]: 'Syndication (pre-intro)',
  [CompanyCharTabName.portfolioAnalytics]: 'Portfolio Analytics',
};

const ChartRendered = ({
  company,
  tab,
}: {
  company: Company;
  tab: CompanyCharTabName;
}) => {
  const flags = useFlags();
  const { data: chartData, isLoading: chartIsLoading } = useApiQuery(
    getChartsByTabName,
    {
      params: {
        companyId: company.id,
        tabName: tab,
      },
      enabled: flags.enabled('use-react-query'),
    }
  );
  const { data: sagaChartData, loaded: sagaChartDataLoaded } = useCharts(
    company,
    tab
  );
  const data = flags.enabled('use-react-query') ? chartData : sagaChartData;
  const loaded = flags.enabled('use-react-query')
    ? !chartIsLoading
    : sagaChartDataLoaded;

  if (!loaded) return null;

  return <ChartList charts={data!} />;
};

// Not the best approach to re-render the same component but useCharts has an effect
// that runs when tab changes so that'll do for now
export const Analytics = ({ company }: { company: Company }) => {
  return (
    <TabNavigator horizontal controls={null}>
      <Tab label={TabNames[CompanyCharTabName.portfolioAnalytics]}>
        <ChartRendered
          company={company}
          tab={CompanyCharTabName.portfolioAnalytics}
        />
      </Tab>
    </TabNavigator>
  );
};
