import React from 'react';
import ReactBodyMovin from 'react-bodymovin';
import confetti from '@hum/assets/confetti.json';

export default function ConfettiAnimation() {
  return (
    <ReactBodyMovin
      options={{
        autoplay: true,
        prerender: false,
        loop: false,
        animationData: confetti,
      }}
    />
  );
}
