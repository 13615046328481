import React from 'react';
import { useHistory } from 'react-router-dom';

import { Routes } from '@hum/icm-app/src/state';
import { Button, ButtonType } from '@hum/ui-library';

export type ConnectorListFooter = {
  hasActiveConnections?: boolean;
  onOpen: () => void;
  onConnect?: (() => void) | null;
  isLoading?: boolean;
  isOnboarding?: boolean;
};

export const ConnectorListFooter = ({
  hasActiveConnections = false,
  onConnect,
  onOpen,
  isLoading = false,
  isOnboarding = false,
}: ConnectorListFooter) => {
  const history = useHistory();

  return isLoading ? null : (
    <div data-testid="connector-list-button" className="py-10 lg:col-start-2">
      {hasActiveConnections ? (
        <Button
          fullWidth
          onClick={() => history.push(Routes.ONBOARDING_V5_COMPLETED)}
        >
          Finish signing up
        </Button>
      ) : onConnect ? (
        <Button fullWidth onClick={onConnect}>
          Connect
        </Button>
      ) : (
        isOnboarding && (
          <Button fullWidth type={ButtonType.QUIET} onClick={onOpen}>
            I’m not able to connect my software
          </Button>
        )
      )}
    </div>
  );
};
