import * as styles from '@hum/icm-app/src/pages/portfolio-monitoring/styles.pc';

import { Modal } from '@hum/design-system';
import { Checkbox, Table, TableCellConfig, Field } from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import {
  NoticeName,
  PortfolioMonitoringInvite,
  Routes,
  getUserCompanyId,
} from '@hum/icm-app/src/state';
import { Result } from '@hum/common/src/ducks/state';
import React, { useEffect, useState } from 'react';

import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { toast } from '@hum/common/src/modules/toast';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useCompanyNotice } from '@hum/icm-app/src/hooks/useCompanyNotice';
import { useHistory } from 'react-router';
import { useInvestorInvites } from '@hum/icm-app/src/hooks/useInvestorInvites';
import { usePortfolioCompanyInviteUrl } from '@hum/icm-app/src/hooks/usePortfolioCompanyInviteUrl';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import {
  bulkCompanyStatusUpdate,
  setNoticeAsSeen,
  useApiMutation,
} from '@hum/api';

export const PortfolioMonitoringOnboarding = () => {
  const flags = useFlags();
  const { mutate: bulkInviteStatusUpdate } = useApiMutation(
    bulkCompanyStatusUpdate
  );
  const { mutate: noticeWasSeen } = useApiMutation(setNoticeAsSeen);
  const api = useAPI();

  const [
    sendInvitationsButtonStatus,
    SetSendInvitationsButtonStatus,
  ] = useState(false);
  const [linkAccordionActive, setLinkAccordionActive] = useState(false);
  const [inviteListAccordianActive, setInviteListAccordianActive] = useState(
    false
  );
  const [linkValue, setLinkValue] = useState('No Link here');
  const { state, dispatch } = useAppStore();
  const history = useHistory();
  const companyId = getUserCompanyId(state);
  const currentUser = state.session.data;
  let showOnboarding;

  const { data: companyInviteUrl } = usePortfolioCompanyInviteUrl(companyId);

  const {
    data: invites = [],
  }: Result<PortfolioMonitoringInvite[]> = useInvestorInvites(companyId);

  const [selectedCompaniesToInvite, setSelectedCompaniesToInvite] = useState(
    {}
  );
  const [skipInvitations, setSkipInvitations] = useState(false);
  const [showConfirmSkipInvitations, setShowConfirmSkipInvitations] = useState(
    false
  );

  const TABLE_CELLS: Record<string, TableCellConfig> = {
    companyName: {
      label: 'COMPANY',
      renderer: ({ item }: { item: PortfolioMonitoringInvite }) => {
        return (
          <styles.WrapperData>
            <Field
              key={`company-checked-${item.companyName}-${item.id}`}
              data-testid={`portfolio-monitoring-invite-${item.id}`}
              toggle
              label={item.companyName}
              onValueChange={() =>
                setSelectedCompaniesToInvite((selections) => ({
                  ...selections,
                  [item.id]: !selections[item.id],
                }))
              }
              value={selectedCompaniesToInvite[item.id]}
              children={(props) => <Checkbox {...props} />}
            />
          </styles.WrapperData>
        );
      },
    },
    companyContact: {
      label: 'CONTACT NAME',
      renderer: ({ item }: { item: PortfolioMonitoringInvite }) => {
        return (
          <styles.WrapperData>{`${item.contactFirstName} ${item.contactLastName}`}</styles.WrapperData>
        );
      },
    },
    companyEmail: {
      label: 'CONTACT EMAIL',
      renderer: ({ item }: { item: PortfolioMonitoringInvite }) => {
        return <styles.WrapperData>{item.contactEmail}</styles.WrapperData>;
      },
    },
  };

  useEffect(() => {
    if (companyInviteUrl) {
      setLinkValue(companyInviteUrl.inviteUrl);
    }
  }, [companyInviteUrl]);

  useEffect(() => {
    if (invites.length) {
      const stateMap = {};
      invites.forEach((company) => (stateMap[company.id] = true));

      setSelectedCompaniesToInvite(stateMap);
    }
  }, [invites]);

  useEffect(() => {
    SetSendInvitationsButtonStatus(
      !!Object.values(selectedCompaniesToInvite).filter((v) => v).length
    );
  }, [selectedCompaniesToInvite]);

  const handleGetStartedClick = async () =>
    flags.enabled('use-react-query')
      ? noticeWasSeen({
          companyId,
          noticeName: NoticeName.PorfolioInvestorGetStarted,
        })
      : await api.onboarding.setCompanyNoticeAsSeen(dispatch)(
          companyId,
          NoticeName.PorfolioInvestorGetStarted
        );
  const invites_created = invites.filter((item) => item.status == 'created');
  const handleSendPortfolioInvites = async () => {
    const companiesToInvite: number[] = invites_created
      .filter((invitation) => selectedCompaniesToInvite[invitation.id])
      .map((invitations) => invitations.id);
    setSkipInvitations(true);
    return flags.enabled('use-react-query')
      ? bulkInviteStatusUpdate({
          companyId,
          inviteIds: companiesToInvite,
        })
      : await api.portfolioMonitoring.bulkCompanyStatusUpdate(dispatch)(
          companyId,
          companiesToInvite
        );
  };

  const [
    portfolioInvestorGetStarted,
    portfolioInvestorGetStartedLoaded,
  ] = useCompanyNotice(NoticeName.PorfolioInvestorGetStarted, companyId);

  if (portfolioInvestorGetStartedLoaded && !portfolioInvestorGetStarted) {
    showOnboarding = true;
  }

  if (!portfolioInvestorGetStartedLoaded) return null;

  const copyToClipboard = (textToCopy: string) => {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      textArea.remove();
      return;
    }
  };

  return showOnboarding ? (
    <styles.Onboarding
      title={`Welcome, ${currentUser?.company?.name}`}
      onClick={handleGetStartedClick}
    />
  ) : (
    <>
      <Topbar />
      <styles.InvitePage
        link={linkValue}
        linkAccordionActive={linkAccordionActive}
        inviteListAccordianActive={inviteListAccordianActive}
        bottomCtaPrimary={true}
        copyLinkPrimary={true}
        sendInvitationsButtonStatus={sendInvitationsButtonStatus}
        onClick={() => setLinkAccordionActive(!linkAccordionActive)}
        inviteListOnClick={() =>
          setInviteListAccordianActive(!inviteListAccordianActive)
        }
        copyLink={() => {
          copyToClipboard(linkValue);
          toast.success('Invite link copied to clipboard');
        }}
        onClickDashboard={() => {
          skipInvitations
            ? history.push(Routes.PORTFOLIO_MONITORING)
            : setShowConfirmSkipInvitations(true);
        }}
        onClickSendInvites={handleSendPortfolioInvites}
        onClickPreviewInvites={() => {
          history.push(Routes.PORTFOLIO_MONITORING_PREVIEW_INVITATION);
        }}
        companiesTable={
          invites_created.length > 0 && (
            <Table
              cellConfig={TABLE_CELLS}
              itemKey="id"
              data={invites_created}
              secondary
              fixedCellWidth={true}
            />
          )
        }
      />
      {!skipInvitations && (
        <Modal
          visible={showConfirmSkipInvitations}
          onClose={() => setShowConfirmSkipInvitations(false)}
          header="Do you want to skip recommendations?"
          wide
          footer={
            <>
              <Button
                type={ButtonType.SECONDARY}
                onClick={() => setShowConfirmSkipInvitations(false)}
              >
                Review again
              </Button>
              <Button
                type={ButtonType.DESTRUCTIVE}
                onClick={() => history.push(Routes.PORTFOLIO_MONITORING)}
              >
                Skip recommendations
              </Button>
            </>
          }
        >
          <p>
            Your recommendations will be discarded. You can add companies later
            manually by going to Manage companies.
          </p>
        </Modal>
      )}
    </>
  );
};
