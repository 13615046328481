import React, { useRef, useEffect } from 'react';

type PreviewOptions = {
  hue?: number;
};

const CANVAS_PADDING = 30;
const CANVAS_HEIGHT = 184;
const CANVAS_WIDTH = 352;

type PreviewProps = {
  sourceImage?: HTMLImageElement;
  options: PreviewOptions;
};

export const Preview = ({ sourceImage, options }: PreviewProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!sourceImage || !canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d')!;

    drawCheckers(ctx);

    const dx = sourceImage.width - CANVAS_WIDTH;
    const dy = sourceImage.height - CANVAS_HEIGHT;

    let scale = 1;
    if (dx > dy) {
      scale = Math.min(
        1,
        (CANVAS_WIDTH - CANVAS_PADDING * 2) / sourceImage.width
      );
    } else {
      scale = Math.min(
        1,
        (CANVAS_HEIGHT - CANVAS_PADDING * 2) / sourceImage.height
      );
    }

    drawImage(ctx, sourceImage, options, scale);
  }, [sourceImage, canvasRef.current, options?.hue]);

  return <canvas ref={canvasRef} width={CANVAS_WIDTH} height={CANVAS_HEIGHT} />;
};

const drawImage = (
  ctx: CanvasRenderingContext2D,
  image: HTMLImageElement,
  options: PreviewOptions,
  scale: number
) => {
  const nw = Math.round(image.width * scale);
  const nh = Math.round(image.height * scale);
  const cx = Math.round(CANVAS_WIDTH / 2 - nw / 2);
  const cy = Math.round(CANVAS_HEIGHT / 2 - nh / 2);

  const canvas = getFilteredImage(image, options);
  ctx.drawImage(canvas, cx, cy, nw, nh);

  return canvas;
};

const getFilteredImage = (image: HTMLImageElement, options: PreviewOptions) => {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const ctx2 = canvas.getContext('2d')!;

  ctx2.drawImage(image, 0, 0);

  if (options.hue != null) {
    applyHue(ctx2, 0, 0, image.width, image.height, options.hue);
  }

  return canvas;
};

export const getFilteredImageBlob = (
  image: HTMLImageElement,
  options: PreviewOptions
): Promise<Blob> => {
  return new Promise((resolve) => {
    const modifiedImage = getFilteredImage(image, options);
    modifiedImage.toBlob((blob) => {
      resolve(blob!);
    }, 'image/png');
  });
};

const CHECKER_SIZE = 10;

const drawCheckers = (ctx: CanvasRenderingContext2D) => {
  // x
  for (let i = 0; i < CANVAS_WIDTH; i += CHECKER_SIZE * 2) {
    const x = i;
    // y
    for (let j = 0; j < CANVAS_HEIGHT; j += CHECKER_SIZE * 2) {
      const y = j;
      ctx.fillStyle = '#CCC';
      ctx.fillRect(x, y, 10, 10);
      ctx.fillRect(x + CHECKER_SIZE, y + CHECKER_SIZE, 10, 10);
      ctx.fillStyle = 'white';
      ctx.fillRect(x + CHECKER_SIZE, y, 10, 10);
      ctx.fillRect(x, y + CHECKER_SIZE, 10, 10);
    }
  }
};

const applyHue = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  hue: number
) => {
  const imageData = ctx.getImageData(x, y, width, height);
  const data = imageData.data;
  for (var i = 0; i < data.length; i += 4) {
    data[i] = hue;
    data[i + 1] = hue;
    data[i + 2] = hue;
  }

  // overwrite original image
  ctx.putImageData(imageData, x, y);
};
