import { trackActions } from '@hum/icm-app/src/modules/analytics';
import {
  CompanyOnboardingActionType,
  CompanySignupActionType,
} from './actions';

// See event details and documentation here:
// https://www.notion.so/capitaltechnology/3e79df34b12845fc82314b95f3191a4c?v=ec610dbdd9fc4940b8c375083775452e

export function* handleMetrics() {
  yield trackActions([
    {
      actionType: CompanySignupActionType.COMPANY_VISIT_REVIEW_AND_CONFIRM,
      track: 'company-visit-review-and-confirm',
    },
    {
      actionType: CompanySignupActionType.COMPANY_VISIT_RAISE_OR_INVEST,
      track: 'company-visit-raise-or-invest',
    },
    {
      actionType: CompanySignupActionType.COMPANY_VISIT_SIGN_UP,
      track: 'company-visit-sign-up',
    },
    {
      actionType:
        CompanySignupActionType.COMPANY_VISIT_TELL_US_ABOUT_YOUR_BUSINESS,
      track: 'company-visit-tell-us-about-your-business',
    },
    {
      actionType: CompanySignupActionType.COMPANY_VISIT_SHARE_YOUR_GOALS,
      track: 'company-visit-share-your-goals',
    },
    {
      actionType: CompanySignupActionType.COMPANY_VISIT_FUNDRAISING_HISTORY,
      track: 'company-visit-fundraising-history',
    },
    {
      actionType: CompanySignupActionType.COMPANY_VISIT_VERIFY_EMAIL,
      track: 'company-visit-verify-email',
    },
    {
      actionType: CompanyOnboardingActionType.COMPANY_VISIT_WELCOME_TO_HUM,
      track: 'company-visit-welcome-to-hum',
    },
    {
      actionType: CompanyOnboardingActionType.COMPANY_VISIT_MNDA,
      track: 'company-visit-mnda',
    },
    {
      actionType: CompanyOnboardingActionType.COMPANY_ACCEPT_MNDA,
      track: 'company-accept-mnda',
    },
    {
      actionType: CompanyOnboardingActionType.COMPANY_VISIT_CONNECT,
      track: 'company-visit-connect',
    },
    {
      actionType: CompanyOnboardingActionType.COMPANY_UPLOAD_FILE,
      track: 'company-upload-file',
    },
    {
      actionType: CompanyOnboardingActionType.COMPANY_VISIT_FINAL_PAGE,
      track: 'company-visit-final-page',
    },
  ]);
}
