import React from 'react';
import clsx from 'clsx';
import { Text, TextType } from '@hum/ui-library';

export const WHY_CONNECT_URL =
  'https://info.humcapital.com/hum-help-center/why-should-i-connect-my-business-system-to-hum';

export type ConnectorPrepInstructionsProps = {
  className?: string;
};

export const ConnectorPrepInstructions = ({
  className,
}: ConnectorPrepInstructionsProps) => {
  return (
    <div
      data-testid="connector-list-instructions"
      className={clsx('mt-8', className)}
    >
      <Text type={TextType.HEADING_S} className="mb-2">
        Why connect?
      </Text>
      <Text type={TextType.UI_ELEMENT} subdued className="mb-2 w-fit">
        Get faster funding options and access more investors.
      </Text>
      <a
        data-testid="why-connect-link"
        href={WHY_CONNECT_URL}
        target="_blank"
        className="ui-link"
        rel="noopener noreferrer"
      >
        Learn more
      </a>
    </div>
  );
};
