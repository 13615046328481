import React from 'react';
import { useParams } from 'react-router-dom';
import { ConnectorListView } from './ConnectorListView';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { PlatformType } from '@hum/types';

export type OnboardingConnectStepProps = {
  isOnboarding?: boolean;
};

export type ConnectorParams = {
  platformType?: string;
};

export function OnboardingConnectStep({
  isOnboarding = false,
}: OnboardingConnectStepProps) {
  let {
    platformType = PlatformType.AccountingSystem,
  } = useParams<ConnectorParams>();
  platformType = platformType.replace(/-/g, '_');

  return (
    <>
      <Topbar
        links={[]}
        title="Build your Data room"
        fixedBar
        background="bg-defaultBg"
        flow
      />
      <div className="pt-32">
        <div className="bg-defaultBg h-[calc(100vh_-_8rem)]">
          <ConnectorListView
            isOnboarding={isOnboarding}
            withTransitions
            platformType={platformType as PlatformType}
          />
        </div>
      </div>
    </>
  );
}
