import * as containerStyles from '@hum/icm-app/src/components/Split/styles.pc';
import * as styles from './../settings/manage-users/TeamTab/styles.pc';
import { Company, CompanyType } from '@hum/types';
import { CompanyName, CompanyWrapper } from './styles.pc';
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { Tab, TabNavigator, MetaTitle } from '@hum/legacy-ui';
import { Analytics } from './Analytics';
import { Button, Scrollable, TextType, Text } from '@hum/ui-library';
import { CompanyInfo } from '@hum/icm-app/src/components/CompanyInfo';
import { Invites } from './Invites';
import React from 'react';
import { Routes } from '@hum/icm-app/src/state/routes';
import { TeamTab } from '../settings/manage-users/ManageUsers';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { useCompanies } from '@hum/icm-app/src/hooks/useCompanies';
import { useCompany } from '@hum/icm-app/src/hooks/useCompany';
import { useCompanyContact } from '@hum/icm-app/src/hooks/useCompanyContact';
import { getCompanyContact, useApiQuery } from '@hum/api';
import { useFlags } from '../../hooks/useFlags';

const InvestorPageDetail = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactChild | React.ReactChildren;
}) => {
  return (
    <CompanyWrapper>
      <CompanyName>{title}</CompanyName>
      {children}
    </CompanyWrapper>
  );
};

const CompanyDetail = () => {
  const flags = useFlags();
  const { companyId } = useParams<{ companyId?: string }>();

  const { data: company } = useCompany(Number(companyId || 0));
  const sagasCompanyContact = useCompanyContact(Number(companyId || 0));
  const {
    data: queryCompanyContact,
    isLoading: companyContactIsLoading,
  } = useApiQuery(getCompanyContact, {
    params: { companyId: Number(companyId || 0) },
    enabled: flags.enabled('use-react-query') && Number(companyId || 0) > 0,
  });
  const companyContact = flags.enabled('use-react-query')
    ? { data: queryCompanyContact, loaded: !companyContactIsLoading }
    : sagasCompanyContact;

  if (!company) {
    return null;
  }

  return (
    <InvestorPageDetail title={company.name}>
      <TabNavigator horizontal controls={null}>
        <Tab label="Overview">
          {companyContact.data && (
            <CompanyInfo contact={companyContact.data} company={company} />
          )}
        </Tab>
        <Tab label="Analytics">
          <Analytics company={company} />
        </Tab>
      </TabNavigator>
    </InvestorPageDetail>
  );
};

type MenuItem = {
  title: string;
  component: React.ReactElement;
  requires: CompanyType[];
};

const menuOptions: {
  [key: string]: MenuItem;
} = {
  [Routes.INVITE_COMPANIES]: {
    title: 'Invite Companies',
    component: <Invites />,
    requires: [CompanyType.Investor],
  },
  [Routes.MANAGE_TEAM]: {
    title: 'Invite Team',
    component: <TeamTab />,
    requires: [
      CompanyType.Investor,
      CompanyType.Admin,
      CompanyType.BalanceSheetPartner,
      CompanyType.SyndicationInvestor,
      CompanyType.Company,
    ],
  },
};

// Portfolio page should have:
// - List of companies
// - List of invites
export const InvestorPortfolio = () => {
  const history = useHistory();
  const {
    data: { companies } = { companies: [] as Company[] },
  } = useCompanies();

  return (
    <containerStyles.Wrapper>
      <MetaTitle>Portfolio</MetaTitle>
      <Topbar />
      <containerStyles.CobrandingLayout>
        <containerStyles.NavSidebar title="My Portfolio">
          <styles.CobrandingMenu>
            <NavLink
              key="invite-companies"
              to={Routes.INVITE_COMPANIES}
              activeClassName="active"
            >
              Invite Companies
            </NavLink>
            {companies.map((company: Company) => {
              return (
                <NavLink
                  key={company.id}
                  to={{
                    pathname: `/portfolio/${company.id}`,
                  }}
                  activeClassName="active"
                >
                  {company.name}
                </NavLink>
              );
            })}
          </styles.CobrandingMenu>
        </containerStyles.NavSidebar>
        <containerStyles.CobrandingRight>
          <Scrollable>
            <Switch>
              {Object.entries(menuOptions).map(([key, menuItem]) => {
                return (
                  <Route key={key} path={key}>
                    {menuItem.component}
                  </Route>
                );
              })}
              <Route key="" path={Routes.PORTFOLIO_COMPANY}>
                <CompanyDetail />
              </Route>
              <Route key="" path={Routes.PORTFOLIO_COMPANIES}>
                <div
                  className="flex flex-col items-center justify-center w-full h-full"
                  data-testid="admin:empty-companies-message"
                >
                  <Text type={TextType.BODY_L} className="mb-6">
                    Start by adding your portfolio companies
                  </Text>
                  <Text type={TextType.BODY_S} subdued className="mb-8">
                    When your companies join, you'll be able to see them here.
                  </Text>
                  <Button
                    className="mb-8"
                    onClick={() => {
                      history.push('/portfolio/invites');
                    }}
                  >
                    Invite Companies
                  </Button>
                </div>
              </Route>
            </Switch>
          </Scrollable>
        </containerStyles.CobrandingRight>
      </containerStyles.CobrandingLayout>
    </containerStyles.Wrapper>
  );
};
