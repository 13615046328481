import { MNDAPayloadType } from '@hum/icm-app/src/state';
import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';

export enum MNDAActionType {
  ACCEPT_MNDA_BUTTON_CLICKED = 'onboarding-v5/ACCEPT_MNDA_BUTTON_CLICKED',
  ACCEPT_MNDA_SUCCESS = 'onboarding-v5/ACCEPT_MNDA_SUCCESS',
  ACCEPT_MNDA_FAIL = 'onboarding-v5/ACCEPT_MNDA_FAIL',
}

export type AcceptMndaButtonClicked = BaseAction<
  MNDAActionType.ACCEPT_MNDA_BUTTON_CLICKED,
  { companyId: number; payload: MNDAPayloadType }
>;
export const acceptMndaButtonClicked = actionCreator<AcceptMndaButtonClicked>(
  MNDAActionType.ACCEPT_MNDA_BUTTON_CLICKED
);

export type AcceptMndaSuccess = BaseAction<MNDAActionType.ACCEPT_MNDA_SUCCESS>;
export const acceptMndaSuccess = actionCreator<AcceptMndaSuccess>(
  MNDAActionType.ACCEPT_MNDA_SUCCESS
);

export type AcceptMndaFail = BaseAction<MNDAActionType.ACCEPT_MNDA_FAIL>;
export const acceptMndaFail = actionCreator<AcceptMndaFail>(
  MNDAActionType.ACCEPT_MNDA_FAIL
);

export type MNDAActions =
  | AcceptMndaButtonClicked
  | AcceptMndaSuccess
  | AcceptMndaFail;
