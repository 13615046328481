import { useCallback } from 'react';
import { ConnectorOption, Connector, OAuthParameters } from '@hum/types';
import { useApiMutation, createConnector } from '@hum/api';
import { toast } from '@hum/common/src/modules/toast';

import { useAuthState } from '.';
import { ConnectorModalProps } from '../modals/ConnectorModal';

export type IUseDirectConnector = {
  onConnect: (
    formFilled: boolean,
    parameters: Partial<OAuthParameters>
  ) => Promise<void>;
  modalProps: Omit<ConnectorModalProps, 'type'>;
  connection: Connector | null;
  isConnectionError: boolean;
};

export type UseDirectConnectorArgs = {
  connector: ConnectorOption | null;
};

export const useDirectConnector = ({
  connector,
}: UseDirectConnectorArgs): IUseDirectConnector => {
  const { setConnectionId } = useAuthState();
  const {
    mutateAsync: connect,
    isError: isConnectionError,
    data: connection = null,
    reset,
  } = useApiMutation(createConnector, {
    onError: (e: any) => {
      reset();
      toast.error(e.message);
    },
  });

  const onConnect = useCallback(
    async (formFilled, parameters) => {
      if (!connector?.config || !formFilled) {
        return;
      }

      const newConnection = await connect({
        connectorConfigId: connector.config.id,
        parameters,
      });

      if (newConnection) {
        setConnectionId(newConnection.id);
      }
    },
    [connector]
  );

  return {
    onConnect,
    modalProps: {
      title: `Connect your ${connector?.config.label ?? ''} account`,
      description:
        'To authorize your account, please enter your credentials below',
      isOpen: true,
      testId: 'cannot-connect-modal',
    },
    isConnectionError,
    connection,
  };
};
