import React, { ReactElement } from 'react';

import { Modal, IconProps, Button, ButtonType } from '@hum/ui-library';
import {
  AuthenticationForm,
  AuthenticationFormProps,
} from './AuthenticationForm';
import { ConnectionType } from '@hum/types';

export enum ModalType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  AUTHENTICATING = 'AUTHENTICATING',
  CONNECTING = 'CONNECTING',
}

export interface ConnectorModalProps {
  type: ModalType;
  testId?: string;
  title: string;
  description?: string;
  reportUrl?: string;
  icon?: ReactElement<IconProps>;
  isOpen?: boolean;
  onClose?: () => void;
  onTryAgain?: () => void;
  onReport?: () => void;
  authenticationProps?: AuthenticationFormProps;
  withTransitions?: boolean;
}

export const MODAL_TEXT_COLOR: Record<ModalType | 'initial', string> = {
  initial: 'text-defaultTextColor',
  [ModalType.SUCCESS]: 'text-gray-0',
  [ModalType.AUTHENTICATING]: 'text-gray-0',
  [ModalType.CONNECTING]: 'text-gray-0',
  [ModalType.ERROR]: 'text-defaultTextColor',
};

function ConnectorModal({
  type,
  testId,
  title,
  description,
  icon,
  reportUrl,
  isOpen = false,
  onClose,
  onTryAgain,
  onReport,
  authenticationProps,
  withTransitions = false,
}: ConnectorModalProps) {
  const modalTextColor = MODAL_TEXT_COLOR[type] || MODAL_TEXT_COLOR.initial;

  return (
    <Modal
      testId={testId}
      isOpen={isOpen}
      title={title}
      titleClassName={modalTextColor}
      description={description}
      icon={icon}
      onClose={onClose}
      withTransitions={withTransitions}
    >
      {authenticationProps &&
      authenticationProps?.connector?.config.connectionType !==
        ConnectionType.Codat ? (
        <AuthenticationForm {...authenticationProps} onClose={onClose} />
      ) : (
        <div className="py-4 px-6 flex justify-between items-center">
          <Button
            onClick={onClose}
            type={ButtonType.QUIET}
            testId={`connector:modal:cancel:${testId}`}
          >
            Cancel
          </Button>
          {reportUrl && (
            <Button
              onClick={onReport}
              type={ButtonType.QUIET}
              testId={`connector:modal:report:${testId}`}
            >
              Report a problem
            </Button>
          )}
          {type !== ModalType.CONNECTING && (
            <Button
              type={ButtonType.SECONDARY}
              testId={`connector:modal:retry:${testId}`}
              onClick={onTryAgain}
            >
              Try again
            </Button>
          )}
        </div>
      )}
    </Modal>
  );
}

export { ConnectorModal };
