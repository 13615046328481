import { UserAcceptMNDA, OnboardingV4ActionType } from '../actions';
import { call, takeEvery } from 'redux-saga/effects';
import { acceptMNDA } from '@hum/icm-app/src/sagas/utils';
import { catchError } from '@hum/icm-app/src/modules/sagas';
import { noop } from 'lodash';

export function* handleAcceptMNDAButtonClicked() {
  yield takeEvery(
    OnboardingV4ActionType.USER_ACCEPT_MNDA,
    catchError(function* ({ payload: { companyId, payload } }: UserAcceptMNDA) {
      yield call(acceptMNDA, companyId, payload);
    }, noop)
  );
}
