import React from "react";
import _pubF29676E3 from "../../../../../design-system/src/components.pc";
import _pubBab5A68F, {Preview as _pubBab5A68F_Preview} from "../../../../../design-system/src/utils.pc";
import _pubA97D53F3 from "../../../../../design-system/src/atoms.pc";
import _pub2Dbb4493 from "../../../../../design-system/src/Button.pc";
import _pub8C547Ae7 from "../../../../../design-system/src/Select.pc";
import _pubC68937F5 from "../../../../../design-system/src/Scrollable.pc";
import _pubA53A8Fae from "../../../../../design-system/src/Table.pc";
import _pub2F51C399 from "../../../../../design-system/src/breakpoints.pc";
import _pub1Fe26F90 from "../../../components/CompanyDocuments/index.pc";
import "./layout.pc.css";
function getDefault(module) {
  return module.default || module;
}

function castStyle(value) {
  var tov = typeof value;
  if (tov === "object" || tov !== "string" || !value) return value;
  return value.trim().split(";").reduce(function (obj, keyValue) {
    var kvp = keyValue.split(":");
    var key = kvp[0];
    var value = kvp[1];
    if (!value || value === "undefined") return obj;
    var trimmedValue = value.trim();
    if (trimmedValue === "undefined" || !trimmedValue) return obj;
    obj[key.trim()] = trimmedValue;
    return obj;
  }, {});
}

export const classNames = {

};

var SlidePageItem = React.memo(React.forwardRef(function SlidePageItem(props, ref) {
  return React.createElement("div", {
    "className": "_838c8644 _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : ""),
    "ref": ref,
  }, 
    props["children"]
  )
}));
export { SlidePageItem };

var SlidePage = React.memo(React.forwardRef(function SlidePage(props, ref) {
  return React.createElement("div", {
    "className": "_6d82e768 _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
    "data-pc-label": "SlidePage",
    "style": castStyle("--index:" + props["index"]),
  }, 
    React.createElement("div", {
      "className": "_a367b3dc _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399",
    }, 
      props["children"]
    )
  )
}));
export { SlidePage };

var OnboardingSideInfo = React.memo(React.forwardRef(function OnboardingSideInfo(props, ref) {
  return React.createElement("div", {
    "className": "_84e1425d _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_a7eacf6e _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399",
    }, 
      React.createElement("div", {
        "className": "_c08876c8 _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399",
      }, 
        props["title"]
      ),
      React.createElement("div", {
        "className": "_59812772 _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399",
      }, 
        props["description"]
      )
    )
  )
}));

var OnboardingLayout = React.memo(React.forwardRef(function OnboardingLayout(props, ref) {
  return React.createElement("div", {
    "className": "_6aef2371 _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399" + " " + "_e6205176_hum _pub-e6205176_hum _pub-a97d53f3_hum _pub-2f51c399_hum hum _e6205176_dark _pub-e6205176_dark _pub-a97d53f3_dark _pub-2f51c399_dark dark _e6205176_v3 _pub-e6205176_v3 _pub-a97d53f3_v3 _pub-2f51c399_v3 v3",
    "ref": ref,
    "data-pc-label": "Onboarding layout",
    "data-testid": props["testId"],
  }, 
    props["children"]
  )
}));
export { OnboardingLayout };

var OnboardingSide = React.memo(React.forwardRef(function OnboardingSide(props, ref) {
  return React.createElement("div", {
    "className": "_8d570e76 _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399",
    "ref": ref,
  }, 
    React.createElement("div", {
      "className": "_aef0360a _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399",
      "data-pc-label": "outer",
    }, 
      React.createElement("div", {
        "className": "_5789bc9 _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399",
        "data-pc-label": "inner",
      }, 
        React.createElement(OnboardingSideInfo, {
          "class": "_4dc86b99",
          "title": props["title"],
          "description": props["subtitle"],
        }, null),
        props["children"]
      )
    )
  )
}));
export { OnboardingSide };

var OnboardingContent = React.memo(React.forwardRef(function OnboardingContent(props, ref) {
  return React.createElement("div", {
    "className": "_a8a1d587 _e6205176 _pub-e6205176 _pub-a97d53f3 _pub-2f51c399" + (props["class"] ? " " + props["class"] : "") + " " + " " + (props["className"] ? " " + props["className"] : "") + (props["centered"] ? " " + "_e6205176_centered _pub-e6205176_centered _pub-a97d53f3_centered _pub-2f51c399_centered centered" : "") + (props["overflowHidden"] ? " " + "_e6205176_overflow-hidden _pub-e6205176_overflow-hidden _pub-a97d53f3_overflow-hidden _pub-2f51c399_overflow-hidden overflow-hidden" : ""),
    "ref": ref,
    "data-pc-label": "Onboarding content",
  }, 
    props["children"]
  )
}));
export { OnboardingContent };


