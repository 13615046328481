import React from 'react';
import * as Yup from 'yup';
import { toast } from '@hum/common/src/modules/toast';
import { User } from '@hum/types';
import { Modal } from '@hum/design-system';
import { Field, TextInput, InputMode } from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import { useField, useForm } from '@hum/common/src/modules/form';
import * as styles from '../styles.pc';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';
import { useFlags } from '@hum/icm-app/src/hooks/useFlags';
import { useApiMutation, changeEmail, updateAccountSettings } from '@hum/api';

export type UpdateUserSettingsProps = {
  currentUser?: Pick<
    User,
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'id'
    | 'subdomain'
    | 'authProviders'
    | 'roles'
    | 'preferredHost'
    | 'phone'
    | 'position'
  >;
  isOpen: boolean;
  onClose: () => void;
};

export type UpdateEmailProps = {
  isOpen: boolean;
  onClose: () => void;
};

const validationAccountSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string().nullable(),
  position: Yup.string().nullable(),
});

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password is required'),
  newEmail: Yup.string().required('Email is required'),
});

const initialValues = {
  currentPassword: '',
  newEmail: '',
};

export const UpdateEmailModal = ({ isOpen, onClose }: UpdateEmailProps) => {
  const flags = useFlags();
  const { mutate: changeUserEmail } = useApiMutation(changeEmail, {
    onSuccess: (data) => {
      toast.success(data.message);
      handleClose();
    },
  });
  const api = useAPI();
  const handleEmailUpdate = async (fields) => {
    return flags.enabled('use-react-query')
      ? changeUserEmail(fields)
      : await api.legacy
          .changeEmail(fields)
          .then(() => {
            toast.success(
              `A confirmation email has been sent to ${fields.newEmail}`
            );
            handleClose();
          })
          .catch((error) => {
            toast.error(error?.message);
            return error;
          });
  };
  const form = useForm({
    initialValues,
    validationSchema,
    onSubmit: (payload) => {
      handleEmailUpdate(payload);
    },
  });

  const handleClose = () => {
    form.resetForm();
    onClose();
  };

  const itemsField = [
    {
      testId: 'settings-page:user-settings:current-password',
      inputMode: InputMode.PASSWORD,
      label: 'Current Password',
      property: 'currentPassword',
    },
    {
      testId: 'settings-page:user-settings:new-email',
      inputMode: InputMode.TEXT,
      label: 'New e-mail',
      property: 'newEmail',
    },
  ];
  return (
    <Modal
      visible={isOpen}
      header="Change email"
      onClose={handleClose}
      onSubmit={form.handleSubmit}
      side
      wide
      footer={
        <>
          <Button
            type={ButtonType.SECONDARY}
            disabled={form.isSubmitting}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            submit
            loading={form.isSubmitting}
            disabled={form.isSubmitting}
          >
            Change email
          </Button>
        </>
      }
    >
      {itemsField.map((item) => (
        <Field
          key={item.testId}
          label={item.label}
          v3
          {...useField(`${item.property}`, 'text', form).input}
        >
          {(props) => (
            <TextInput
              {...props}
              inputMode={item.inputMode}
              data-testid={`${item.testId}-input`}
              {...(item.testId ===
              'settings-page:user-settings:current-password'
                ? { autoFocus: true }
                : { autoFocus: false })}
            />
          )}
        </Field>
      ))}
      <styles.TextModal text="Your email update will be effective once you confirm your new email from your inbox." />
    </Modal>
  );
};

export const UpdateUserSettingsModal = ({
  currentUser = {
    id: NaN,
    email: '',
    firstName: '',
    lastName: '',
    subdomain: '',
    authProviders: [],
    roles: [],
    preferredHost: '',
  },
  isOpen,
  onClose,
}: UpdateUserSettingsProps) => {
  const flags = useFlags();
  const { mutate: updateAccount } = useApiMutation(updateAccountSettings, {
    onSuccess: () => {
      handleClose();
    },
  });
  const { dispatch } = useAppStore();
  const api = useAPI();
  const form = useForm({
    initialValues: currentUser,
    validationSchema: validationAccountSchema,
    onSubmit: async (fields) => {
      if (fields) {
        return flags.enabled('use-react-query')
          ? updateAccount({
              firstName: fields.firstName,
              lastName: fields.lastName,
              phone: fields.phone || ' ',
              companyPosition: fields.position || ' ',
            })
          : await api.legacy
              .updateAccountSettings(dispatch)({
                firstName: fields.firstName,
                lastName: fields.lastName,
                phone: fields.phone || ' ',
                position: fields.position || ' ',
              })
              .then(() => {
                toast.success('Your changes were successfully saved');
                onClose();
              })
              .catch((error) => {
                toast.error(error);
              });
      }
    },
  });
  const handleClose = () => {
    form.resetForm();
    onClose();
  };

  const itemsField = [
    {
      testId: 'settings-page:edit-profile:Name',
      type: 'input',
      label: 'First name',
      property: 'firstName',
    },
    {
      testId: 'settings-page:edit-profile:Lastname',
      type: 'input',
      label: 'Last name',
      property: 'lastName',
    },
    {
      testId: 'settings-page:edit-profile:Phone',
      type: 'input',
      label: 'Phone number (Optional)',
      property: 'phone',
    },
    {
      testId: 'settings-page:edit-profile:Position',
      type: 'input',
      label: 'Position (Optional)',
      property: 'position',
    },
  ];
  return (
    <Modal
      visible={isOpen}
      header="Edit profile"
      onClose={onClose}
      onSubmit={form.handleSubmit}
      side
      wide
      footer={
        <>
          <Button
            type={ButtonType.SECONDARY}
            disabled={form.isSubmitting}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            submit
            loading={form.isSubmitting}
            disabled={form.isSubmitting}
          >
            Save
          </Button>
        </>
      }
    >
      {itemsField.map((item) => (
        <Field
          key={item.testId}
          label={item.label}
          v3
          {...useField(item.property, 'text', form).input}
        >
          {(props) => (
            <TextInput
              {...props}
              data-testid={`${item.testId}-input`}
              {...(item.testId === 'settings-page:edit-profile:Name'
                ? { autoFocus: true }
                : { autoFocus: false })}
            />
          )}
        </Field>
      ))}
    </Modal>
  );
};
