import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { CannotConnectModal, ConnectorModal } from './modals';
import { useConnectorList, useAuthFlow, useAuthState } from './hooks';
import { toast } from '@hum/common/src/modules/toast';
import clsx from 'clsx';
import { extractAuthParams } from '@hum/icm-app/src/utils';
import { useApiMutation, deleteConnector } from '@hum/api';

import { PlatformType, ConnectorConfig, ConnectionType } from '@hum/types';
import { ConnectorPrepInstructions } from './ConnectorPrepInstructions';
import { TextInput } from '@hum/legacy-ui';
import { Icon, TextType, Text } from '@hum/ui-library';
import { ConnectorList, ConnectorListHeader, ConnectorListFooter } from '.';

export type ConnectorListViewProps = {
  platformType?: PlatformType;
  withTransitions?: boolean;
  isOnboarding?: boolean;
};

const DEFAULT_ERROR_MESSAGE =
  'An unknown error has occurred. If this problem persists, please contact support';
const REDIRECT_ERRORS: Record<string, string> = {
  'User cancelled.': 'Connection cancelled',
  'Unknown error occurred.': DEFAULT_ERROR_MESSAGE,
  'Not supported.':
    'This type of connection is not supported. Please contact support if you believe you have received this message in error',
};
const PLATFORM_NAMES = {
  [PlatformType.AccountingSystem]: 'accounting',
  [PlatformType.PaymentProcessor]: 'payment',
  [PlatformType.Bank]: 'bank',
};

export const ConnectorListView = ({
  platformType = PlatformType.AccountingSystem,
  withTransitions = true,
  isOnboarding = false,
}: ConnectorListViewProps) => {
  const connectionConfig: Partial<ConnectorConfig> = {};
  const {
    filteredConnectors,
    loading,
    totalConnectors,
    selectedConnector,
    selectConnector,
    onFilterConnectors,
    hasActiveConnections,
  } = useConnectorList(platformType, isOnboarding);

  const {
    onConnect,
    authenticationProps,
    isConnecting,
    modalProps,
    connection,
  } = useAuthFlow(selectedConnector, selectConnector);

  const { mutate: deleteConnection } = useApiMutation(deleteConnector, {
    toastsEnabled: false,
  });
  const { connectionId, oauthConfigId, clearOauthState } = useAuthState();
  const location = useLocation();
  const history = useHistory();
  const isLoading = loading.companyConnectors || loading.connectorConfigs;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isLoading || !isConnecting) {
      return;
    }

    // show error messages from query string
    const { error_message: errorMessage } = qs.parse(location.search);
    if (errorMessage) {
      toast.error(
        REDIRECT_ERRORS[errorMessage as string] || DEFAULT_ERROR_MESSAGE
      );

      if (connectionId) {
        deleteConnection({ connectorId: connectionId });
      }
    }

    const newUrl = extractAuthParams(
      location,
      connection?.config?.connectionType === ConnectionType.Direct
    );

    if (newUrl !== `${location.pathname}${location.search}`) {
      history.replace(newUrl);
      clearOauthState();
    }
  }, [isLoading, connectionId, oauthConfigId]);

  return (
    <>
      <ConnectorModal
        {...modalProps}
        authenticationProps={authenticationProps}
        withTransitions={withTransitions}
      />
      <CannotConnectModal
        isOpen={isOpen}
        onBack={() => setIsOpen(false)}
        withTransitions={withTransitions}
      />
      <div
        className={clsx(
          'mx-auto',
          'w-10/12',
          'md:w-6/12',
          'lg:w-10/12',
          'h-full',
          'grid',
          'grid-rows-[1fr_auto]',
          'lg:grid-cols-3'
        )}
      >
        <div className={clsx('lg:contents')}>
          <div className={clsx('lg:col-start-2')}>
            <ConnectorListHeader
              title={
                isOnboarding && hasActiveConnections
                  ? 'Connection successful'
                  : `Securely connect your ${PLATFORM_NAMES[platformType]} system`
              }
              subtitle={
                isOnboarding && hasActiveConnections
                  ? `You have connected your ${connectionConfig?.label} account. You can edit your connections or add more later on.`
                  : `Select the ${PLATFORM_NAMES[platformType]} software that best represents your company's financials.`
              }
            />
            <div
              className={clsx(
                isLoading && ['flex', 'justify-center', 'items-center', 'h-1/2']
              )}
            >
              {totalConnectors > 5 && (
                <div data-testid="connector-list-search" className="mb-6">
                  <TextInput
                    icon={<Icon.Search />}
                    onValueChange={onFilterConnectors}
                    placeholder={`Search your ${PLATFORM_NAMES[platformType]} software`}
                  />
                </div>
              )}
              <ConnectorList
                className={clsx(totalConnectors > 5 && 'lg:grid-cols-2')}
                connectors={filteredConnectors}
                selectedConnector={selectedConnector}
                selectConnector={selectConnector}
                isOnboarding={isOnboarding}
                isLoading={isLoading}
              />
            </div>
            {selectedConnector?.config.helpLink && (
              <div className="my-4">
                <Text type={TextType.BODY_S}>
                  Ready to connect {selectedConnector?.config.label}? Explore
                  our{' '}
                  <a
                    href={selectedConnector?.config.helpLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ui-link"
                  >
                    help guide
                  </a>{' '}
                  for step-by-step instructions.
                </Text>
              </div>
            )}
          </div>
          {!hasActiveConnections && (
            <ConnectorPrepInstructions
              className={clsx('lg:col-start-3', 'lg:ml-32')}
            />
          )}
        </div>
        <ConnectorListFooter
          hasActiveConnections={isOnboarding && hasActiveConnections}
          onOpen={() => setIsOpen(true)}
          onConnect={selectedConnector && onConnect}
          isOnboarding={isOnboarding}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default ConnectorListView;
