import React, { useEffect, useState } from 'react';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { StatusCodeDot } from '@hum/design-system';
import {
  Tooltip,
  TooltipContainer,
  Table,
  TabNavigator,
  Tab,
  TableCellConfig,
} from '@hum/legacy-ui';
import { Button, ButtonType } from '@hum/ui-library';
import {
  getUserCompanyId,
  Routes,
  PortfolioMonitoringInvite,
} from '@hum/icm-app/src/state';
import { Result } from '@hum/common/src/ducks/state';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { useInvestorInvites } from '@hum/icm-app/src/hooks/useInvestorInvites';
import { useHistory } from 'react-router';
import * as styles from '@hum/icm-app/src/pages/portfolio-monitoring/styles.pc';
import { usePortfolioCompanyInviteUrl } from '@hum/icm-app/src/hooks/usePortfolioCompanyInviteUrl';
import { PortfolioMonitoringConnectorStatus } from '@hum/icm-app/src/state/app';
import { CompanyInvitationModal, FormData } from './CompanyInvitationModal';
import { toast } from '@hum/common/src/modules/toast';
import editIcon from '@hum/icm-app/src/pages/portfolio-monitoring/assets/edit-icon.svg';

enum CompanyStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  PROCESSING = 'processing',
  ONBOARDING = 'onboarding',
}

const statusLabel = {
  [CompanyStatus.CONNECTED]: 'The company is sharing live data',
  [CompanyStatus.DISCONNECTED]: 'The company is no longer sharing data',
  [CompanyStatus.PROCESSING]: 'Connector data is being processed',
  [CompanyStatus.ONBOARDING]:
    'The company created an account and is being onboarded',
};
const TABLE_CELLS_COMPANIES: Record<string, TableCellConfig> = {
  companyName: {
    label: 'COMPANY',
    renderer: ({ item }: { item: PortfolioMonitoringConnectorStatus }) => {
      return <styles.WrapperData>{item.name}</styles.WrapperData>;
    },
  },
  companyContact: {
    label: 'CONTACT NAME',
    renderer: ({ item }: { item: PortfolioMonitoringConnectorStatus }) => {
      return <styles.WrapperData>{item.contactName}</styles.WrapperData>;
    },
  },
  companyEmail: {
    label: 'CONTACT EMAIL',
    renderer: ({ item }: { item: PortfolioMonitoringConnectorStatus }) => {
      return <styles.WrapperData>{item.contactEmail}</styles.WrapperData>;
    },
  },
  companyStatus: {
    label: 'STATUS',
    renderer: ({ item }: { item: any }) => {
      return (
        <>
          <StatusCodeDot
            active={item.status === CompanyStatus.CONNECTED}
            warning={item.status === CompanyStatus.DISCONNECTED}
            disabled={item.status === CompanyStatus.PROCESSING}
            inactive={item.status === CompanyStatus.ONBOARDING}
          />
          <TooltipContainer>
            <Tooltip narrow center>
              {statusLabel[item.status]}
            </Tooltip>
            {item.status.toUpperCase()}
          </TooltipContainer>
        </>
      );
    },
  },
};

const TABLE_CELLS_INVITES: Record<string, TableCellConfig> = {
  companyName: {
    label: 'COMPANY',
    renderer: ({ item }: { item: PortfolioMonitoringInvite }) => {
      return <styles.WrapperData>{item.companyName}</styles.WrapperData>;
    },
  },
  companyContact: {
    label: 'CONTACT NAME',
    renderer: ({ item }: { item: PortfolioMonitoringInvite }) => {
      return (
        <styles.WrapperData>{`${item.contactFirstName} ${item.contactLastName}`}</styles.WrapperData>
      );
    },
  },
  companyEmail: {
    label: 'CONTACT EMAIL',
    renderer: ({ item, onButtonEditClick }: any) => {
      return (
        <>
          <styles.WrapperData>{item.contactEmail}</styles.WrapperData>
          <Button
            type={ButtonType.QUIET}
            className="button_cell"
            testId="table-lastcell-button"
            onClick={() => onButtonEditClick(item)}
          >
            <img src={editIcon} />
          </Button>
        </>
      );
    },
  },
};

export const PortfolioMonitoringManageCompanies = () => {
  const [showAddCompanyModal, setShowCompanyModal] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<
    PortfolioMonitoringInvite | undefined
  >();
  const [formData, setFormData] = useState<FormData>({
    title: 'Add Company',
    button: 'Send Invitation',
  });
  const { state } = useAppStore();
  const { portfolioMonitoringCompaniesConnectorStatus } = state;
  const companyId = getUserCompanyId(state);
  const {
    data: invites = [],
  }: Result<PortfolioMonitoringInvite[]> = useInvestorInvites(companyId);
  const invites_sent = invites.filter(
    (item) => item.status == 'sent' || 'viewed'
  );
  const [linkValue, setLinkValue] = useState('No Link here');
  const { data: companyInviteUrl } = usePortfolioCompanyInviteUrl(companyId);
  const history = useHistory();
  const {
    data: companiesConnectorStatus,
  } = portfolioMonitoringCompaniesConnectorStatus;
  const onCloseAddCompanyModal = () => {
    setShowCompanyModal(false);
  };
  const onButtonEditClick = (item: PortfolioMonitoringInvite) => {
    setCurrentCompany(item);
    setShowCompanyModal(true);
    setFormData({ title: 'Edit', button: 'Save' });
  };
  useEffect(() => {
    if (companyInviteUrl) {
      setLinkValue(companyInviteUrl.inviteUrl);
    }
  }, [companyInviteUrl]);

  if (companiesConnectorStatus === undefined) {
    history.push(Routes.PORTFOLIO_MONITORING);
  }
  return (
    <>
      <Topbar />
      {companiesConnectorStatus && (
        <styles.ManageCompanies
          title="Manage companies"
          copyLink={() => {
            navigator.clipboard.writeText(linkValue);
            toast.success('Invite link copied to clipboard');
          }}
          previewInvitation={() => {
            history.push(Routes.PORTFOLIO_MONITORING_PREVIEW_INVITATION);
          }}
          addCompany={() => {
            setCurrentCompany(undefined);
            setShowCompanyModal(true);
            setFormData({ title: 'Add company', button: 'Send Invitation' });
          }}
          clickGoBack={() => {
            history.push(Routes.PORTFOLIO_MONITORING);
          }}
          transparent={companiesConnectorStatus.length > 0 ? true : false}
          primary={companiesConnectorStatus.length < 0 ? true : false}
        >
          <TabNavigator horizontal id="companies-table">
            <Tab label="Onboarded companies">
              {companiesConnectorStatus.length > 0 ? (
                <Table
                  cellConfig={TABLE_CELLS_COMPANIES}
                  itemKey="id"
                  data={companiesConnectorStatus}
                  secondary
                  fixedCellWidth={true}
                />
              ) : (
                <styles.InviteBox
                  title={null}
                  description="You don’t have any companies yet. Send invitations to the portfolio companies you want to onboard."
                  buttonText={null}
                />
              )}
            </Tab>
            <Tab label="Invited companies">
              {invites_sent.length > 0 ? (
                <Table
                  cellConfig={TABLE_CELLS_INVITES}
                  itemKey="id"
                  data={invites_sent}
                  secondary
                  fixedCellWidth={true}
                  cellProps={{ onButtonEditClick }}
                  nowrap
                />
              ) : (
                <styles.InviteBox
                  title={null}
                  description="You haven’t invited any companies yet. Send invitations to the portfolio companies you want to onboard."
                  buttonText={null}
                />
              )}
            </Tab>
          </TabNavigator>
        </styles.ManageCompanies>
      )}
      <CompanyInvitationModal
        companyId={companyId}
        isOpen={showAddCompanyModal}
        onClose={onCloseAddCompanyModal}
        currentCompany={currentCompany}
        formData={formData}
      />
    </>
  );
};
