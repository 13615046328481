import React from 'react';
import memoize from 'fast-memoize';
import { Route, Switch } from 'react-router-dom';
import { CompanyType, MenuItem } from '@hum/types';
import { Topbar } from '@hum/icm-app/src/components/Topbar';
import { SidebarNavItems } from '@hum/icm-app/src/components/SidebarNavItems';
import { MetaTitle } from '@hum/legacy-ui';
import { FundingProfile } from '@hum/icm-app/src/pages/settings/funding-profile/FundingProfile';
import { Routes } from '@hum/icm-app/src/state/routes';
import { Account } from './account/Account';
import { BrandingPage } from './branding';
import { Documents } from './Documents';
import { SubdomainPage } from './subdomain';
import { TeamTab } from './manage-users/ManageUsers';
import { getSession } from '@hum/icm-app/src/state';
import { useIsSvbSubDomain } from '@hum/icm-app/src/hooks/useIsSvbSubdomain';
import { useSelector } from 'react-redux';
import { SidebarNav } from '@hum/ui-library';

const getMenuOptions = memoize(
  (): Record<string, MenuItem> => {
    return {
      [Routes.BRANDING]: {
        title: 'Branding',
        component: <BrandingPage />,
        requires: [CompanyType.Investor],
        testName: 'settings-page:branding-logo-btn',
      },
      [Routes.SUBDOMAIN]: {
        title: 'Subdomain',
        component: <SubdomainPage />,
        requires: [CompanyType.Investor],
      },
      [Routes.SETTINGS_ACCOUNT]: {
        title: 'Settings',
        component: <Account />,
        requires: [
          CompanyType.Investor,
          CompanyType.Company,
          CompanyType.BalanceSheetPartner,
          CompanyType.Admin,
          CompanyType.SyndicationInvestor,
        ],
        testName: 'settings-page:settings-user-btn',
      },
      [Routes.SETTINGS_FUNDING_PROFILE]: {
        title: 'Funding profile',
        component: <FundingProfile />,
        requires: [CompanyType.SyndicationInvestor],
        testName: 'settings-page:funding-profile-btn',
      },
      [Routes.SETTINGS_DOCUMENTS]: {
        title: 'Privacy and Documents',
        component: <Documents />,
        requires: [CompanyType.Company],
      },
      [Routes.MANAGE_TEAM]: {
        title: 'Manage Users',
        component: <TeamTab />,
        requires: [
          CompanyType.Investor,
          CompanyType.Company,
          CompanyType.BalanceSheetPartner,
          CompanyType.Admin,
          CompanyType.SyndicationInvestor,
        ],
        testName: 'settings-page:manage-users-btn',
      },
    };
  }
);

export const SettingsPageContent = () => {
  const menuOptions = getMenuOptions();
  const session = useSelector(getSession);
  const isSbvSubdomain = useIsSvbSubDomain();
  const currentUserRoles = session.data?.roles || [];
  const menuItems = Object.entries(menuOptions)
    .filter(([path]) => {
      if (isSbvSubdomain && path === Routes.SETTINGS_DOCUMENTS) {
        return false;
      }
      return true;
    })
    .filter(([_, menuItem]) =>
      menuItem.requires.some((role: CompanyType) =>
        currentUserRoles.includes(role)
      )
    );

  return (
    <div data-testid="settings:user-settings">
      <SidebarNav fixedBar>
        <SidebarNavItems items={menuItems} />
      </SidebarNav>
      <SidebarNav.Content>
        <MetaTitle>Settings</MetaTitle>
        <Switch>
          {Object.entries(menuOptions).map(([key, menuItem]) => (
            <Route key={key} path={key}>
              <div className="w-full max-w-4xl mx-auto py-10 px-8">
                {menuItem.component}
              </div>
            </Route>
          ))}
        </Switch>
      </SidebarNav.Content>
    </div>
  );
};

export const SettingsPage = () => {
  return (
    <>
      <Topbar fixedBar />
      <SettingsPageContent />
    </>
  );
};
