import React from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '@hum/icm-app/src/state';
import {
  OnboardingLayout,
  Button,
  Icon,
  TextType,
  Text,
  Topbar,
} from '@hum/ui-library';
import { Pill } from '@hum/legacy-ui';
import { useOverview } from '../../hooks/useOverview';
import { StepIndex, overviewSteps, OverviewStepsType } from '@hum/types';
import TopbarProfileMenu from '@hum/icm-app/src/components/TopbarProfileMenu';

export const Overview = () => {
  const history = useHistory();
  const {
    company,
    title,
    subtitle,
    currentStep,
    dataRoomDone,
    handleMndaClick,
    handleDataroomClick,
    isMndaPending,
    acceptedNDA,
  } = useOverview();

  if (!company.loaded) return null;

  if (company.loaded && dataRoomDone) {
    history.push(Routes.ONBOARDING_V5_COMPLETED);
    return null;
  }

  return (
    <>
      <Topbar
        fixedBar
        background="transparent"
        logo={null}
        userLinks={<TopbarProfileMenu showAuthSection />}
      />
      <OnboardingLayout>
        <OnboardingLayout.LeftContent>
          <div className="hidden md:block">
            <Icon.Hum />
          </div>
          <OnboardingLayout.Header title={title} subtitle={subtitle} />
          <OnboardingLayout.Footer>
            <div className="flex justify-start items-center">
              <Text type={TextType.BODY_MICO} subdued className="mr-8">
                <a
                  href="https://humcapital.com/terms-of-service/"
                  className="ui-link text-bodySmallSize"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms
                </a>
              </Text>
              <Text type={TextType.BODY_MICO} subdued>
                <a
                  href="https://humcapital.com/privacy-policy/"
                  className="ui-link text-bodySmallSize"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </Text>
            </div>
          </OnboardingLayout.Footer>
        </OnboardingLayout.LeftContent>

        <OnboardingLayout.RightContent>
          <div className="w-full max-w-md flex flex-col">
            {Object.values(overviewSteps).map(
              (step: OverviewStepsType, i: number) => {
                let description =
                  i === currentStep &&
                  (i == StepIndex.SIGN_MNDA_INDEX && isMndaPending
                    ? step.altDescription
                    : step.description);

                const StepIcon =
                  step.completed ||
                  (i === StepIndex.SIGN_MNDA_INDEX && acceptedNDA)
                    ? Icon.Check
                    : i === currentStep && !step.completed
                    ? Icon.RingOn
                    : Icon.RingOff;

                return (
                  <div
                    className="p-5 relative border-t border-borderColor first:border-t-0"
                    key={step.label}
                  >
                    <div className="flex items-center">
                      <StepIcon className="mr-6" />
                      <Text
                        type={TextType.HEADING_M}
                        subdued={i !== currentStep}
                      >
                        {step.label}
                      </Text>
                    </div>
                    {i === currentStep && isMndaPending && (
                      <Pill>{step.pillText}</Pill>
                    )}
                    {description && (
                      <Text type={TextType.UI_ELEMENT} className="my-4" subdued>
                        {description}
                      </Text>
                    )}

                    {i === currentStep &&
                      i === StepIndex.SIGN_MNDA_INDEX &&
                      !step.completed &&
                      !isMndaPending && (
                        <Button onClick={handleMndaClick}>Let's go</Button>
                      )}
                    {i === currentStep &&
                      i === StepIndex.BUILD_DATAROOM_INDEX &&
                      !step.completed && (
                        <Button onClick={handleDataroomClick}>Let's go</Button>
                      )}
                  </div>
                );
              }
            )}
          </div>
        </OnboardingLayout.RightContent>
      </OnboardingLayout>
    </>
  );
};
